/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Collapse, List, Typography } from "@mui/material";
import { useRoleGuard } from "hooks";
import { EMAIL_REGEX, Roles } from "models";
import { useLocationContext } from "pages/locations/context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TransitionGroup } from "react-transition-group";

import ButtonAddKeyContacts from "../ButtonAddKeyContacts/ButtonAddKeyContacts";
import KeyContactFields from "../KeyContactFields/KeyContactFields";

export enum FieldKey {
  NAME = "name",
  EMAIL = "email",
  PHONE = "phone",
  TITLE = "title",
}

export default function KeyContact() {
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
    Roles.multiOrgOwner,
  ]);
  const { keyContacts } = useLocationContext();
  const { setKeyContacts } = useLocationContext();

  const [expanded, setExpanded] = useState<string | false>(false);

  //** Key contacts management */
  const handleChangeInputContact = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const copyData = keyContacts;
    const isEmailChange = e.target.name.slice(6) === FieldKey.EMAIL;
    copyData[index] = {
      ...copyData[index],
      [e.target.name.slice(6)]: e.target.value,
      isValidEmail: isEmailChange ? EMAIL_REGEX.test(e.target.value) || e.target.value === "" : copyData[index]?.isValidEmail,
    };

    setKeyContacts([...copyData]);
  };

  const handleDeleteContactField = (index: number) => {
    // eslint-disable-next-line no-shadow
    const copyContacts = [...keyContacts];
    copyContacts.splice(index, 1);
    setKeyContacts(copyContacts);
  };

  //** Manager director management */
  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} sx={{ boxShadow: "none" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: "24px" }} />}>
        <Typography variant="h2" sx={{ fontWeight: 600, letterSpacing: "0.18px", mt: 4, mb: 4, mx: -4 }}>
          Key contacts
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box mx={-4}>
          <List>
            <TransitionGroup>
              {keyContacts?.map((keyContact, index) => (
                <Collapse>
                  <KeyContactFields
                    index={index}
                    keyContact={keyContact}
                    handleChangeInputContact={handleChangeInputContact}
                    authUpdateOrganization={authUpdateOrganization}
                    handleDeleteContactField={handleDeleteContactField}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </List>
        </Box>
        <ButtonAddKeyContacts />
      </AccordionDetails>
    </Accordion>
  );
}
