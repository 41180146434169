//** Components Imports */
import { useFetchAndLoad, useInputValueContext, useRoleGuard } from "hooks";
import InputApiCallCheckbox from "components/atoms/InputApiCallCheckbox/InputApiCallCheckbox";
import { FileUploaderBrand, Input } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Context */
import { useLocationContext } from "pages/locations/context";
//**Services */
import { getAllBrandsAPI } from "pages/locations/services";
//** MUI Imports */
import { Box, FormControlLabel, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { Roles } from "models";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
import { TagInput } from "../../molecules";
import { purposeCodeforAplicableBrand } from "../../../constants/purposesToConditions";

// eslint-disable-next-line no-unused-vars
export default function BusinessStep({ create }: { create?: boolean }) {
  //**Context */
  const {
    category,
    applicableBrand,
    view,
    alertRequiredFields,
    purpose,
    isPremium,
    business,
    displayOrder,
    brandsPictures,
    brandsPicturesText,
    isOnlineOnly,
  } = useLocationContext();
  const {
    setApplicableBrand,
    setCategory,
    setPurpose,
    setIsPremium,
    setBusiness,
    setDisplayOrder,
    setBrandsPictures,
    setBrandsPicturesText,
    setIsOnlineOnly,
  } = useLocationContext();
  //**Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  //**States */
  const fetchBrands = useFetchAndLoad();
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
    Roles.multiOrgOwner,
  ]);
  const businessInput = useInputValueContext("", setBusiness);
  const displayOrderInput = useInputValueContext("", setDisplayOrder);

  //**Required inputs management */
  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields[3] : true;

  const includePurposeCodeforAplicableBrand = purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`));
  const isRequiedExclusiveFields = includePurposeCodeforAplicableBrand && applicableBrand.length === 0;

  const { getPurposes, loadingPurpose } = useGetSearchData();

  // eslint-disable-next-line consistent-return
  const getBrands = async () => {
    try {
      //get the brand sorted by name
      const query: any = { sort: "asc(name)" };
      const response = await fetchBrands.callEndpoint(getAllBrandsAPI({ query }));
      const { data } = response;

      //Generate the array of brands for the autocomplete
      //We need to add the code for send to the create or update funciton
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id, code: item.code }));

      //Add select all field to the brands array
      return [{ label: "Select all", id: "", code: "" }, ...autocompleteData];
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangesetIsPremium = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPremium(event.target.checked);
  };

  const handleChangesetIsOnlineOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOnlineOnly(event.target.checked);
  };

  return (
    <>
      <InputApiCallCheckbox
        initialValue={purpose}
        setPropValue={setPurpose}
        apiCallFunction={getPurposes}
        loading={loadingPurpose}
        label={`${t("INPUT-BUSINESS")} *`}
        disabled={!authUpdateOrganization}
        requiredFields={purpose.length === 0 && isRequiedFieldEmpty}
        alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
      />

      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={6} mt={4}>
        <FormControlLabel
          control={<Switch checked={isPremium} onChange={handleChangesetIsPremium} />}
          label={t("INPUT-PREMIUM")}
          disabled={!authUpdateOrganization}
        />
        <FormControlLabel
          control={<Switch checked={isOnlineOnly} onChange={handleChangesetIsOnlineOnly} />}
          label={t("INPUT-ONLINE-ONLY")}
          disabled={!authUpdateOrganization}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <TagInput
          name="loc-category-product"
          label={`${t("INPUT-CATEGORY-PRODUCT")} `}
          valueTag={category}
          setValueTag={setCategory}
          disabled={!authUpdateOrganization}
        />

        <InputApiCallCheckbox
          initialValue={applicableBrand}
          setPropValue={setApplicableBrand}
          apiCallFunction={getBrands}
          loading={fetchBrands.loading}
          label={`${t("INPUT-BRAND")} ${includePurposeCodeforAplicableBrand ? "*" : ""}`}
          disabled={!authUpdateOrganization}
          requiredFields={isRequiedExclusiveFields && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mt={6}>
        {create && (
          <Input
            name="business"
            value={business}
            onChange={businessInput.onChange}
            label={`${t("INPUT-BUSINESS")}`}
            fullWidth
            disabled={!authUpdateOrganization}
          />
        )}

        <Box width="100%">
          {purpose.findIndex((item) => item.code === "OR") !== -1 && (
            <Input
              name="locationNumber"
              value={displayOrder}
              onChange={displayOrderInput.onChange}
              label={`${t("INPUT-DISPLAY-ORDER")} *`}
              disabled={!authUpdateOrganization}
              fullWidth
              error={displayOrder?.length === 0 && isRequiedFieldEmpty}
              helperText={`${t("ALL-REQUIRED.INPUT")}`}
            />
          )}
        </Box>
      </Stack>
      <Box mt={8}>
        <Typography variant="h2" sx={{ fontWeight: 500, letterSpacing: "0.18px" }}>
          Brand Manufactured
        </Typography>
        <Box my={8}>
          <FileUploaderBrand
            setFileText={setBrandsPicturesText}
            files={brandsPictures}
            setFiles={setBrandsPictures}
            label="Drop files here or click to upload"
            disabled={!authUpdateOrganization}
            fileText={brandsPicturesText}
          />
        </Box>
      </Box>
    </>
  );
}
