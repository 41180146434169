/* eslint-disable prefer-destructuring */
import { useFetchAndLoad } from "hooks";
import { getAllRolesAPI } from "services";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Models */
import { Roles } from "models";
import { IRolesList } from "services/globalServices.models";
import { RolesRadioButtons } from "pages/users/models";

export default function useGetRoles() {
  const { callEndpoint, loading } = useFetchAndLoad();
  const userState = useSelector((store: AppStore) => store.user);
  const { roles } = userState;
  const adminRoles = new Set([`${Roles.superAdmin}`, `${Roles.generalViewOnly}`]);
  //**State */

  const getRoles = async () => {
    //get the roles sorted by name
    const response = await callEndpoint(getAllRolesAPI({}));
    const data: IRolesList = response?.data;
    //Generate the array of brands for the autocomplete
    const autocompleteData = data.result.items.map((item) => ({ label: item.name, id: item.id }));
    const excludedRoles: string[] = [Roles.sbdWebsite];
    const filteredDataDyRole = autocompleteData.filter((item) => !excludedRoles.includes(item.label));
    //Filter for non admin users roles
    if (!adminRoles.has(roles)) {
      //Add select all field to the brands array
      return [...filteredDataDyRole.filter((item) => !adminRoles.has(item.label))];
    }
    //Add select all field to the brands array
    return [...filteredDataDyRole];
  };

  const getRolesByUserRole = async (radiusRole: RolesRadioButtons, addLocRoles?: boolean) => {
    // Get the roles sorted by name
    const response = await callEndpoint(getAllRolesAPI({}));
    const data: IRolesList = response.data;

    // Generate the array of brands for the autocomplete
    const autocompleteData = data.result.items.map((item) => ({ label: item.name, id: item.id }));

    // Initial value have only the posibility to create an Organization or Location User
    let filteredDataDyRole = [];
    const excludedRoles: string[] = [Roles.superAdmin, Roles.generalViewOnly, Roles.sbdWebsite];
    filteredDataDyRole = autocompleteData.filter((item) => !excludedRoles.includes(item.label));

    //** Admin filters by user sections (Radios Roles) */
    if (radiusRole === RolesRadioButtons.orgRelated) {
      filteredDataDyRole = filteredDataDyRole.filter((item) => item.label.toLocaleLowerCase().includes("org"));
    }
    if (radiusRole === RolesRadioButtons.locRelated) {
      filteredDataDyRole = filteredDataDyRole.filter((item) => item.label.toLocaleLowerCase().includes("location"));
    }

    if (roles === Roles.locationOwner) {
      filteredDataDyRole = autocompleteData.filter((item) => item.label.toLocaleLowerCase().includes("location"));
      return [...filteredDataDyRole];
    }

    //** Filters per role */
    if (roles === Roles.organizationEditor || roles === Roles.organizationOwner || addLocRoles) {
      filteredDataDyRole = autocompleteData.filter(
        (item) => item.label.toLocaleLowerCase().includes("organization") || item.label.toLocaleLowerCase().includes("location"),
      );
      return [...filteredDataDyRole];
    }

    if (roles === Roles.multiOrgOwner) {
      filteredDataDyRole = autocompleteData.filter(
        (item) => item.label.toLocaleLowerCase().includes("organization") || item.label.toLocaleLowerCase().includes("location"),
      );
      return [...filteredDataDyRole];
    }
    // Add select all field to the brands array for super admin
    return [...filteredDataDyRole];
  };

  return { getRoles, loading, getRolesByUserRole };
}
