// ** Type Import
import { Settings } from "context/settingsContext";

//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

//** Icon */
import PlaceIcon from "@mui/icons-material/Place";

//** Context */
import { useGlobalContext } from "context/globalContext";

// ** Custom Components Imports
import { PrivateRoutes, Roles } from "models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TooltipApp } from "components";
import OptionsMenuOrganization from "../option-menu-organization";
import { OptionType } from "../option-menu-organization/types";

interface Props {
  settings: Settings;
  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  saveSettings: (values: Settings) => void;
}

export default function OrganizationsDropdown({ settings }: Props) {
  const { t } = useTranslation();
  // ** Vars
  const { layout } = settings;

  //** Context */
  const { setToggleChangeOrganization } = useGlobalContext();

  //**Get user information */
  const navigate = useNavigate();
  const userState = useSelector((store: AppStore) => store.user);
  const { organization, locationId, locationName, roles } = userState;
  const isLocRelated = roles === Roles.locationEditor || roles === Roles.locationOwner;

  const organizations: OptionType[] | undefined = userState?.organizations?.map((item) => ({
    text: item.name,
    url: item.logo,
    menuItemProps: {
      sx: { py: 2 },
      selected: item.id === organization?.id,
      onClick: () => {
        navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${item.id}`);
        // dispatch(modifyUser({ organization: item }));
        setToggleChangeOrganization((prev: boolean) => !prev);
      },
    },
  }));

  const location = [
    {
      text: locationName,
      url: userState?.organizations ? userState?.organizations[0]?.logo : "",
      menuItemProps: {
        sx: { py: 2 },
        selected: true,
        onClick: () => {
          navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${locationId}`);
          // dispatch(modifyUser({ organization: item }));
          setToggleChangeOrganization((prev: boolean) => !prev);
        },
      },
    },
  ];

  if (userState.roles === Roles.locationOwner || userState.roles === Roles.locationEditor) {
    return (
      <OptionsMenuOrganization
        icon={
          <TooltipApp
            title={t("GLOBAL-TITLE-LOCATIONS")}
            sxText={{
              fontWeight: 600,
            }}
          >
            <PlaceIcon sx={{ fontSize: 25 }} />
          </TooltipApp>
        }
        menuProps={{ sx: { "& .MuiMenu-paper": { mt: 4, minWidth: 130 } } }}
        iconButtonProps={{ color: "inherit", sx: { ...(layout === "vertical" ? { mr: 0.75 } : { mx: 0.75 }) } }}
        options={isLocRelated ? location : organizations || []}
        isLocRelated={isLocRelated}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
