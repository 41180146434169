import { IconButton, Theme, useMediaQuery } from "@mui/material";
import { Button } from "components";
import { useRoleGuard } from "hooks";
import { ButtonVariants, Roles } from "models";
import { defaultKeyContact, useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

export default function ButtonAddKeyContacts() {
  const { t } = useTranslation();
  const { setKeyContacts, keyContacts } = useLocationContext();
  const disabledByRole: boolean = useRoleGuard([
    Roles.superAdmin,
    Roles.locationEditor,
    Roles.locationOwner,
    Roles.organizationEditor,
    Roles.organizationOwner,
  ]);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const handleAddField = () => setKeyContacts((prev) => [...prev, defaultKeyContact]);

  return (
    <>
      {underMd ? (
        <IconButton onClick={handleAddField} disabled={!disabledByRole || keyContacts?.length >= 10}>
          <PersonAddIcon sx={{ fontSize: 20 }} />
        </IconButton>
      ) : (
        <Button
          onClick={handleAddField}
          label={t("BUTTON-MORE-FIELDS")}
          variant={ButtonVariants.text}
          disabled={!disabledByRole || keyContacts?.length >= 10}
          name="add-ccontact-fields"
        />
      )}
    </>
  );
}
