import { Box, Typography, useMediaQuery } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OpeningHoursEditRespInputs from "./OpeningHoursEditRespInputs";

export default function LocOpenHoursEdit() {
  const { t } = useTranslation();
  const { openingHours, setOpeningHours, googleMapsInputs } = useLocationContext();

  const underSm = useMediaQuery("(max-width:350px)");
  return (
    <Box mx={underSm ? 1 : 6} mt={4}>
      <Box display="flex" alignItems="center" gap={4} mx={-2}>
        <AccessTimeIcon sx={{ fontSize: 24 }} />
        <Typography variant="h2" sx={{ fontWeight: 700 }}>
          {`${t("STEP-TITLE-OPEN-OPEN-HOURS")}`.toUpperCase()}
        </Typography>
      </Box>
      <Box display="flex" mt={4} overflow="scroll">
        {openingHours?.map((data: any, index: number) => (
          <OpeningHoursEditRespInputs
            index={index}
            openingHours={openingHours}
            setOpeningHours={setOpeningHours}
            googleMapsInputs={googleMapsInputs}
          />
        ))}
      </Box>
    </Box>
  );
}
