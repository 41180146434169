/* eslint-disable no-shadow */
// ** React Imports
import { Dispatch, Fragment, SetStateAction } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// ** Third Party Components

import { useDropzone } from "react-dropzone";
//** Hooks */
import { useToastMui } from "hooks";
import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

//** Style */
import "./style.css";
import { useTranslation } from "react-i18next";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

export default function FileUploadMain({
  files,
  setFiles,
  label,
  loading,
  removeFunction,
  disabled,
}: {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  removeFunction: () => void;
}) {
  // ** Hooks
  const { handleCreateToast } = useToastMui();
  const theme: any = useTheme();
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 3000000,
    disabled: files.length > 0 || disabled,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedfiles: any) => {
      setFiles(acceptedfiles.map((file: File) => Object.assign(file)));
    },
    onDropRejected: () => {
      handleCreateToast("You can only upload 1 files & maximum size of 3 MB.", "error");
    },
  });

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          style={{ borderRadius: "8px", maxHeight: "200px", width: "100%", height: "100%" }}
          alt={file.name}
          src={URL.createObjectURL(file as any)}
        />
      );
    }
    return <CloseIcon />;
  };

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i: FileProp) => i.name !== file.name);
    setFiles([...filtered]);
    if (removeFunction) removeFunction();
  };

  const fileList = files?.map((file: FileProp) => (
    <div className="full-width" key={`main-file-${file.name}`} style={{ marginBottom: 40 }}>
      <div style={{ height: "15%", textAlign: "right" }}>
        <IconButton onClick={() => handleRemoveFile(file)} disabled={disabled} sx={{ top: underMd ? "70px" : "" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ height: "85%", textAlign: "right", maxHeight: "85%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
            height: "200px",
            justifyContent: "center",
          }}
        >
          <div className="file-preview" style={{ margin: "0 auto" }}>
            {renderFilePreview(file)}
          </div>
        </Box>
      </div>
    </div>
  ));

  if (loading) {
    return <Skeleton width="17%" height={250} />;
  }

  return (
    <div {...getRootProps({ className: `dropzone-main ${theme?.palette?.mode}` })} style={{ cursor: "pointer" }}>
      <input {...getInputProps()} />
      {files.length ? (
        <>{fileList}</>
      ) : (
        <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"], alignItems: "center" }}>
            <FileUploadIcon fontSize="large" />
            <Typography variant="h5" fontWeight={700} textAlign="center">
              {label}
            </Typography>

            <Typography color="textSecondary" textAlign="center">
              {t("FILE-ALLOWED")}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}
