/* eslint-disable no-confusing-arrow */
import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { Chip, TooltipError } from "components";
import { PrivateRoutes } from "models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ILocationsListDOM } from "pages/locations/models";
import { ErrorOutline, StoreOutlined } from "@mui/icons-material";
import { useState } from "react";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";

interface LocListDetailProps {
  data: ILocationsListDOM;
  isAnyLocSelected: boolean;
  purposesLocation: { label: string; id: string; code: string }[];
}

export const LocListDetail = ({ data, isAnyLocSelected, purposesLocation }: LocListDetailProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const handleNavigate = () => {
    navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${data.id}`, { replace: false });
  };
  const [expandPurposes, setExpandPurposes] = useState(false);

  return (
    <>
      {isAnyLocSelected ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          sx={{ backgroundColor: "background.paper" }}
        >
          <Box mt={4}>
            <Box sx={{ color: "white", borderRadius: 1 }} mb={4} p={8}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h1" fontWeight="700">
                  {data.name}
                </Typography>
                {data.warnings && data.warnings.length > 0 && <WarningTooltip table />}
                {!data.hasRequiredFields && <TooltipError />}
              </Box>
              <Box display="flex" mt={4}>
                {data.isHeadQuarter && <Chip label="HQ" size="small" color="success" sx={{ mr: 2 }} />}
                {data.marketOrCountry && <Chip label={data.marketOrCountry} size="small" sx={{ mr: 2 }} />}
              </Box>
            </Box>

            <Box>
              <Box sx={{ color: "white", borderRadius: 1 }} p={8}>
                <Box display="flex" mb={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-ADDRESS")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1">{data.address.address1}</Typography>
                </Box>
                <Box display="flex" mb={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-STATE")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1">{data.address.state}</Typography>
                </Box>
                <Box display="flex" mb={2}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-CITY")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1">{data.address.city}</Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-URL")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%" }}>
                    {data.contact.website}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "background.default",
                  color: "white",
                }}
                mt={8}
                p={8}
              >
                <Box display="flex" alignItems="center" mb={1}>
                  <StoreOutlined sx={{ color: "white", marginRight: 6, fontSize: 24 }} />
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "white" }}>
                    {`${t("INPUT-BUSINESS")} `.toUpperCase()}
                  </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap" alignItems="center" mt={4}>
                  <Box display="flex" flexWrap="wrap" gap={2}>
                    {purposesLocation.map((purpose, index) =>
                      index >= 2 && !expandPurposes ? null : <Chip key={purpose.id} label={purpose.label.toUpperCase()} size="small" />,
                    )}
                    {purposesLocation.length > 2 && !expandPurposes && (
                      <Button variant="text" color="secondary" sx={{ px: 0, py: 0, minWidth: 40 }} onClick={() => setExpandPurposes(true)}>
                        +{purposesLocation.length - 2}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ color: "white" }} mt={2} p={8}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Box display="flex" alignItems="center">
                    <ErrorOutline sx={{ color: "white", marginRight: 6, fontSize: 24 }} />
                    <Typography variant="body1" sx={{ fontWeight: "bold", color: "white" }}>
                      {`${t("LOCATION-LOCATION-OWNER")} `.toUpperCase()}
                    </Typography>
                  </Box>
                  {}
                  <Chip
                    label={data.primaryContactData.designation ? data.primaryContactData.designation.toUpperCase() : "-----"}
                    size="small"
                    sx={{ color: "white", borderColor: "white", marginRight: 1, marginBottom: 1 }}
                    fontSize={underMd ? 12 : 14}
                  />
                </Box>
                <Box display="flex" mb={1}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-NAME")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                    {data.primaryContactData.firstName}
                  </Typography>
                </Box>
                <Box display="flex" mb={1}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-PHONE")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                    {data.primaryContactData.phone}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="body1" sx={{ fontWeight: "bold", minWidth: "80px" }}>
                    {`${t("INPUT-EMAIL")}: `.toUpperCase()}
                  </Typography>
                  <Typography variant="body1" sx={{ marginLeft: "10px" }}>
                    {data.primaryContactData.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{ backgroundColor: "background.default", borderRadius: "8px 0 0 8px", cursor: "pointer", mb: 4 }}
              py={4}
              px={8}
              ml={4}
              onClick={handleNavigate}
            >
              <Typography variant="h1" sx={{ width: "100%" }} display="flex" alignItems="center">
                {t("LOCATION-ENTER-TO-LOC-DETAILS")}
                <ArrowForwardIosIcon sx={{ fontSize: 24, ml: 4 }} />
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%" px={6}>
          <Typography variant="h2" sx={{ width: "100%" }} display="flex" fontWeight="700">
            {t("LOCATION-LIST-NOT-SELECTED")}
          </Typography>
        </Box>
      )}
    </>
  );
};
