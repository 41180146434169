//** Components Imports */
import { useFetchAndLoad, useInputValueContext, useRoleGuard } from "hooks";
import InputApiCallCheckbox from "components/atoms/InputApiCallCheckbox/InputApiCallCheckbox";
import { Chip, Input } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Context */
import { useLocationContext } from "pages/locations/context";
//**Services */
import { getAllBrandsAPI } from "pages/locations/services";
//** MUI Imports */
import { Box, FormControlLabel, Skeleton, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { IDropdownOption, Roles } from "models";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
import { purposeCodeforAplicableBrand } from "pages/locations/constants/purposesToConditions";
import { TagInput } from "pages/locations/components";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import { useEffect } from "react";

// eslint-disable-next-line no-unused-vars
export default function LocBusinessRespEdit({ create, preventApiCall }: { create?: boolean; preventApiCall?: boolean }) {
  //**Context */
  const { category, applicableBrand, view, alertRequiredFields, purpose, isPremium, business, displayOrder, isOnlineOnly } =
    useLocationContext();
  const { setApplicableBrand, setCategory, setPurpose, setIsPremium, setBusiness, setDisplayOrder, setIsOnlineOnly } = useLocationContext();

  //**Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  //**States */
  const fetchBrands = useFetchAndLoad();
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
  ]);
  const businessInput = useInputValueContext("", setBusiness);
  const displayOrderInput = useInputValueContext("", setDisplayOrder);

  //**Required inputs management */
  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields[3] : true;

  const includePurposeCodeforAplicableBrand = purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`));
  const isRequiedExclusiveFields = includePurposeCodeforAplicableBrand && applicableBrand.length === 0;

  const { purposeOptions, getPurposes, loadingPurpose } = useGetSearchData();
  const underMd = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  useEffect(() => {
    if (preventApiCall && !underMd) return;
    getPurposes();
  }, []);

  // eslint-disable-next-line consistent-return
  const getBrands = async () => {
    try {
      //get the brand sorted by name
      const query: any = { sort: "asc(name)" };
      const response = await fetchBrands.callEndpoint(getAllBrandsAPI({ query }));
      const { data } = response;

      //Generate the array of brands for the autocomplete
      //We need to add the code for send to the create or update funciton
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id, code: item.code }));

      //Add select all field to the brands array
      return [{ label: "Select all", id: "", code: "" }, ...autocompleteData];
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangesetIsPremium = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPremium(event.target.checked);
  };

  const handleChangesetIsOnlineOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOnlineOnly(event.target.checked);
  };

  const handleChangePurposes = (isSelected: boolean, item: any) => {
    if (isSelected) {
      setPurpose((prev) => {
        const newPurposeArray: IDropdownOption[] = prev.filter((purposeItem) => purposeItem.code !== item.code);
        return [...newPurposeArray];
      });
    } else {
      setPurpose((prev) => {
        const newPurposeArray: IDropdownOption = { label: item.label, code: item.code, id: item.id };
        return [...prev, newPurposeArray];
      });
    }
  };
  return (
    <Box sx={{ backgroundColor: "background.paper" }} py={4} mt={8} px={4}>
      <Box display="flex" alignItems="center" gap={4}>
        <StoreOutlinedIcon sx={{ fontSize: 24 }} />
        <Typography variant="h2" sx={{ fontWeight: 700 }}>
          {`${t("CREATE-LOC-STEPPER-4")}`.toUpperCase()}
        </Typography>
      </Box>
      <Box display="flex" gap={4} mt={4} flexWrap="wrap">
        {purpose.length === 0 && (
          <Typography variant="h6" color="error">
            You must choose at least one item*
          </Typography>
        )}
        {loadingPurpose ? (
          <>
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" width="40%" height={20} />
            ))}
          </>
        ) : (
          <>
            {purposeOptions.map((item) => {
              const isSelected = purpose.findIndex((purposeItem) => purposeItem.code === item.code) !== -1;
              return (
                <Chip
                  label={item?.label?.toUpperCase()}
                  color={isSelected ? "primary" : "default"}
                  size="small"
                  onDelete={() => {
                    handleChangePurposes(isSelected, item);
                  }}
                  onClick={() => {
                    handleChangePurposes(isSelected, item);
                  }}
                  deleteIcon={isSelected ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                />
              );
            })}
          </>
        )}
      </Box>
      <Box display="flex" gap={4} flexWrap="wrap">
        <Box mt={4} width="100%">
          <Typography variant="h6" sx={{ fontWeight: 700 }} mb={4}>
            CATEGORIES
          </Typography>
          <TagInput
            size="small"
            name="loc-category-product"
            label=""
            valueTag={category}
            setValueTag={setCategory}
            disabled={!authUpdateOrganization}
          />
        </Box>

        <Box mt={4} width="100%">
          <Typography variant="h6" sx={{ fontWeight: 700 }} mb={4}>
            BRAND SERVICED
          </Typography>
          <InputApiCallCheckbox
            size="small"
            initialValue={applicableBrand}
            setPropValue={setApplicableBrand}
            apiCallFunction={getBrands}
            loading={fetchBrands.loading}
            label=""
            disabled={!authUpdateOrganization}
            requiredFields={isRequiedExclusiveFields && isRequiedFieldEmpty}
            alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          />
        </Box>
      </Box>

      <Stack direction={!matches ? "row" : "column"} spacing={4} mt={6}>
        {create && (
          <Input
            name="business"
            value={business}
            onChange={businessInput.onChange}
            label={`${t("INPUT-BUSINESS")}`}
            fullWidth
            disabled={!authUpdateOrganization}
          />
        )}

        <Box width="100%">
          {purpose.findIndex((item) => item.code === "OR") !== -1 && (
            <Input
              name="locationNumber"
              value={displayOrder}
              onChange={displayOrderInput.onChange}
              label={`${t("INPUT-DISPLAY-ORDER")} *`}
              disabled={!authUpdateOrganization}
              fullWidth
              error={displayOrder?.length === 0 && isRequiedFieldEmpty}
              helperText={`${t("ALL-REQUIRED.INPUT")}`}
            />
          )}
        </Box>
      </Stack>
      <Stack direction="row" spacing={4} mb={6} mt={4}>
        <FormControlLabel
          control={<Switch checked={isPremium} onChange={handleChangesetIsPremium} />}
          label={t("INPUT-PREMIUM")}
          disabled={!authUpdateOrganization}
        />

        <FormControlLabel
          control={<Switch checked={isOnlineOnly} onChange={handleChangesetIsOnlineOnly} />}
          label={t("INPUT-ONLINE-ONLY")}
          disabled={!authUpdateOrganization}
        />
      </Stack>
    </Box>
  );
}
