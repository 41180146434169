//** React imports */
import { lazy } from "react";

//** External Libraries */
import { Navigate, Route } from "react-router-dom";
import ErrorView from "pages/ErrorView";

//** Utils */
import { PrivateRoutes } from "models";
import { UserContextProvider } from "pages/users";
import { ErrorBoundary, RoutesWithNotFound } from "utilities";
import { LocationContextProvider } from "./context";

const LocationsList = lazy(() => import("./pages/locationsList/LocationsList"));
const LocationsInformation = lazy(() => import("./pages/locationsInformation/LocationsInformation"));
const LocationsCreate = lazy(() => import("./pages/locationsCreate/LocationsCreate"));
const LocationsEdit = lazy(() => import("./pages/locationsInformation/components/organism/LocationEditView/LocationEditView"));

export default function Locations() {
  return (
    <ErrorBoundary fallBackComponent={<ErrorView />}>
      <LocationContextProvider>
        <UserContextProvider>
          <RoutesWithNotFound>
            <Route path="/" element={<Navigate to={PrivateRoutes.LIST} />} />
            <Route path={PrivateRoutes.INFORMATION} element={<LocationsInformation />} />
            <Route path={PrivateRoutes.EDIT} element={<LocationsEdit />} />
            <Route path={PrivateRoutes.CREATE} element={<LocationsCreate />} />
            <Route path={PrivateRoutes.LIST} element={<LocationsList />} />
          </RoutesWithNotFound>
        </UserContextProvider>
      </LocationContextProvider>
    </ErrorBoundary>
  );
}
