//** MUI imports */
import { Typography } from "@mui/material";
//**Hooks */
import { useTranslation } from "react-i18next";
//** Context */
import { useLocationContext } from "pages/locations/context";
import OpenHoursInputs from "./OpenHoursInputs";

export default function OpenHours() {
  const { openingHours, setOpeningHours, googleMapsInputs } = useLocationContext();
  //** Hooks */
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h2" sx={{ mb: 8.5, fontWeight: 600, letterSpacing: "0.18px", mt: 8 }}>
        {t("STEP-TITLE-OPEN-OPEN-HOURS")}
      </Typography>

      {openingHours?.map((data: any, index: number) => (
        <OpenHoursInputs
          data={data}
          index={index}
          openingHours={openingHours}
          setOpeningHours={setOpeningHours}
          googleMapsInputs={googleMapsInputs}
        />
      ))}
    </>
  );
}
