/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import { IRoleDataAPI, IRoleDataDOM, IUsersListAPI, IUsersListDOM } from "../models";
import { orgDataAdapter } from "../pages/usersList/adapters";

export const userGetOneAdapter = (item: IUsersListAPI): IUsersListDOM => {
  return {
    id: item.id,
    firstName: item.first_name,
    lastName: item.last_name,
    phone: item.phone,
    akamaiUuid: item.akamai_uuid,
    createdAt: item.created_at,
    deletedAt: item.deleted_at,
    description: item.description,
    isSuspended: item.is_suspended,
    roles: item.roles,
    status: item.status,
    updatedAt: item.updated_at,
    username: item.username,
    roleData: roleDataOneAdapter(item.role_data),
    SBDEmail: item.sbd_contact,
    title: item.designation,
    locationData: {
      name: item?.location_data?.name,
    },
    organizationData: orgDataAdapter(item.organization_data),
    primaryContact: {
      relatedId: item?.primary_contact?.related_id,
      type: item?.primary_contact?.type,
    },
  };
};

const roleDataOneAdapter = (items: IRoleDataAPI[]): IRoleDataDOM[] => {
  const array: IRoleDataDOM[] = [];
  items?.map((item: IRoleDataAPI) => {
    return array.push({
      id: item._id,
      name: item.name,
      description: item.description,
      enabled: item.enabled,
      viewsEnabled: item.views_enabled,
      actions: item.actions,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      deletedAt: item.deleted_at,
    });
  });
  return array;
};
