import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
//** Component import */
import { Input, InputApiCall, InputApiCallInfiniteScroll } from "components";
import InputApiCallCheckboxInfiniteScroll from "components/atoms/InputApiCallCheckbox/InputApiCallCheckboxInfiniteScroll";
import { RoleGuard } from "guards";
//** MUI Imports */
import { Box, FormControlLabel, Radio, RadioGroup, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useUserContext } from "pages/users/context";
import { useGetOrganizationsInfiniteScroll, useInputValueContext, useLocationInfiniteScroll } from "hooks";
import { useGetRoles } from "hooks/apiCalls";
//** Model */
import { EMAIL_REGEX, IDropdownOption, PrivateRoutes, Roles, SBD_EMAIL_REGEX } from "models";
import { RolesRadioButtons, UserViewsName } from "pages/users/models";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useParams } from "react-router-dom";

export default function BasicInformationUsers({
  loadingOrganization,
  loadingLoc,
}: {
  loadingOrganization?: boolean | undefined;
  loadingLoc?: boolean | undefined;
}) {
  //** Context */
  const { firstName, lastName, email, phone, role, organization, radioRole, SBDEmail, view, title, alertRequiredFields, location } =
    useUserContext();
  const { setFirstName, setLastName, setEmail, setPhone, setRole, setOrganization, setRadioRole, setSBDEmail, setTitle, setLocation } =
    useUserContext();
  const { isValidSBDEmail, setIsValidSBDEmail, isValidEmail, setIsValidEmail } = useUserContext();

  const params = useParams();
  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles, id, locationId } = userState;

  //**States */
  const firstNameInput = useInputValueContext("", setFirstName);
  const lastNameInput = useInputValueContext("", setLastName);
  const phoneInput = useInputValueContext("", setPhone);
  const titleInput = useInputValueContext("", setTitle);

  //** Request doesn't have radioRoles buttons*/
  //** Then we need to see if the role from the request is loc related to display the location input*/
  //** And we need to wait until the variable have information after display the field */
  const requestViewLocRole =
    (window.location.pathname.includes("request") || window.location.pathname.includes("create")) &&
    (role?.label === Roles.locationEditor || role?.label === Roles.locationOwner) &&
    !Array.isArray(organization) &&
    !!organization?.id;

  const disableForCantEditRole =
    roles === Roles.generalViewOnly || roles === Roles.viewOnly || roles === Roles.locationEditor || roles === Roles.organizationEditor;
  const disableForViewOnlyRole = roles === Roles.generalViewOnly || roles === Roles.viewOnly;

  const isAdminUser =
    roles === Roles.superAdmin || roles === Roles.multiOrgOwner || roles === Roles.organizationOwner || roles === Roles.locationOwner;
  const isMyProfile = params?.id === id;

  //** locations users only can edit users related to their own location*/
  const locManagerCanEdit = roles === Roles.locationOwner && radioRole === RolesRadioButtons.locRelated && locationId === location?.id;
  //** org users only can edit users related to their own org except the org managers that can edit all */
  const orgManagerCanEdit =
    roles === Roles.multiOrgOwner || roles === Roles.superAdmin
      ? true
      : roles === Roles.organizationOwner &&
        (radioRole === RolesRadioButtons.locRelated || (radioRole === RolesRadioButtons.orgRelated && role.label !== Roles.multiOrgOwner));

  //**Users can edit their on profile */
  const canEditInformation = (isAdminUser && (locManagerCanEdit || orgManagerCanEdit)) || isMyProfile || view === "create";

  const onlyUpdateLocationRole =
    (roles === Roles.locationEditor || roles === Roles.locationOwner) &&
    (role?.label === Roles.organizationOwner || role?.label === Roles.organizationEditor || role?.label === Roles.viewOnly);

  const sbdEmailRolesInput =
    radioRole === RolesRadioButtons.superAdmin || radioRole === RolesRadioButtons.generalViewOnly || role.label === Roles.multiOrgOwner;

  const validateUserEmailByRole = (emailToValidate: string, isMultiOrgOwner?: boolean, isAdminRole?: boolean) => {
    const sbdEmailRoles = isAdminRole || isMultiOrgOwner;
    const validEmail = sbdEmailRoles || sbdEmailRolesInput ? SBD_EMAIL_REGEX.test(emailToValidate) : EMAIL_REGEX.test(emailToValidate);
    setIsValidEmail(validEmail);
  };
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    validateUserEmailByRole(e.target.value);
  };
  const onChangeSBDEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setSBDEmail(e.target.value);
    setIsValidSBDEmail(SBD_EMAIL_REGEX.test(e.target.value));
  };

  useEffect(() => {
    if (view === UserViewsName.INFORMATION) return;
    if (roles !== Roles.superAdmin || view === UserViewsName.REQUEST) {
      setRadioRole(RolesRadioButtons.orgRelated);
    } else {
      setRadioRole(RolesRadioButtons.superAdmin);
    }
  }, []);

  //** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const { getRolesByUserRole, loading } = useGetRoles();

  const { getOrganizationsInfiniteScroll, hasNextPage, orgData, orgPage, setOrgPage, setOrgData } = useGetOrganizationsInfiniteScroll();
  const { getlocationsInfiniteScroll, locHasNextPage, locData, locPage, setLocPage, setLocData } = useLocationInfiniteScroll();

  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields : true;

  const rolesToDisplayRole = [RolesRadioButtons.orgRelated, RolesRadioButtons.locRelated];
  const locRelatedHasOrgSelected =
    (role.label === Roles.locationEditor || role.label === Roles.locationOwner) && !Array.isArray(organization) && !!organization?.id;

  return (
    <Box mb={12}>
      <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.generalViewOnly]} view={view !== UserViewsName.REQUEST}>
        <RadioGroup
          row
          value={radioRole}
          aria-labelledby="user-role-group-label"
          name="row-radio-roles-group"
          sx={{ mb: 8 }}
          onChange={(e) => {
            setRadioRole(e.target.value);
            // When the radioRole changes the role should be reset
            // loc related cant have a org related role
            setRole({ label: "", id: "" });
            if (e.target.value === RolesRadioButtons.locRelated && role.label === Roles.multiOrgOwner) {
              setOrganization({ label: "", id: "" } as any);
            }
            if (e.target.value === RolesRadioButtons.superAdmin || e.target.value === RolesRadioButtons.generalViewOnly) {
              validateUserEmailByRole(email || "", false, true);
            }
          }}
        >
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.superAdmin}
            control={<Radio />}
            label={RolesRadioButtons.superAdmin}
            name="user-radio-super-admin"
          />
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.generalViewOnly}
            control={<Radio />}
            label={RolesRadioButtons.generalViewOnly}
            name="user-radio-general-vo"
          />
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.orgRelated}
            control={<Radio />}
            label={RolesRadioButtons.orgRelated}
            name="user-radio-organization"
          />
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.locRelated}
            control={<Radio />}
            label={RolesRadioButtons.locRelated}
            name="user-radio-location"
          />
        </RadioGroup>
      </RoleGuard>
      <Typography variant="h2" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("ORGANIZATION-BASIC-INFORMATION")}
      </Typography>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="users-first-name"
          value={firstName}
          onChange={firstNameInput.onChange}
          label={`${t("INPUT-FIRST-NAME")}*`}
          fullWidth
          disabled={!canEditInformation}
          error={firstName?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
        />
        <Input
          name="users-last-name"
          value={lastName}
          onChange={lastNameInput.onChange}
          label={`${t("INPUT-LAST-NAME")}*`}
          fullWidth
          disabled={!canEditInformation}
          error={lastName?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="users-email"
          value={email}
          onChange={onChangeEmail}
          label={`${t("INPUT-EMAIL")}*`}
          fullWidth
          disabled={view === UserViewsName.INFORMATION}
          error={email?.length === 0 || !isValidEmail || (email === null && isRequiedFieldEmpty)}
          helperText={
            // eslint-disable-next-line no-nested-ternary
            !isValidEmail && `${email || ""}`.length > 0
              ? sbdEmailRolesInput
                ? `${t("NEED-SBD-EMAIL-ADMIN")}`
                : `${t("INVALID-EMAIL")}`
              : `${t("ALL-REQUIRED.INPUT")}`
          }
        />
        <Input
          name="users-phone"
          value={phone}
          onChange={phoneInput.onChange}
          label={`${t("INPUT-PHONE")}*`}
          fullWidth
          disabled={!canEditInformation}
          error={phone?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
        />
      </Stack>

      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <Input
          name="users-title"
          value={title}
          onChange={titleInput.onChange}
          label={`${t("INPUT-TITLE")}`}
          fullWidth
          disabled={!canEditInformation}
          sx={{ display: "flex" }}
        />
        {rolesToDisplayRole.every((item) => item !== radioRole) && <Box sx={{ width: "100%" }} />}
        {rolesToDisplayRole.find((item) => item === radioRole) && (
          <InputApiCall
            name="usr-role"
            externalValue={role}
            setPropValue={(value: any) => {
              setRole(value);
              if (value?.label === Roles.multiOrgOwner) {
                setOrganization([]);
              } else if (Array.isArray(organization)) setOrganization({ label: "", id: "" } as any);

              if (value?.label === Roles.multiOrgOwner) {
                validateUserEmailByRole(email || "", true);
              } else {
                setIsValidEmail(true);
              }
            }}
            apiCallFunction={() => getRolesByUserRole(radioRole as any)}
            loading={loading}
            label={`${t("INPUT-ROLE")}*`}
            disabled={!canEditInformation || disableForCantEditRole || onlyUpdateLocationRole}
            requiredFields={role?.label?.length === 0 && isRequiedFieldEmpty}
            alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
            alwaysCallApi
          />
        )}
      </Stack>
      {rolesToDisplayRole.find((item) => item === radioRole) && (
        <>
          {view === UserViewsName.REQUEST && (
            <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
              <Input
                name="users-sbd-email"
                value={SBDEmail}
                onChange={onChangeSBDEmail}
                label={`${t("INPUT-SBD-EMAIL")} *`}
                fullWidth
                placeholder="email@sbdinc.com"
                error={(SBDEmail?.length > 0 && !isValidSBDEmail) || (SBDEmail?.length === 0 && isRequiedFieldEmpty)}
                helperText={SBDEmail?.length === 0 && isRequiedFieldEmpty ? `${t("ALL-REQUIRED.INPUT")}` : `${t("NEED-SBD-EMAIL")}`}
                disabled={disableForCantEditRole}
              />
              <Box sx={{ width: "100%" }} />
            </Stack>
          )}
          {(role?.label || radioRole === RolesRadioButtons.locRelated) && (
            <>
              <Typography variant="h2" sx={{ mb: 10, fontWeight: 600, letterSpacing: "0.18px" }}>
                {t("GLOBAL-TITLE-ORGNAIZATION")}
              </Typography>
              <>
                {!loadingOrganization && !loadingLoc ? (
                  <>
                    {role?.label === Roles.multiOrgOwner ? (
                      <InputApiCallCheckboxInfiniteScroll
                        initialValue={organization}
                        name="user-organization"
                        setPropValue={setOrganization as Dispatch<SetStateAction<IDropdownOption[]>>}
                        changeWithInputText
                        apiCallFunction={getOrganizationsInfiniteScroll}
                        loading={loadingOrganization || false}
                        label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
                        hasNextPage={hasNextPage}
                        page={orgPage}
                        options={orgData}
                        setPage={setOrgPage}
                        setOptions={setOrgData}
                        disabled={!canEditInformation || disableForCantEditRole}
                        requiredFields={Array.isArray(organization) && organization?.length === 0 && isRequiedFieldEmpty}
                        alertLabel={`${t("SELECT-ORG-INPUT")}`}
                        redirect
                        redirectUrl={`${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`}
                      />
                    ) : (
                      <InputApiCallInfiniteScroll
                        initialValue={organization}
                        name="loc-organization"
                        setPropValue={setOrganization}
                        changeWithInputText
                        apiCallFunction={getOrganizationsInfiniteScroll}
                        loading={loadingOrganization || false}
                        label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
                        hasNextPage={hasNextPage}
                        page={orgPage}
                        options={orgData}
                        setPage={setOrgPage}
                        disabled={
                          !canEditInformation ||
                          disableForCantEditRole ||
                          (view !== "create" && (roles === Roles.locationOwner || roles === Roles.organizationOwner))
                        }
                        setOptions={setOrgData}
                        requiredFields={!Array.isArray(organization) && !organization?.label && isRequiedFieldEmpty}
                      />
                    )}
                  </>
                ) : (
                  <Skeleton variant="rounded" height={40} width="100%" />
                )}
              </>
              <>
                {(locRelatedHasOrgSelected || requestViewLocRole) && (
                  <Box mt={8}>
                    <InputApiCallInfiniteScroll
                      initialValue={location}
                      name="loc.user"
                      setPropValue={setLocation}
                      changeWithInputText
                      apiCallFunction={(name: string) => {
                        getlocationsInfiniteScroll(name, !Array.isArray(organization) ? `${organization?.id}` : "");
                      }}
                      loading={loadingLoc || false}
                      label={`${t("GLOBAL-TITLE-LOCATION")} *`}
                      hasNextPage={locHasNextPage}
                      page={locPage}
                      options={locData}
                      setPage={setLocPage}
                      disabled={!canEditInformation || disableForCantEditRole || roles === Roles.locationOwner}
                      setOptions={setLocData}
                      requiredFields={!location?.label && isRequiedFieldEmpty}
                      alwaysCallApi
                    />
                  </Box>
                )}
              </>
            </>
          )}
        </>
      )}
    </Box>
  );
}
