/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { useEffect, useState } from "react";
//**Components */
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Input, InputApiCallInfiniteScroll, ModalConfirm } from "components";
import { MapOrg } from "components/atoms/Map/MapOrg";
import ClaimOrgSearch from "pages/organizations/components/molecules/claimSearch/ClaimOrgSearch";
//**Hooks */ */
import { useInputValueContext, useLocationInfiniteScroll, useRoleGuard } from "hooks";
import { useOrganizationContext } from "pages/organizations/context";
import useMapOrgData from "pages/organizations/hooks/useMapOrgData";
import useSetGoogleMapDataOrg from "pages/organizations/hooks/useSetGoogleMapDataOrg";
import { useTranslation } from "react-i18next";
import useAutorizeUserByRole from "utilities/hooks/useAuthorizeUserRole";
//**Vars */
import { Roles } from "models";
import { useParams } from "react-router-dom";
import { useChangeHq } from "pages/organizations/hooks";

export default function MapOrgView({ createView }: { createView?: boolean }) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  //**Context */
  const {
    orgSelectedMark,
    locationsMapMarksArray,
    name,
    setLocationHQName,
    locationHQName,
    alertRequiredFields,
    locationHq,
    newHqData,
    setOrgHasChanges,
  } = useOrganizationContext();

  const { setNewHqData, setLocationHq } = useOrganizationContext();
  const {
    getlocationsInfiniteScroll,
    locHasNextPage,
    locData,
    locPage,
    setLocPage,
    setLocData,
    loading: loadingHq,
  } = useLocationInfiniteScroll(true);

  //**Vars */
  const heightDesktop = "70vh";
  const heightMobile = "60vh";
  const isRequiedFieldEmpty = createView ? alertRequiredFields : true;

  //**Hooks */
  const smAbove = useMediaQuery("(min-width:1200px)"); //** Left bar over or half */
  const { id } = useParams();

  const { t } = useTranslation();
  const { loadList, onSearch } = useMapOrgData(name);
  const { getMapsData, loading } = useSetGoogleMapDataOrg();
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.organizationOwner, Roles.organizationEditor]);

  //**Functions */
  const urlLocationName = useInputValueContext("", setLocationHQName);
  const changeControllerFn = () => setOrgHasChanges(true);

  const { isUserAuthorized } = useAutorizeUserByRole();
  useEffect(() => {
    if (!orgSelectedMark) return;
    getMapsData(orgSelectedMark);
  }, [orgSelectedMark]);

  //**Modal */

  const { updateFieldsWithHQSelected } = useChangeHq();
  const handleConfirm = async () => {
    try {
      setOpenModal(false);
      changeControllerFn();
      updateFieldsWithHQSelected(newHqData);
    } catch (e) {
      console.error(e);
    }
  };
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Typography variant="h2" sx={{ mt: 8, fontWeight: 600 }}>
        {t("ORG-CREATE-NEW-HQ")}
      </Typography>
      <Typography variant="h6">{t("ORG-CREATE-NEW-HQ-DESCRIPTION")}</Typography>

      <Box mt={8}>
        {createView ? (
          <Input
            name="org-name"
            value={locationHQName}
            onChange={urlLocationName.onChange}
            label={`${t("INPUT-HQ-NAME")}*`}
            fullWidth
            disabled={!authUpdateOrganization}
            error={locationHQName?.length === 0 && isRequiedFieldEmpty}
            helperText={`${t("ALL-REQUIRED.INPUT")}`}
            changeControllerFn={changeControllerFn}
          />
        ) : (
          <InputApiCallInfiniteScroll
            initialValue={locationHq}
            name="loc.user"
            setPropValue={(value: any) => {
              setLocationHq(value);
              setNewHqData(value);
              setOpenModal(true);
            }}
            disabled={!authUpdateOrganization}
            changeWithInputText
            apiCallFunction={(name: string) => {
              getlocationsInfiniteScroll(name, id || "");
            }}
            loading={loadingHq || false}
            label={`${t("INPUT-HQ-NAME")}*`}
            hasNextPage={locHasNextPage}
            page={locPage}
            options={locData}
            setPage={setLocPage}
            setOptions={setLocData}
          />
        )}
      </Box>
      <Box position="relative" mb={6}>
        <ClaimOrgSearch onSearch={onSearch} />
        <MapOrg
          positions={locationsMapMarksArray}
          mapHeight={smAbove ? heightDesktop : heightMobile}
          options={{
            mapTypeControlOptions: { position: 3 },
            draggable: isUserAuthorized([Roles.superAdmin, Roles.organizationOwner, Roles.organizationEditor]),
          }}
          loading={loading}
          loadList={loadList}
        />
      </Box>
      <ModalConfirm
        open={openModal}
        title={t("LOCATION-HQ-CHANGE-TITLE")}
        description={t("LOCATION-HQ-CHANGE-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </>
  );
}
