import { Box, Switch, Typography } from "@mui/material";
import { Input } from "components";
import { useInputValueContext } from "hooks";
import { useLocationContext } from "pages/locations/context";

import { useTranslation } from "react-i18next";

export default function FacilityInfoRespEdit() {
  const { t } = useTranslation();
  const { amountOfEmployees, facilitySquareFootage, yearFounded, sustainable } = useLocationContext();
  const { setAmountOfEmployees, setFacilitySquareFootage, setYearFounded, setSustainable } = useLocationContext();

  const amountOfEmployeesInput = useInputValueContext("", setAmountOfEmployees);
  const facilitySquareFootageInput = useInputValueContext("", setFacilitySquareFootage);

  const handleChangeYear = (event: any) => {
    const data = event.target.value;
    setYearFounded(data.replace(/[^0-9]/g, ""));
  };
  return (
    <Box px={6} pb={6} sx={{ backgroundColor: "background.paper" }}>
      <Box display="flex" mb={4}>
        <Box display="flex" width="100%">
          <Box width="100%" alignContent="center">
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-AMOUNT-OF-EMPLOYEES")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box width="100%">
            <Input
              name="amount-of-employees"
              value={amountOfEmployees}
              onChange={amountOfEmployeesInput.onChange}
              type="number"
              fullWidth
              size="small"
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" mb={4}>
        <Box display="flex" width="100%">
          <Box width="100%" alignContent="center">
            <Typography variant="h2" sx={{ fontWeight: 700 }} pr={2}>
              {`${t("INPUT-FACILITY-SQUARE")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box width="100%">
            <Input
              name="facilitySquareFootage"
              type="number"
              value={facilitySquareFootage}
              onChange={facilitySquareFootageInput.onChange}
              fullWidth
              size="small"
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" mb={4} width="100%">
        <Box display="flex" width="100%">
          <Box width="100%" alignContent="center">
            <Typography variant="h2" sx={{ fontWeight: 700 }} mr={2}>
              {`${t("INPUT-YEAR-FOUNDED")}: `.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Box width="100%" alignItems="center" display="flex">
          <Box width="100%">
            <Input
              maxLength={4}
              name="year-founded"
              value={yearFounded === 0 ? "" : yearFounded}
              onChange={handleChangeYear}
              fullWidth
              size="small"
            />
          </Box>
        </Box>
        <Box width="100%" alignItems="center" display="flex">
          <Typography variant="h2" sx={{ fontWeight: 700 }} pl={4}>
            {`${t("INPUT-SUSTAINABLE")}: `.toUpperCase()}
          </Typography>
          <Switch
            checked={sustainable}
            onChange={() => {
              setSustainable((prev) => !prev);
            }}
            size="small"
          />
        </Box>
      </Box>
    </Box>
  );
}
