import { useState } from "react";
//** MUI Imports */
import { IconButton, Stack, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { Button, ModalConfirm } from "components";
import { PrivateRoutes, Roles } from "models";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
// import { useRoleGuard } from "hooks";
//**Models */
import isUrl from "is-url";
//**Redux */
import { useLocationContext } from "pages/locations/context";
import { RoleGuard } from "guards";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDeleteLocations } from "pages/locations/hooks";
import { useUpdateLocation } from "../../hooks";
import { purposeCodeforAplicableBrand } from "../../../../constants/purposesToConditions";

export default function ButtonUpdateLocation() {
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  //**Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { updateLocation, completeLoading } = useUpdateLocation();
  const { deleteLocations } = useDeleteLocations();
  const { id } = useParams();
  //**Context */
  //** Step */
  const { purpose, isValidEmail, isValidPhone, keyContacts } = useLocationContext();
  //** Others Step */
  const { name, organization, applicableBrand, initalUrl } = useLocationContext();
  const { email, phone, website, marketCountry } = useLocationContext();
  const { city, postalCode, latitude, longitude, state, region } = useLocationContext();
  //* location Services */
  const { displayOrder } = useLocationContext();
  //* SBDUSE*/
  const { isValidSBDUSEmails } = useLocationContext();
  //** See which apps are selected */
  const isAplicableBrandRequired =
    purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`)) && applicableBrand.length === 0;

  const disableButton =
    !name ||
    !organization?.label ||
    !marketCountry?.label ||
    !region?.label ||
    (!isUrl(`${initalUrl}${website}`) && website.length > 0) ||
    !city ||
    !postalCode ||
    !latitude ||
    !longitude ||
    !state ||
    (email?.length > 0 && !isValidEmail) ||
    (phone?.length > 0 && !isValidPhone) ||
    purpose.length === 0 ||
    // This fields are mandaroty only if the app is selected
    (purpose.findIndex((item) => item.code === "OR") !== -1 ? !displayOrder : false) ||
    isAplicableBrandRequired ||
    purpose.length === 0 ||
    !isValidSBDUSEmails.mngDirectorEmail ||
    keyContacts?.some((item) => !item.isValidEmail);

  const handleCloseDelete = () => setOpenModalDelete(false);
  return (
    <>
      {underMd ? (
        <RoleGuard
          authorizedRoles={[
            Roles.superAdmin,
            Roles.organizationEditor,
            Roles.organizationOwner,
            Roles.locationOwner,
            Roles.locationEditor,
            Roles.multiOrgOwner,
          ]}
        >
          <IconButton onClick={updateLocation} disabled={disableButton || completeLoading}>
            <CheckCircleIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </RoleGuard>
      ) : (
        <Stack direction="row" gap={4} mt={8}>
          <RoleGuard
            authorizedRoles={[
              Roles.superAdmin,
              Roles.organizationEditor,
              Roles.organizationOwner,
              Roles.locationOwner,
              Roles.locationEditor,
              Roles.multiOrgOwner,
            ]}
          >
            <Button
              name="loc-update"
              onClick={updateLocation}
              label={t("BUTTON-UPDATE")}
              size="large"
              disabled={disableButton || completeLoading}
            />
          </RoleGuard>
          <Button
            name="loc-cancel-update"
            onClick={() => {
              navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: true });
            }}
            label={t("BUTTON-CANCEL")}
            size="large"
            color="secondary"
            variant="outlined"
          />

          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationOwner, Roles.organizationEditor, Roles.locationOwner]}>
            <Button
              capitalize
              name="location-delete"
              onClick={() => setOpenModalDelete(true)}
              label={t("BUTTON-DELETE")}
              size="large"
              variant="text"
            />
          </RoleGuard>

          <RoleGuard
            authorizedRoles={[
              Roles.superAdmin,
              Roles.organizationOwner,
              Roles.organizationEditor,
              Roles.locationOwner,
              Roles.multiOrgOwner,
            ]}
          >
            <ModalConfirm
              open={openModalDelete}
              title={t("LOCATIONS-DELETE-MODAL-CONFIRM-TITLE")}
              description={t("LOCATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
              textButtonConfirm={t("BUTTON-ACCEPT")}
              handleClose={handleCloseDelete}
              handleConfirm={() => {
                deleteLocations([`${id}`]);
                setOpenModalDelete(false);
                navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`, { replace: false });
              }}
              sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
              sxTitle={{ fontSize: "2rem !important" }}
            />
          </RoleGuard>
        </Stack>
      )}
    </>
  );
}
