import { useGlobalContext } from "context/globalContext";
import { useFetchAndLoad } from "hooks";
import { useLocationContext } from "pages/locations/context";
import { IMapsAdditionalItemFromAPI } from "pages/locations/models";
import { googleMapsAdditionalDataAPI } from "pages/locations/services";
import { hasHttp } from "utilities";

export default function useSetGoogleMapData() {
  const {
    setAddress1,
    setCity,
    setPostalCode,
    setLatitude,
    setLongitude,
    setMarketCountry,
    setState,
    setGoogleMapsInputs,
    setMapId,
    setRegion,
    setInitalUrl,
  } = useLocationContext();
  const { setPhone, setWebsite, setOpeningHours } = useLocationContext();
  const { setPositions } = useGlobalContext();
  const getAdditionalGoogleData = useFetchAndLoad();

  //For location information google send a array with information split on array with codes
  //here we take those codes for select the data that we need
  let postalCodeGoogle = "";
  let cityGoogle = "";
  let marketGoogle = "";
  let stateGoogle = "";

  const setDataToCompareGM = (additionalData: any, data: any) => {
    setGoogleMapsInputs({
      address1: data[0]?.formatted_address,
      market: marketGoogle,
      city: cityGoogle,
      state: stateGoogle,
      region: additionalData.region,
      postalCode: postalCodeGoogle,
      coordinates_latitude: `${additionalData.coordinates[0]}`,
      coordinates_longitude: `${additionalData.coordinates[1]}`,
      phone: additionalData.phone,
      // eslint-disable-next-line no-nested-ternary
      website: additionalData.website,
      openingHours: {
        monday: {
          opening: additionalData.open_hours?.monday?.opening,
          closing: additionalData.open_hours?.monday?.closing,
          is_enabled: additionalData.open_hours?.monday?.is_enabled,
        },
        tuesday: {
          opening: additionalData.open_hours?.tuesday?.opening,
          closing: additionalData.open_hours?.tuesday?.closing,
          is_enabled: additionalData.open_hours?.tuesday?.is_enabled,
        },
        wednesday: {
          opening: additionalData.open_hours?.wednesday?.opening,
          closing: additionalData.open_hours?.wednesday?.closing,
          is_enabled: additionalData.open_hours?.wednesday?.is_enabled,
        },
        thursday: {
          opening: additionalData.open_hours?.thursday?.opening,
          closing: additionalData.open_hours?.thursday?.closing,
          is_enabled: additionalData.open_hours?.thursday?.is_enabled,
        },
        friday: {
          opening: additionalData.open_hours?.friday?.opening,
          closing: additionalData.open_hours?.friday?.closing,
          is_enabled: additionalData.open_hours?.friday?.is_enabled,
        },
        saturday: {
          opening: additionalData.open_hours?.saturday?.opening,
          closing: additionalData.open_hours?.saturday?.closing,
          is_enabled: additionalData.open_hours?.saturday?.is_enabled,
        },
        sunday: {
          opening: additionalData.open_hours?.sunday?.opening,
          closing: additionalData.open_hours?.sunday?.closing,
          is_enabled: additionalData.open_hours?.sunday?.is_enabled,
        },
      },
    });
  };

  const getGMDataForInfoView = async (response: any) => {
    //Get google api response
    const data = response?.data?.results;
    const responseAdd = await getAdditionalGoogleData.callEndpoint(googleMapsAdditionalDataAPI({ mapId: data[0]?.place_id }));
    const additionalData: IMapsAdditionalItemFromAPI = responseAdd?.data?.result;

    // eslint-disable-next-line no-plusplus
    data[0]?.address_components.forEach((addressComponent: any) => {
      if (addressComponent.types.includes("postal_code")) {
        postalCodeGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("locality")) {
        cityGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("country")) {
        marketGoogle = addressComponent?.short_name;
      }
      if (addressComponent.types.includes("administrative_area_level_1")) {
        stateGoogle = addressComponent?.long_name;
      }
    });
    //Set google maps inputs
    setDataToCompareGM(additionalData, data);
  };

  const onPlaceChanged = async (response: any) => {
    //Get google api response
    const data = response?.data?.results;
    const responseAdd = await getAdditionalGoogleData.callEndpoint(googleMapsAdditionalDataAPI({ mapId: data[0]?.place_id }));
    const additionalData: IMapsAdditionalItemFromAPI = responseAdd?.data?.result;
    //Get data from google api
    setMapId(data[0]?.place_id);
    setAddress1(data[0]?.formatted_address);
    setRegion({ label: additionalData.region, id: additionalData.region });
    setLatitude(`${additionalData.coordinates[0]}`);
    setLongitude(`${additionalData.coordinates[1]}`);
    setPositions([
      {
        lat: +additionalData.coordinates[0],
        lng: +additionalData.coordinates[1],
      },
    ]);

    // eslint-disable-next-line no-plusplus
    data[0]?.address_components.forEach((addressComponent: any) => {
      if (addressComponent.types.includes("postal_code")) {
        setPostalCode(addressComponent?.long_name);
        postalCodeGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("locality")) {
        setCity(addressComponent?.long_name);
        cityGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("country")) {
        setMarketCountry({ label: addressComponent?.short_name, id: addressComponent?.short_name });
        marketGoogle = addressComponent?.short_name;
      }
      if (addressComponent.types.includes("administrative_area_level_1")) {
        setState(addressComponent?.long_name);
        stateGoogle = addressComponent?.long_name;
      }
    });

    //Additional data
    setPhone(additionalData.phone);
    setWebsite(hasHttp(additionalData.website));
    setInitalUrl(additionalData.website.includes("https") ? "https://" : "http://");
    const days: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const openingHoursData = days.map((day, index) => ({
      label: day,
      id: index,
      opening: additionalData.open_hours[`${day.toLocaleLowerCase()}`]?.opening,
      closing: additionalData.open_hours[`${day.toLocaleLowerCase()}`]?.closing,
      is_enabled: additionalData.open_hours[`${day.toLocaleLowerCase()}`]?.is_enabled,
    }));
    setOpeningHours(openingHoursData);
    //Set google maps inputs
    setDataToCompareGM(additionalData, data);
  };

  return { onPlaceChanged, getGMDataForInfoView };
}
