/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { ModalConfirm } from "components";
import { Box, IconButton, Switch, Theme, Tooltip } from "@mui/material";
import { useFetchAndLoad, useToastMui } from "hooks";
import { useLocationListContext } from "pages/locations/pages";
import { useTranslation } from "react-i18next";
import { bulkLocationAPI } from "pages/locations/services";
import { IBulkLocation, ILocationsListDOM } from "pages/locations/models";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDeleteLocations } from "pages/locations/hooks";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { BulkLocMoreOptions } from "../../molecules";

enum BulkActions {
  VISIBLE = "VISIBLE",
  HIDE = "HIDE",
  ORGANIZATION = "ORGANIZATION",
}

interface IBulk {
  is_visible?: boolean;
  organization_id?: string;
  action?: BulkActions;
}

export default function LocBulkActions({ onSearch, hasSelectedItems }: { onSearch: Function; hasSelectedItems: boolean }) {
  //** Context */
  const { selectedClaim, setSelectedClaim, locationsMapListArray, setLocationsMapListArray } = useLocationListContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [bulkObject, setBulkObject] = useState<IBulk>({});
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [activeCheck, setActiveCheck] = useState<boolean>(false);
  const [tempState, setTempState] = useState<ILocationsListDOM[]>([]);

  useEffect(() => {
    if (selectedClaim.length >= 100) return setActiveCheck(false);

    const initialStatus = selectedClaim.reduce(
      (acc: { true: number; false: number }, claim: { status: boolean }) => {
        acc[`${claim.status}`] = (acc[`${claim.status}`] || 0) + 1;
        return acc;
      },
      { true: 0, false: 0 },
    );
    const mostFrequentStatus = initialStatus.true >= initialStatus.false;
    setActiveCheck(mostFrequentStatus);
  }, [selectedClaim]);

  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { deleteLocations } = useDeleteLocations();
  //** Function */
  const handleBulk = async (bulkObj: IBulk | null) => {
    try {
      //** Save old state with optimistic update*/
      setTempState(locationsMapListArray);

      delete bulkObject.action;

      //** update loaded elements (optimistic update) */
      const locIds = selectedClaim.map((item) => item.id);
      setLocationsMapListArray((prev) => {
        const locCopy = [...prev];
        locIds.forEach((locId) => {
          const orgIndex = locationsMapListArray.findIndex((item) => item.id === locId);
          locCopy[orgIndex] = { ...prev[orgIndex], isVisible: bulkObject.is_visible ?? prev[orgIndex].isVisible };
        });
        return [...locCopy];
      });

      //**Bulk action */
      let body: IBulkLocation = { ...bulkObject, item_id: selectedClaim.map((item) => item.id) };
      if (bulkObj) body = { ...bulkObj, item_id: selectedClaim.map((item) => item.id) };
      setOpenModal(false);

      await callEndpoint(bulkLocationAPI({ body }), (error: any) => {
        handleCreateToast(error?.response?.data?.message || t("ALERT-ERROR-UPDATE"), "error");
      });

      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
      setSelectedClaim([]);
      onSearch();
    } catch (error) {
      console.log(error);
      setLocationsMapListArray(tempState);
    }
  };
  // Handle  dialog
  const handleClickOpen = (bulkQuery: IBulk) => {
    if (bulkQuery.action !== BulkActions.ORGANIZATION) {
      setOpenModal(true);
    }
    setBulkObject(bulkQuery);
  };
  const handleClose = () => setOpenModal(false);
  const handleCloseDelete = () => setOpenModalDelete(false);

  const modalTitle =
    bulkObject.action === BulkActions.VISIBLE
      ? `Make visible ${selectedClaim.length} locations?`
      : bulkObject.action === BulkActions.HIDE
      ? `Hide ${selectedClaim.length} locations?`
      : `Change the organization of  ${selectedClaim.length} locations?`;

  const modalContent =
    bulkObject.action === BulkActions.VISIBLE
      ? t("BULK-LOC-VISIBLE")
      : bulkObject.action === BulkActions.HIDE
      ? t("BULK-LOC-HIDE")
      : `${t("BULK-ORG-DESCRIPTION")}`;

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const action = event.target.checked ? BulkActions.VISIBLE : BulkActions.HIDE;
    handleClickOpen({ is_visible: event.target.checked, action });
    setActiveCheck(event.target.checked);
  };
  const hasHQSelected = selectedClaim.some((item) => item.isHQ === true);

  return (
    <Box display="flex">
      <RoleGuard
        authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor, Roles.locationOwner]}
      >
        <Switch checked={activeCheck} onChange={handleChangeActive} disabled={!hasSelectedItems} color="success" />
        <Tooltip title={t("ICON-DELETE")}>
          <IconButton onClick={() => setOpenModalDelete(true)} disabled={!hasSelectedItems}>
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 22 }} />
          </IconButton>
        </Tooltip>
      </RoleGuard>

      <BulkLocMoreOptions
        BulkActions={BulkActions}
        handleBulk={handleBulk}
        hasSelectedItems={hasSelectedItems}
        hasHQSelected={hasHQSelected}
      />

      <ModalConfirm
        open={openModal}
        title={modalTitle}
        description={modalContent}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleClose}
        handleConfirm={() => handleBulk(null)}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title={`Delete ${selectedClaim.length} locations?`}
        description={hasHQSelected ? t("LOCATIONS-DELETE-MODAL-ERROR-HQ") : t("LOCATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteLocations(selectedClaim.map((item) => item.id));
          setOpenModalDelete(false);
          onSearch();
        }}
        disableAccept={hasHQSelected}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
