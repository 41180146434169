import { useState } from "react";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchAndLoad, useToastMui } from "hooks";
import useGetPictures from "hooks/useGetPictures";
//**Context */
import { useLocationContext } from "pages/locations/context";
//**SERVICES */
import { changeSBDConnectLocationAPI, updateLocationAPI } from "pages/locations/services";
import { IUpdateBodyLocation } from "pages/locations/services/api.interfaces";
import { capitalize, hasHttp } from "utilities";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { PrivateRoutes } from "models";
import { useGlobalContext } from "context/globalContext";
import { Theme, useMediaQuery } from "@mui/material";
import { changeHQLocationAPI } from "pages/organizations/services";
import { useUpdateUsers } from "pages/users/components/molecules/UpdateUserButtons/hooks";

export default function useUpdateLocation() {
  //**Redux */
  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const userState = useSelector((store: AppStore) => store.user);
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { updateUserPrimaryContact } = useUpdateUsers();

  //**Context */
  //** Step */
  const { purpose, setUpdateToggle } = useLocationContext();
  //** Others Step */
  const { name, organization, applicableBrand, category, isVisible, initalUrl } = useLocationContext();
  const { email, phone, website, fax, openingHours, marketCountry } = useLocationContext();
  const { address1, address2, city, postalCode, latitude, longitude, state, mapId, region } = useLocationContext();
  //* location Services */
  const { displayOrder, isPremium, isFactory, isOnlineOnly, isHQLocation } = useLocationContext();
  const { SBDConnectContact, dataCopy } = useLocationContext();
  const { setLocEditMode, setUserEditMode } = useGlobalContext();
  //* SBDUSE*/
  const {
    descriptionSBDUS,
    amountOfEmployees,
    facilitySquareFootage,
    business,
    mainLocationImage,
    locationImagesAdditional,
    brandsPictures,
  } = useLocationContext();
  const { sustainable, yearFounded, mngDirector, keyContacts } = useLocationContext();
  const { primaryContact } = useLocationContext();

  //**Hooks */
  const { t } = useTranslation();
  const { callEndpoint, loading } = useFetchAndLoad();
  const hqFetch = useFetchAndLoad();
  const sbdFetch = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { handleGetCompressedBase64 } = useGetPictures();
  const { id } = useParams();

  const updateHqLocation = async (organizationId: string, locationId: string) => {
    try {
      await hqFetch.callEndpoint(changeHQLocationAPI(organization?.id || organizationId, locationId), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const updateLocationSBDConnect = async (LocationId: string, SBDConnectId: string) => {
    try {
      //** Update users data */
      await sbdFetch.callEndpoint(changeSBDConnectLocationAPI(LocationId, SBDConnectId), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );
    } catch (error) {
      console.log(error);
    }
  };
  //** Hook to update location */
  const updateLocation = async () => {
    try {
      setCompleteLoading(true);
      //** Get the base 64 of compressed images */
      let additionalImagesCompressed: string[] = [];
      let brandPicturesCompressed: string[] = [];
      const mainImageCompressed = await handleGetCompressedBase64(mainLocationImage, 0);

      //** Get all compressed images data */
      for (let i = 0; i < locationImagesAdditional.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const imageCompressed = await handleGetCompressedBase64(locationImagesAdditional, i);
        additionalImagesCompressed = [...additionalImagesCompressed, imageCompressed];
      }
      for (let i = 0; i < brandsPictures.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const imageCompressed = await handleGetCompressedBase64(brandsPictures, i);
        brandPicturesCompressed = [...brandPicturesCompressed, imageCompressed];
      }

      //** Create a location with the location data */

      //if an application is inactive, the purpose will be deleted
      let body: IUpdateBodyLocation = {
        address: {
          address1,
          address2,
          city,
          postal_code: postalCode,
          coordinates: [latitude, longitude],
          state,
          maps_id: mapId,
          region: region.id || "",
        },
        brands: applicableBrand?.map((item) => `${item.code}`),
        categories_products: category,
        contact: {
          email,
          phone,
          fax,
          // eslint-disable-next-line no-nested-ternary
          website: website ? `${initalUrl}${hasHttp(website)}` : "",
          key_contacts: keyContacts.map((item) => ({
            name: item.name || "",
            title: item.title || "",
            email: item.email || "",
            phone: item.phone || "",
          })),
          managing_director_or_leader: {
            name: mngDirector?.name || "",
            title: mngDirector?.title || "",
            email: mngDirector?.email || "",
            phone: mngDirector?.phone || "",
          },
        },
        is_visible: isVisible,

        market_or_country: `${marketCountry ? marketCountry?.label : ""}`,
        name,
        open_hours: {
          1: {
            opening: openingHours[0].opening,
            closing: openingHours[0].closing,
            is_enabled: openingHours[0].is_enabled,
          },
          2: {
            opening: openingHours[1].opening,
            closing: openingHours[1].closing,
            is_enabled: openingHours[1].is_enabled,
          },
          3: {
            opening: openingHours[2].opening,
            closing: openingHours[2].closing,
            is_enabled: openingHours[2].is_enabled,
          },
          4: {
            opening: openingHours[3].opening,
            closing: openingHours[3].closing,
            is_enabled: openingHours[3].is_enabled,
          },
          5: {
            opening: openingHours[4].opening,
            closing: openingHours[4].closing,
            is_enabled: openingHours[4].is_enabled,
          },
          6: {
            opening: openingHours[5].opening,
            closing: openingHours[5].closing,
            is_enabled: openingHours[5].is_enabled,
          },
          7: {
            opening: openingHours[6].opening,
            closing: openingHours[6].closing,
            is_enabled: openingHours[6].is_enabled,
          },
        },
        //** If the user have and organization the location will be assigned to that organization */
        organization_id: `${userState.organization?.id ? userState.organization?.id : organization.id}`,
        purpose_codes: purpose.map((item) => `${item.code}`),
        amount_of_employees: +amountOfEmployees,
        description: descriptionSBDUS,
        facility_square_footage: +facilitySquareFootage,
        business,
        sustainability: sustainable,
        images: additionalImagesCompressed,
        brand_manufactured: brandPicturesCompressed,
        main_image: mainImageCompressed,
        display_order: displayOrder,
        factory: isFactory,
        premium: isPremium,
        is_online_only: isOnlineOnly,
      };

      if (yearFounded) {
        body = { ...body, year_founded: +yearFounded };
      }

      //** Is important to update first the HQ */
      if (dataCopy.isHqLocation !== isHQLocation) await updateHqLocation(organization.id || "", id || "");

      if (SBDConnectContact?.id && dataCopy?.sbdConnect?.id !== SBDConnectContact?.id) {
        await updateLocationSBDConnect(id || "", SBDConnectContact?.id || "");
      }

      if (primaryContact?.id && dataCopy?.primaryContact?.id !== primaryContact?.id) {
        await updateUserPrimaryContact(primaryContact?.id || "", id || "", "Location");
      }

      await callEndpoint(updateLocationAPI(id ? `${id}` : `${organization?.id}`, { body }), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );

      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
      setUpdateToggle((prev) => !prev);

      setUserEditMode(false);
      setCompleteLoading(false);
      if (underMd) setLocEditMode(false);
      else navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: true });
    } catch (error) {
      setCompleteLoading(false);
    }
  };

  return {
    updateLocation,
    loading,
    updateHqLocation,
    loadingHq: hqFetch.loading,
    updateLocationSBDConnect,
    loadingSbd: sbdFetch.loading,
    completeLoading,
  };
}
