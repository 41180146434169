/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { SnackbarOrigin, VariantType, useSnackbar } from "notistack";
import "./style.css";
import { ReactNode } from "react";

export enum ToastVariant {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
  default = "default",
}

const notificationMarginTop = 60; //? This only is added for right-top placement, delete if this change/

export default function useToastMui() {
  const { enqueueSnackbar } = useSnackbar();
  const handleCreateToast = (message: string | ReactNode, variant: VariantType, persist?: boolean, origin?: SnackbarOrigin) => {
    const anchorOrigin: SnackbarOrigin = origin || { horizontal: "right", vertical: "top" };
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      anchorOrigin,
      className: `alert ${variant}`,
      persist,
      style: { zIndex: 1000002, marginTop: notificationMarginTop },
    });
  };

  return { handleCreateToast };
}
