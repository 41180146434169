/* eslint-disable max-len */
import { Box } from "@mui/material";
import { RoleGuard } from "guards";
import { Roles } from "models";

import { useGetReduxUser } from "hooks";
import { useParams } from "react-router-dom";
import ButtonsInformation from "pages/organizations/pages/organizationsInformation/components/molecules/ButtonsInformation/ButtonsInformation";

export default function OrganizationEditButtonResp() {
  const { id } = useParams();
  const { userState } = useGetReduxUser();
  const multiOrgCanEdit =
    userState?.roles === Roles.multiOrgOwner ? userState.organizations?.find((item) => item.id.includes(`${id}`)) : true;

  return (
    <>
      {multiOrgCanEdit && (
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationEditor, Roles.organizationOwner, Roles.multiOrgOwner]}>
          <Box>
            <ButtonsInformation />
          </Box>
        </RoleGuard>
      )}
    </>
  );
}
