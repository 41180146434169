import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import { GoogleMapsMUI, Input, InputApiCallInfiniteScroll } from "components";
import { useGetOrganizationsInfiniteScroll, useInputValueContext } from "hooks";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";
import { RoleGuard } from "guards";
import { Roles } from "models";
import isUrl from "is-url";
import useSetGoogleMapData from "../../../hooks/useSetGoogleMapData";

export default function LocAddressRespEdit() {
  //States
  const {
    address1,
    state,
    city,
    mapId,
    alertRequiredFields,
    address2,
    googleMapsInputs,
    postalCode,
    descriptionSBDUS,
    organization,
    website,
    initalUrl,
    view,
    isHQLocation,
  } = useLocationContext();
  const { setAddress2, setCity, setPostalCode, setState, setDescriptionSBDUS, setOrganization, setWebsite, setInitalUrl } =
    useLocationContext();
  const { t } = useTranslation();

  const { onPlaceChanged, getGMDataForInfoView } = useSetGoogleMapData();

  const address2Input = useInputValueContext("", setAddress2);
  const cityInput = useInputValueContext("", setCity);
  const postalCodeInput = useInputValueContext("", setPostalCode);
  const stateInput = useInputValueContext("", setState);
  const descriptionSBDUSInput = useInputValueContext("", setDescriptionSBDUS);
  const websiteInput = useInputValueContext("", setWebsite);

  const cityGoogleCompare = !!city && !!googleMapsInputs?.city && googleMapsInputs?.city !== city;
  const postalCodeGoogleCompare = !!postalCode && !!googleMapsInputs?.postalCode && googleMapsInputs?.postalCode !== postalCode;
  const stateGoogleCompare = !!state && !!googleMapsInputs?.state && googleMapsInputs?.state !== state;
  const webGoogleCompare = !!website && !!googleMapsInputs?.website && googleMapsInputs?.website !== `${initalUrl}${website}`;

  const websiteWithOutProtocol = googleMapsInputs?.website?.replace("https://", "").replace("http://", "") || "";

  const { getOrganizationsInfiniteScroll, hasNextPage, orgData, loading, orgPage, setOrgPage, setOrgData } =
    useGetOrganizationsInfiniteScroll();

  //**Required inputs management */
  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields[1] : true;
  const isRequiedFieldEmptyBasic = view === "create" ? alertRequiredFields[0] : true;

  return (
    <Box px={6} py={6} sx={{ backgroundColor: "background.paper" }}>
      <Box display="flex">
        <Box width="100%">
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%" alignItems="flex-start">
              <Box width="100px" alignContent="center" mt={2}>
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-ADDRESS-1")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                {address1 ? (
                  <GoogleMapsMUI
                    multiline
                    onPlaceChanged={onPlaceChanged}
                    initialValue={mapId}
                    label=""
                    size="small"
                    getGMDataForInfoView={getGMDataForInfoView}
                    requiredFields={!mapId && isRequiedFieldEmpty}
                    alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
                  />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
              </Box>
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-ADDRESS-2")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input name="loc-address2" value={address2} onChange={address2Input.onChange} size="small" fullWidth />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" mb={4} width="100%" alignContent="center" mr={8}>
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-STATE")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input
                  name="loc-state"
                  value={state}
                  onChange={stateInput.onChange}
                  fullWidth
                  color={stateGoogleCompare && "warning"}
                  focused={stateGoogleCompare}
                  error={state?.length === 0 && isRequiedFieldEmpty}
                  helperText={`${t("ALL-REQUIRED.INPUT")}`}
                  endAdornment={
                    stateGoogleCompare && googleMapsInputs?.state && <ReturnDataIcon onClick={() => setState(googleMapsInputs.state)} />
                  }
                  size="small"
                />
              </Box>
            </Box>
            <Box display="flex" mb={4} width="100%" alignContent="center">
              <Box width="50px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-CITY")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 50px)">
                <Input
                  name="loc-city"
                  value={city}
                  onChange={cityInput.onChange}
                  fullWidth
                  color={cityGoogleCompare && "warning"}
                  focused={cityGoogleCompare}
                  error={city?.length === 0 && isRequiedFieldEmpty}
                  helperText={`${t("ALL-REQUIRED.INPUT")}`}
                  endAdornment={
                    cityGoogleCompare && googleMapsInputs?.city && <ReturnDataIcon onClick={() => setCity(googleMapsInputs.city)} />
                  }
                  size="small"
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Box width="130px" alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-POSTAL-CODE")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 260px)">
              <Input
                name="loc-postal-code"
                value={postalCode}
                onChange={postalCodeInput.onChange}
                fullWidth
                size="small"
                color={postalCodeGoogleCompare && "warning"}
                focused={postalCodeGoogleCompare}
                error={postalCode?.length === 0 && isRequiedFieldEmpty}
                helperText={`${t("ALL-REQUIRED.INPUT")}`}
                endAdornment={
                  postalCodeGoogleCompare &&
                  googleMapsInputs?.postalCode && <ReturnDataIcon onClick={() => setPostalCode(googleMapsInputs.postalCode)} />
                }
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  DESC:
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input
                  name="description"
                  multiline
                  value={descriptionSBDUS}
                  onChange={descriptionSBDUSInput.onChange}
                  label=""
                  fullWidth
                  rows={2}
                />
              </Box>
            </Box>
          </Box>
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.generalViewOnly]}>
            <Box display="flex" mb={4}>
              <Box display="flex" width="100%">
                <Box width="100px" alignContent="center">
                  <Typography variant="h2" sx={{ fontWeight: 700 }}>
                    ORG:
                  </Typography>
                </Box>
                <Box width="calc(100% - 100px)">
                  <InputApiCallInfiniteScroll
                    initialValue={organization}
                    name="loc-organization"
                    disabled={isHQLocation}
                    setPropValue={setOrganization}
                    changeWithInputText
                    apiCallFunction={getOrganizationsInfiniteScroll}
                    loading={loading}
                    label=""
                    size="small"
                    hasNextPage={hasNextPage}
                    page={orgPage}
                    options={orgData}
                    setPage={setOrgPage}
                    setOptions={setOrgData}
                    requiredFields={(organization?.label?.length === 0 || organization === null) && isRequiedFieldEmptyBasic}
                    alertLabel={`${t("SELECT-ORG-INPUT")}`}
                  />
                </Box>
              </Box>
            </Box>
          </RoleGuard>
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-URL")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input
                  name="loc-website"
                  value={website}
                  onChange={websiteInput.onChange}
                  label=""
                  size="small"
                  fullWidth
                  color={webGoogleCompare && "warning"}
                  focused={webGoogleCompare}
                  endAdornment={
                    webGoogleCompare &&
                    googleMapsInputs?.website && (
                      <ReturnDataIcon
                        onClick={() => {
                          setWebsite(websiteWithOutProtocol);
                          setInitalUrl(googleMapsInputs.website.includes("https://") ? "https://" : "http://");
                        }}
                      />
                    )
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          value={initalUrl}
                          onChange={(event: SelectChangeEvent) => {
                            setInitalUrl(event.target.value);
                          }}
                          variant="standard"
                          disableUnderline
                          sx={{
                            marginTop: "5px",
                          }}
                        >
                          <MenuItem value="https://">https://</MenuItem>
                          <MenuItem value="http://">http://</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  error={!isUrl(`${initalUrl}${website}`) && website.length > 0}
                  helperText={`${t("INVALID-WEBSITE")}`}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
