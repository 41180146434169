import { Box, IconButton, Theme } from "@mui/material";
import { Chip, StatusBadge } from "components";
import { useLocationContext } from "pages/locations/context";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { useGlobalContext } from "context/globalContext";

export default function LocHeaderAditionalInfo() {
  const { region, marketCountry, isHQLocation, purpose, isVisible } = useLocationContext();
  const { setMapLocDetailView, mapLocDetailView } = useGlobalContext();

  const hasCorporateOffice = purpose.some((item) => item.code === "CO");

  return (
    <Box display="flex" ml={22} justifyContent="space-between" mt={mapLocDetailView ? 0 : 1}>
      <Box display="flex" gap={2} alignItems="center" flexWrap="wrap">
        <StatusBadge color={isVisible ? "success" : "secondary"} />
        {isHQLocation && <Chip label="HQ" size="small" color="success" sx={{ mr: 2 }} />}
        <Chip label={region.label} size="small" color="secondary" sx={{ mr: 2 }} />
        <Chip label={marketCountry?.label} size="small" color="secondary" sx={{ mr: 2 }} />
        {hasCorporateOffice && isHQLocation && (
          <Chip
            label={purpose.find((item) => item.code === "CO")?.label?.toLocaleUpperCase()}
            size="small"
            color="secondary"
            sx={{ mr: 2, fontSize: "1.35rem" }}
          />
        )}
      </Box>
      {mapLocDetailView && (
        <IconButton
          onClick={() => {
            setMapLocDetailView(false);
          }}
          sx={{ color: (theme: Theme) => theme.palette.text.primary }}
        >
          <ViewListOutlinedIcon sx={{ fontSize: 26 }} />
        </IconButton>
      )}
    </Box>
  );
}
