import { lazy } from "react";
import { PrivateRoutes } from "models";
import { Navigate, Route } from "react-router-dom";
import ErrorView from "pages/ErrorView";
import { ErrorBoundary, RoutesWithNotFound } from "utilities";
import { UserContextProvider } from "./context/UserContext";

const UsersList = lazy(() => import("./pages/usersList/UsersList"));
const UsersInformation = lazy(() => import("./pages/usersInformation/UsersInformation"));
const UsersCreate = lazy(() => import("./pages/usersCreate/UsersCreate"));
const UsersProfile = lazy(() => import("./pages/userProfile/UserProfile"));

export default function Users() {
  return (
    <ErrorBoundary fallBackComponent={<ErrorView />}>
      <UserContextProvider>
        <RoutesWithNotFound>
          <Route path="/" element={<Navigate to={PrivateRoutes.LIST} />} />
          <Route path={PrivateRoutes.LIST} element={<UsersList />} />
          <Route path={PrivateRoutes.INFORMATION} element={<UsersInformation />} />
          <Route path={PrivateRoutes.CREATE} element={<UsersCreate />} />
          <Route path={PrivateRoutes.USERS_PROFILE} element={<UsersProfile />} />
        </RoutesWithNotFound>
      </UserContextProvider>
    </ErrorBoundary>
  );
}
