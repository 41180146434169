/* eslint-disable no-param-reassign */
import { useState } from "react";
import { getAllUsersAPI, getOnlyAdminsUsersAPI } from "pages/users/pages/usersList/services";
import useFetchAndLoad from "./useFetchAndLoad";

export default function useUsersInfiniteScroll() {
  const [userPage, setUserPage] = useState<number>(0);
  const [userHasNextPage, setUserHasNextPage] = useState<boolean>(false);
  const [userData, setUserData] = useState<any[]>([]);
  const { callEndpoint, loading } = useFetchAndLoad();
  const getUsersInfiniteScroll = async (firstName: string, locationId: string, roles?: string, organizationId?: string) => {
    if (firstName === null) firstName = "";
    //** Provide next on api call */
    setUserHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = {
        offset: !firstName ? userPage * limit : 0,
        limit,
        location_id: locationId,
        role_id: roles || "",
        organization_id: organizationId || "",
      };
      if (firstName) {
        query = {
          first_name: typeof firstName === "string" ? firstName : "",
          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await callEndpoint(getAllUsersAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: any) => ({
        label: `${item.first_name} ${item.last_name} - ${item.username}`,
        id: item.id,
        phone: item.phone,
        email: item.username,
        desigantion: item.designation,
        firstName: item?.first_name,
        lastName: item?.last_name,
      }));

      //** If the firstName has no value, all results are displayed from an infinite scroll */
      if (!firstName) {
        //** Create an array with the previuous data an the new data */
        if (userPage !== 0) {
          setUserData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setUserData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setUserPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setUserHasNextPage(false);
        if (userData.length === 0) setUserPage(0);
      } else {
        setUserPage(0);
        //** Create an array with the previuous data an the new data */
        setUserData([...autocompleteData]);
      }
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };

  const getUsersSBDConnectInfiniteScroll = async (firstName: string, locationId: string, organizationId?: string) => {
    if (firstName === null) firstName = "";
    //** Provide next on api call */
    setUserHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = {
        offset: !firstName ? userPage * limit : 0,
        limit,
        location_id: locationId,
        organization_id: organizationId || "",
      };
      if (firstName) {
        query = {
          first_name: typeof firstName === "string" ? firstName : "",
          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await callEndpoint(getOnlyAdminsUsersAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: any) => ({
        label: `${item.first_name} ${item.last_name} - ${item.username}`,
        id: item.id,
        phone: item.phone,
        email: item.username,
        desigantion: item.designation,
        firstName: item?.first_name,
        lastName: item?.last_name,
      }));

      //** If the firstName has no value, all results are displayed from an infinite scroll */
      if (!firstName) {
        //** Create an array with the previuous data an the new data */
        if (userPage !== 0) {
          setUserData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setUserData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setUserPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setUserHasNextPage(false);
        if (userData.length === 0) setUserPage(0);
      } else {
        setUserPage(0);
        //** Create an array with the previuous data an the new data */
        setUserData([...autocompleteData]);
      }
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };

  return {
    getUsersInfiniteScroll,
    getUsersSBDConnectInfiniteScroll,
    userHasNextPage,
    userData,
    loading,
    userPage,
    setUserPage,
    setUserData,
  };
}
