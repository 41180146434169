import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Theme, useMediaQuery } from "@mui/material";

import { useGlobalContext } from "context/globalContext";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate, useParams } from "react-router-dom";
import { useBreadcrumbs, useGetReduxUser, useRoleGuard } from "hooks";

export default function ButtonEditLocation() {
  const { locEditMode, setLocEditMode } = useGlobalContext();
  const { setSearchToggleDash } = useListAndSearchContext();
  const navigate = useNavigate();
  const { addToTheEndOfHistory } = useBreadcrumbs();
  const { id } = useParams();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { userState } = useGetReduxUser();

  const canEditAllLocs = useRoleGuard([Roles.superAdmin, Roles.organizationEditor, Roles.organizationOwner, Roles.multiOrgOwner]);
  const onlyCanEditTheirLoc = useRoleGuard([Roles.locationOwner, Roles.locationEditor]);

  const onlyCanEditTheirOwnLoc = (onlyCanEditTheirLoc && userState.locationId === id) || canEditAllLocs;

  return (
    <RoleGuard
      authorizedRoles={[
        Roles.superAdmin,
        Roles.organizationEditor,
        Roles.organizationOwner,
        Roles.locationOwner,
        Roles.locationEditor,
        Roles.multiOrgOwner,
      ]}
    >
      <>
        {onlyCanEditTheirOwnLoc && (
          <>
            {!locEditMode ? (
              <IconButton
                onClick={() => {
                  if (underMd) setLocEditMode(true);
                  else {
                    navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.EDIT_NAV}/${id}`, { replace: true });
                    addToTheEndOfHistory({
                      label: "Edit",
                      url: `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.EDIT_NAV}/${id}`,
                      index: 2,
                    });
                  }
                }}
              >
                <EditIcon sx={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <Box>
                <IconButton
                  onClick={() => {
                    setSearchToggleDash((prev) => !prev);
                    setLocEditMode(false);
                  }}
                >
                  <HighlightOffIcon sx={{ fontSize: 24 }} />
                </IconButton>
              </Box>
            )}
          </>
        )}
      </>
    </RoleGuard>
  );
}
