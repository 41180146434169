/* eslint-disable no-unused-vars */
//** MUI imports */
import Box from "@mui/material/Box";
import { Fade, Grid, Skeleton, Stack } from "@mui/material";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Images */
import { EmptyListText } from "components";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import TableResponsiveRow from "pages/users/components/responsive/TableResponsiveRow/TableResponsiveRow";
import useGetUsersDashboard from "pages/users/pages/usersList/hooks/useUsersDashboard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { RoleGuard } from "guards";
import { useEmptyCardsLocationData } from "hooks";

//** Components imports */
import { useGlobalContext } from "context/globalContext";
import { Roles } from "models";
import { UsersSpeedDial } from "components/responsiveComponents";
import { useUsersListContext } from "../../../context";
import { AppListHeader } from "../../atoms";

export default function UsersTable({
  organizationId,
  embebed,
  locationId,
}: {
  organizationId?: string;
  embebed?: boolean;
  locationId?: string;
}) {
  const { dataUsersCards } = useEmptyCardsLocationData();
  //** Context */
  const { userEditMode } = useGlobalContext();
  const { usersArray } = useUsersListContext();
  const { usersHasFilter } = useListAndSearchContext();

  const { loading, hasNextPage, loadMoreUsers, onSearch, page } = useGetUsersDashboard(
    `${organizationId || ""}`,
    `${locationId || ""}`,
    embebed,
  );

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreUsers(),
    rootMargin: "0px 0px 400px 0px",
  });

  const hasData = usersHasFilter?.length === 0 ? usersArray.length !== 0 : true;

  return (
    <>
      <AppListHeader onSearch={onSearch} />
      <Box display="flex">
        <Box
          height={`calc(100vh - ${
            userEditMode ? `226px  - ${usersHasFilter ? "70px" : "0px"}` : `180px  - ${usersHasFilter ? "70px" : "0px"}`
          })`}
          overflow="scroll"
          width="100%"
        >
          {loading && usersArray.length === 0 ? (
            <Stack spacing={4} mt={8}>
              {Array.from({ length: 8 }).map((_, i) => (
                <Skeleton key={i} width="100%" height={50} variant="rounded" />
              ))}
            </Stack>
          ) : (
            <Box pt={2}>
              {usersArray?.map((item, i) => (
                <TableResponsiveRow data={item} index={i} alwaysEdit={embebed} invertBg mainPage embebed={!organizationId} />
              ))}
              {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

              <Box display="flex" justifyContent="center">
                {!hasData && (
                  <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                    {dataUsersCards.map((item, index) => (
                      <EmptyTableCards
                        key={index}
                        data={dataUsersCards[index]}
                        name="custom-radios-delivery"
                        gridProps={{ md: 12, sm: 12, xs: 12 }}
                      />
                    ))}
                  </Grid>
                )}
                {usersHasFilter?.length > 0 && usersArray.length === 0 && <EmptyListText />}
              </Box>
            </Box>
          )}
        </Box>
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationOwner, Roles.locationOwner, Roles.multiOrgOwner]}>
          <Fade in={userEditMode}>
            <Box position="relative">
              <UsersSpeedDial />
            </Box>
          </Fade>
        </RoleGuard>
      </Box>
    </>
  );
}
