import { Alert, Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { Chip, Input, InputApiCallInfiniteScroll } from "components";
import useUsersInfiniteScroll from "hooks/useUsersInfiniteScroll";
import { useLocationContext } from "pages/locations/context";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import { Roles } from "models";
import useAutorizeUserByRole from "utilities/hooks/useAuthorizeUserRole";
import useQuery from "utilities/getLocation";

export default function SbdConnectEdit({ editView }: { editView?: boolean }) {
  const { SBDConnectContact, setSBDConnectContact, organization } = useLocationContext();
  const { getUsersSBDConnectInfiniteScroll, userHasNextPage, userData, loading, userPage, setUserPage, setUserData } =
    useUsersInfiniteScroll();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { isUserAuthorized } = useAutorizeUserByRole();
  const authorizedRoles = isUserAuthorized([Roles.superAdmin, Roles.multiOrgOwner]);
  const query = useQuery();

  if (!authorizedRoles) {
    return null;
  }

  return (
    <Box mx={0} mt={4}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={4}>
          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 24, transform: "rotate(90deg)" }} />
          <Typography variant="h2" sx={{ fontWeight: 700 }}>
            {`${t("LOCATION-SBD-CONECT")}`}
          </Typography>
        </Box>
        <Box>
          <Chip label={SBDConnectContact?.designation?.toUpperCase() || "-----"} size="small" fontSize={underMd ? 12 : 14} />
        </Box>
      </Box>
      <Box mx={2} display="flex">
        <Box sx={{ width: "100%" }}>
          {organization?.id === "" && !query.get("orgId") && (
            <Alert severity="warning" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }}>
              {`${t("ALERT-ERROR-SELECT-ORG")}`}
            </Alert>
          )}
          <Box display="flex" my={4}>
            <Box minWidth="80px" width="20%" maxWidth={110} alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-NAME")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              <InputApiCallInfiniteScroll
                readOnly={!authorizedRoles && editView}
                disabled={(!authorizedRoles && editView) || (organization?.id === "" && !query.get("orgId"))}
                initialValue={SBDConnectContact || { label: "", id: "" }}
                name="loc-organization"
                setPropValue={(value: any) => {
                  setSBDConnectContact({
                    email: value?.email,
                    firstName: value?.first_name,
                    lastName: value?.last_name,
                    phone: value?.phone,
                    id: value?.id,
                    designation: value?.designation,
                    label: `${value?.first_name} ${value?.last_name}`,
                  });
                }}
                changeWithInputText
                apiCallFunction={
                  authorizedRoles || !editView
                    ? (firstName: string) => getUsersSBDConnectInfiniteScroll(firstName, "", query.get("orgId") || organization.id || "")
                    : () => {}
                }
                loading={loading}
                label=""
                hasNextPage={userHasNextPage}
                page={userPage}
                options={userData}
                setPage={setUserPage}
                setOptions={setUserData}
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="20%" maxWidth={110} alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-PHONE")}: `.toUpperCase() || "----"}
              </Typography>
            </Box>
            <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              <Input name="org-email" value={SBDConnectContact?.phone} onChange={() => {}} fullWidth readonly size="small" />
            </Box>
          </Box>
          <Box display="flex" mb={12}>
            <Box minWidth="80px" width="20%" maxWidth={110} alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-EMAIL")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              <Input name="org-email" value={SBDConnectContact?.email} onChange={() => {}} fullWidth readonly size="small" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
