/* eslint-disable consistent-return */
//**Hooks */
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useCreateLocation from "pages/locations/hooks/useCreateLocation";
//**Components imports */
import { useRequiredFields } from "pages/locations/hooks";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { ButtonStepsCreate, SbdConnectEdit } from "../../molecules";

import { BusinessStep } from "../BusinessStep";

export default function AditionalStep({ hiddenButtons, create }: { hiddenButtons?: boolean; create?: boolean }) {
  //**Context */

  //**Hooks */
  const { t } = useTranslation();
  const { handleCreateLocation, loading } = useCreateLocation();

  //** Validate required fields */

  const { requiredInputStatus, requiredInputStatus2, requiredInputStatus3, requiredInputStatus4 } = useRequiredFields();

  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 8.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("TITLE-ADDITIONAL-FIELDS")}
      </Typography>
      <BusinessStep create={create} />
      {create && (
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner]}>
          <Box mt={8}>
            <SbdConnectEdit />
          </Box>
        </RoleGuard>
      )}

      <Divider />

      {!hiddenButtons && (
        <ButtonStepsCreate
          requiredInputsEmpty={[requiredInputStatus, requiredInputStatus2, requiredInputStatus3, requiredInputStatus4]}
          disabled={loading}
          onClickLastStep={handleCreateLocation}
        />
      )}
    </Box>
  );
}
