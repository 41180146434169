import { useState } from "react";
import { Box, Switch } from "@mui/material";
import { Chip, ModalConfirm } from "components";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import { RoleGuard } from "guards";
import { Roles } from "models";

export default function HqSwitch() {
  const { setIsHQLocation } = useLocationContext();
  const { isHQLocation } = useLocationContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const rolesCanEditOrganization = [Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor];

  //**Modal */
  const handleConfirm = async () => {
    setOpenModal(false);
    setIsHQLocation(true);
  };
  const handleClose = () => setOpenModal(false);
  return (
    <RoleGuard authorizedRoles={rolesCanEditOrganization}>
      <Box display="flex" alignItems="center">
        <Chip label="HQ" size="small" color="success" sx={{ mr: 2 }} />
        <Switch
          sx={{ ml: -2 }}
          checked={isHQLocation}
          onChange={() => {
            setOpenModal(true);
          }}
          disabled={isHQLocation}
          color="success"
        />
      </Box>
      <ModalConfirm
        open={openModal}
        title={t("LOCATION-HQ-CHANGE-TITLE")}
        description={t("LOCATION-HQ-CHANGE-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </RoleGuard>
  );
}
