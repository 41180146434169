//** MUI Imports */
import { Card, CardContent } from "@mui/material";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Components */
import { AppListHeader } from "pages/users/pages/usersList/components/atoms";
import useGetUsersDashboard from "pages/users/pages/usersList/hooks/useUsersDashboard";
import { useParams } from "react-router-dom";
import UserDashboardItem from "../molecules/UserDashboardItem/UserDashboardItem";

export default function UserDashboardView() {
  const params = useParams();
  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;
  //** Hooks */
  const { loading, onSearch, hasNextPage, page, loadMoreUsers } = useGetUsersDashboard(params.id || `${organization?.id}`, "", true);

  return (
    <Card sx={{ mt: 8 }}>
      <CardContent>
        <AppListHeader isDashboard onSearch={onSearch} />
        <UserDashboardItem loading={loading} hasNextPage={hasNextPage} page={page} loadMoreUsers={loadMoreUsers} onSearch={onSearch} />
      </CardContent>
    </Card>
  );
}
