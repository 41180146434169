/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import { IPrimaryContactAPI, IPrimaryContactDOM } from "pages/locations";
import {
  IHQLocationAPI,
  IHQLocationDOM,
  IOrganizationListAPI,
  IOrganizationListDOM,
  IUsersDatAPI,
  IUsersDataDOM,
} from "pages/organizations/models";

export const organizationGetOneAdapter = (item: IOrganizationListAPI): IOrganizationListDOM => {
  return {
    id: item.id,
    name: item.name,
    isSuspended: item.is_suspended,
    logo: item.logo,
    notificationTime: `${item.notification_time}`,
    users: item.users,
    users_data: userDataOneAdapter(item.users_data),
    description: item.description,
    hqInformation: hqLocDataAdapter(item?.hq_information),
    primaryContact: item.primary_contact,
    primaryContactData: orgPrimaryContactAdapter(item.primary_contact_data),
  };
};
const userDataOneAdapter = (items: IUsersDatAPI[] | undefined): IUsersDataDOM[] => {
  const array: IUsersDataDOM[] = [];
  items?.map((item: IUsersDatAPI) => {
    return array.push({
      // eslint-disable-next-line no-underscore-dangle
      id: item._id,
      akamaiUuid: item.akamai_uuid,
      createdAt: item.created_at,
      deletedAt: item.deleted_at,
      roles: item.roles,
      updatedAt: item.updated_at,
      username: item.username,
    });
  });
  return array;
};

const hqLocDataAdapter = (item: IHQLocationAPI): IHQLocationDOM => ({
  address: item?.address ? item?.address : "",
  email: item?.email ? item?.email : "",
  phone: item?.phone ? item?.phone : "",
  website: item?.website ? item?.website : "",
  id: item?.loc_id ? item?.loc_id : "",
});

export const orgPrimaryContactAdapter = (item: IPrimaryContactAPI): IPrimaryContactDOM => {
  return {
    email: item?.email || "",
    firstName: item?.first_name || "",
    lastName: item?.last_name || "",
    phone: item?.phone || "",
    id: item?._id || "",
    designation: item?.designation || "",
    label: `${item?.first_name} ${item?.last_name} - ${item?.email}`,
  };
};
