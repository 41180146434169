import { Suspense, lazy, useEffect } from "react";
//**Context */
import { useLocationContext } from "pages/locations/context";
import { Box, Divider, Fade } from "@mui/material";
import { ButtonUpdateLocation } from "pages/locations/pages";
import { UsersList } from "pages/users";
import { useParams } from "react-router-dom";
import { UsersSpeedDialOnScroll } from "components/responsiveComponents";
import { Spinner } from "components";
import { LocOwnerEdit } from "../../molecules/LocOwnerEdit";
import { SbdConnectEdit } from "../../molecules";

const BasicInformationLocationStep = lazy(() => import("../../organism/BasicInformationLocation/BasicInformationLocationStep"));
const ContactStep = lazy(() => import("../../organism/ContactStep/ContactStep"));
const AddressStep = lazy(() => import("../../organism/AddressStep/AddressStep"));
const AditionalStep = lazy(() => import("../../organism/AditionalStep/AditionalStep"));

export default function StepsLocation({ action }: { action: "create" | "update" }) {
  const { setActiveStep, setTabValue } = useLocationContext();
  const { id } = useParams();

  //** Hooks */
  useEffect(() => {
    //** For creation */
    setActiveStep(0);
    //** For Update */
    setTabValue("1");
  }, []);
  if (action === "update") {
    return (
      <Suspense fallback={<Spinner />}>
        <Fade in>
          <div>
            <BasicInformationLocationStep />
            <Box mt={6}>
              <AddressStep />
            </Box>
            <ContactStep />
            <Box mt={8}>
              <AditionalStep hiddenButtons />
            </Box>

            <Divider />
            <Box mt={8}>
              <SbdConnectEdit editView />
            </Box>
            <Box mx={-4}>
              <LocOwnerEdit locationId={id || ""} />
            </Box>
            <UsersList locationId={id} embebed staticTop height="100%" />
            <UsersSpeedDialOnScroll needsScroll locationId={id} embebed />
            <Box mb={8}>
              <ButtonUpdateLocation />
            </Box>
          </div>
        </Fade>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Spinner />}>
      <Fade in>
        <div>
          <BasicInformationLocationStep create />
          <Box mt={6}>
            <AddressStep create />
          </Box>
          <ContactStep create />
          <Box mt={8}>
            <AditionalStep create />
          </Box>
        </div>
      </Fade>
    </Suspense>
  );
}
