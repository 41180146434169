/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
// import Icon from 'components'

// ** Type Import
import { Settings } from "context/settingsContext";
import MenuIcon from "@mui/icons-material/Menu";
// ** Components
// import Autocomplete from "layouts/components/Autocomplete";
import ModeToggler from "core/layouts/components/shared-components/ModeToggler";
import UserDropdown from "core/layouts/components/shared-components/UserDropdown";
// import LanguageDropdown from "core/layouts/components/shared-components/LanguageDropdown";
import { Theme, useTheme } from "@mui/material";

//** Redux */
import ApplicationsDropdown from "core/layouts/components/shared-components/ApplicationsDropdown";
import OrganizationsDropdown from "core/layouts/components/shared-components/OrganizationsDropdown";
import NotificationDropdown from "core/layouts/components/shared-components/NotificationDropdown";

interface Props {
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { settings, saveSettings, toggleNavVisibility } = props;

  const showNavMenu = true;
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: (theme: Theme) => theme.spacing(0, 6),
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      }}
    >
      <Box className="actions-left" sx={{ mr: 2, display: "flex", alignItems: "center" }}>
        {showNavMenu && !settings.navHidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75, "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon sx={{ fontSize: 25 }} />
          </IconButton>
        ) : null}

        {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      </Box>
      <Box className="actions-right" sx={{ display: "flex", alignItems: "center" }}>
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <OrganizationsDropdown settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown settings={settings} />
        <ApplicationsDropdown settings={settings} saveSettings={saveSettings} />
        {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;
