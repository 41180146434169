import { Box, Switch, Typography, useMediaQuery } from "@mui/material";
import { AutoComplete } from "components";
import { days, daysShort } from "constants/globalConstants";
import { useRoleGuard } from "hooks";
import { Roles } from "models";
import { useLocationContext } from "pages/locations/context";
import React from "react";
import { useTranslation } from "react-i18next";

import { hours } from "pages/locations/components";

export default function OpeningHoursEditRespInputs({
  index,
  openingHours,
  setOpeningHours,
  googleMapsInputs,
}: {
  index: number;
  openingHours: any;
  setOpeningHours: any;
  googleMapsInputs: any;
}) {
  /** Hooks */
  const { t } = useTranslation();
  const { view } = useLocationContext();
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
  ]);

  // eslint-disable-next-line no-shadow
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setOpeningHours((prev: any) => {
      const copyData = prev;
      copyData[index] = {
        ...copyData[index],
        is_enabled: event.target.checked,
      };
      return [...copyData];
    });
  };

  //** Getting open hour value */
  const googleOpenHour = googleMapsInputs?.openingHours?.[days[index]]?.opening;
  const googleCloseHour = googleMapsInputs?.openingHours?.[days[index]]?.closing;
  const googleIsEnabled = googleMapsInputs?.openingHours?.[days[index]]?.is_enabled;

  const haveOpenHours = googleOpenHour?.length > 0 && googleCloseHour?.length > 0;

  //** If open hour have value then compare between data and google maps, */
  //** When google maps doen't have data it's not necessary the comparison */
  const openingHourComparisson = googleOpenHour?.length > 0 ? googleOpenHour?.includes(openingHours[index]?.opening) : true;
  const closingHourComparisson = googleCloseHour?.length > 0 ? googleCloseHour?.includes(openingHours[index]?.closing) : true;
  const isEnabledHourComparisson = haveOpenHours ? googleIsEnabled === openingHours[index]?.is_enabled : true;

  const under400 = useMediaQuery("(max-width:530px)");

  const fonSizeForAutocomplete = under400 ? 10 : 13;
  const paddingAutocomplete = under400 ? "0 !important" : "auto !important";
  return (
    <Box textAlign="center" width="100%">
      <Box textAlign="left" mb={2}>
        <Switch
          checked={openingHours[index]?.is_enabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeSwitch(event, index)}
          size="small"
          color={!isEnabledHourComparisson ? "warning" : "primary"}
          sx={{ ml: -1.5 }}
        />
      </Box>

      <Typography variant="h2" textAlign="left" mb={2}>
        {daysShort[index]}
      </Typography>

      <Box width="100%" mt={4}>
        <AutoComplete
          sx={{
            width: "90%",
            "& .MuiAutocomplete-input": {
              paddingX: paddingAutocomplete,
              fontSize: fonSizeForAutocomplete,
            },
          }}
          disabled={!openingHours[index]?.is_enabled || !authUpdateOrganization}
          name="autocomplete-opening-hour"
          value={openingHours[index]?.opening}
          inputLabel=""
          inputSize="small"
          setValue={(value: any) => {
            const copyData = openingHours;
            copyData[index] = {
              ...copyData[index],
              opening: value,
            };
            setOpeningHours([...copyData]);
          }}
          renderOption={(props: any, option: any) => (
            <Box component="li" sx={{ paddingX: "8px !important", fontSize: fonSizeForAutocomplete }} {...props}>
              {option}
            </Box>
          )}
          onChangeInput={(e: any) => {
            //** If the user input value appear on hours list it will be auto selected */
            //** Autocomplete select item if i copy a acceptable value */
            if (hours.filter((item) => item === e.target.value).length > 0) {
              const copyData = openingHours;
              copyData[index] = {
                ...copyData[index],
                opening: e.target.value,
              };
              setOpeningHours([...copyData]);
            }
          }}
          //** If the user input doesn't exist on the hours list it must be deleted*/
          freeSolo
          disableClearable
          options={hours}
          warning={
            view === "create"
              ? !openingHourComparisson
              : !openingHourComparisson || googleMapsInputs?.openingHours?.[days[index]]?.opening === "warning"
          }
        />

        <Typography variant="h2" my={4}>
          {t("GLOBAL-TO")}
        </Typography>

        <AutoComplete
          sx={{
            width: "90%",
            "& .MuiAutocomplete-input": {
              paddingX: paddingAutocomplete,
              fontSize: fonSizeForAutocomplete,
            },
          }}
          disabled={!openingHours[index]?.is_enabled || !authUpdateOrganization}
          name="autocomplete-closing-hour"
          value={openingHours[index]?.closing}
          inputLabel=""
          inputSize="small"
          setValue={(value: any) => {
            const copyData = openingHours;
            copyData[index] = {
              ...copyData[index],
              closing: value,
            };
            setOpeningHours([...copyData]);
          }}
          renderOption={(props: any, option: any) => (
            <Box component="li" sx={{ paddingX: "8px !important", fontSize: fonSizeForAutocomplete }} {...props}>
              {option}
            </Box>
          )}
          onChangeInput={(e: any) => {
            //** If the user input value appear on hours list it will be auto selected */
            //** Autocomplete select item if i copy a acceptable value */
            if (hours.filter((item) => item === e.target.value)) {
              const copyData = openingHours;
              copyData[index] = {
                ...copyData[index],
                closing: e.target.value,
              };
              setOpeningHours([...copyData]);
            }
          }}
          //** If the user input doesn't exist on the hours list it must be deleted*/
          freeSolo
          disableClearable
          options={hours}
          warning={
            view === "create"
              ? !closingHourComparisson
              : !closingHourComparisson || googleMapsInputs?.openingHours?.[days[index]]?.closing === "warning"
          }
        />
      </Box>
    </Box>
  );
}
