//** MUI Imports */
import { UserContextProvider } from "pages/users";
import LocationsInformationView from "./components/organism/LocationsInformationView";

export default function LocationsInformation() {
  return (
    <UserContextProvider>
      <LocationsInformationView />
    </UserContextProvider>
  );
}
