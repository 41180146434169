/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
import { IconButton, Stack } from "@mui/material";
import { Button } from "components";
//**Hooks */
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchAndLoad, useToastMui } from "hooks";
//** Moles */
import { PrivateRoutes, Roles } from "models";
import { ICreateBodyUser, createUsersAPI } from "pages/users/pages/usersCreate/services";
import { RolesRadioButtons } from "pages/users/models";
//** CONTEXT */
import { useUserContext } from "pages/users/context";
//** Utils */
import { capitalize } from "utilities";
import { useGetRoles } from "hooks/apiCalls";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import config from "config/settings.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useGlobalContext } from "context/globalContext";

export default function CreateUsersButtons({
  hideCancel,
  successFunction,
  iconsButtons,
}: {
  hideCancel?: boolean;
  successFunction?: () => void;
  iconsButtons?: boolean;
}) {
  //**CONTEXT */
  const { firstName, lastName, email, phone, SBDEmail, radioRole, role, organization, title, location, setAlertRequiredFields } =
    useUserContext();
  const { isValidEmail } = useUserContext();
  const { callEndpoint, loading } = useFetchAndLoad();

  const { handleCreateToast } = useToastMui();
  const { getRoles } = useGetRoles();
  const { urlHistory } = useGlobalContext();
  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //**Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isBasicInfoEmpty = !firstName || !lastName || !email || !phone;

  const orgNotFilled = Array.isArray(organization) ? organization.length === 0 : organization.label === "";
  const needsOrganization = userState.roles === Roles.superAdmin && orgNotFilled;

  const hadleCreate = async () => {
    try {
      // If user role is Organizacion or location manager, the organization is the one that is selected
      // And if the user is an admin it is necessary to obtain the ids of the organizations
      let organizationIds: string[] = [];
      if (userState?.organization?.id) {
        organizationIds = [userState?.organization?.id];
      } else if (Array.isArray(organization)) {
        organizationIds = organization?.map((item) => (item.id ? item.id : ""));
      } else {
        organizationIds = organization.id ? [organization.id] : [];
      }

      const isAdminRole = RolesRadioButtons.superAdmin || radioRole === RolesRadioButtons.generalViewOnly;
      const isLocRole = RolesRadioButtons.locRelated;

      let body: ICreateBodyUser = {
        username: email,
        first_name: firstName,
        last_name: lastName,
        phone,
        description: "",
        sbd_contact: SBDEmail,
        roles: [`${role.label}`],
        organization_id: radioRole === isAdminRole ? [] : organizationIds,
        designation: title,
        location_id: radioRole === isLocRole ? `${location?.id}` : "",
      };

      if (radioRole === RolesRadioButtons.superAdmin || radioRole === RolesRadioButtons.generalViewOnly) {
        //** When selecting an admin or general view only type it is necessary to obtain the ids of the roles separately  */
        const rolesArray = await getRoles();

        if (radioRole === RolesRadioButtons.superAdmin)
          body = { ...body, roles: [rolesArray.filter((item) => item.label === Roles.superAdmin)[0].label] };
        if (radioRole === RolesRadioButtons.generalViewOnly)
          body = { ...body, roles: [rolesArray.filter((item) => item.label === Roles.generalViewOnly)[0].label] };
      }

      await callEndpoint(createUsersAPI({ body }), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-CREATE")} : ${capitalize(error?.response?.data?.message)}`, "error"),
      );

      handleCreateToast(t("ALERT-SUCCESS-CREATE"), "success");
      //When create organization needs to be redirected to the create one
      if (successFunction) successFunction();
      else if (radioRole === RolesRadioButtons.superAdmin || radioRole === RolesRadioButtons.generalViewOnly) {
        navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
      } else {
        // ? If the user come from org landing or loc list then go back to the respective page
        // eslint-disable-next-line no-lonely-if
        if (document.referrer.indexOf(`${config.HOST_URL}`) !== -1 || urlHistory.length > 0) {
          navigate(-1);
        } else {
          navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleRequiredFields = () => {
    const userReqFields =
      radioRole !== RolesRadioButtons.orgRelated
        ? isBasicInfoEmpty || !isValidEmail
        : isBasicInfoEmpty || !role?.id || needsOrganization || !isValidEmail;

    const userLocReqFields = radioRole === RolesRadioButtons.locRelated && (needsOrganization || location?.label === "");

    if (userReqFields || userLocReqFields) return setAlertRequiredFields(true);
    hadleCreate();
  };

  return (
    <Stack direction="row" gap={4} mt={8}>
      {iconsButtons ? (
        <>
          <IconButton onClick={handleRequiredFields} disabled={loading}>
            <CheckCircleIcon sx={{ fontSize: 24 }} />
          </IconButton>
          {!hideCancel && (
            <IconButton
              onClick={() => {
                navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
              }}
            >
              <HighlightOffIcon sx={{ fontSize: 24 }} />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <Button name="user-create" onClick={handleRequiredFields} label={t("BUTTON-CREATE")} size="large" disabled={loading} />
          {!hideCancel && (
            <Button
              name="user-cancel-create"
              onClick={() => {
                navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
              }}
              label={t("BUTTON-CANCEL")}
              size="large"
              color="secondary"
              variant="outlined"
            />
          )}
        </>
      )}
    </Stack>
  );
}
