/* eslint-disable no-param-reassign */
import { useState } from "react";
import { getAllOrganizationsAPI, getAllOrganizationsWithOutTokenAPI } from "pages";
import { Roles } from "models";
import useFetchAndLoad from "./useFetchAndLoad";
import useGetReduxUser from "./useGetReduxUser";
import useRoleGuard from "./useRoleGuard";

export default function useGetOrganizationsInfiniteScroll() {
  //**Organization infinite scroll states */
  const [orgPage, setOrgPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<any[]>([]);
  const { callEndpoint, loading } = useFetchAndLoad();
  const { userState } = useGetReduxUser();

  const dontNeedOrgFilter = useRoleGuard([Roles.superAdmin]);
  const getOrganizationsInfiniteScroll = async (name: string) => {
    if (name === null) name = "";
    //** Provide next on api call */
    setHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = {
        limit,
        offset: !name ? orgPage * limit : 0,
        sort: "asc(name)",
        user_id: dontNeedOrgFilter ? "" : userState?.id || "",
      };
      if (name) {
        query = {
          name: typeof name === "string" ? name : "",
          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await callEndpoint(getAllOrganizationsAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id }));

      //** If the name has no value, all results are displayed from an infinite scroll */
      if (!name) {
        //** Create an array with the previuous data an the new data */
        if (orgPage !== 0) {
          setOrgData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setOrgData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setOrgPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setHasNextPage(false);
        if (orgData.length === 0) setOrgPage(0);
      } else {
        setOrgPage(0);
        //** Create an array with the previuous data an the new data */
        setOrgData([...autocompleteData]);
      }
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };

  const getOrganizationsInfiniteScrollWithOutToken = async (name: string) => {
    if (name === null) name = "";
    //** Provide next on api call */

    setHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = { limit, offset: !name ? orgPage * limit : 0, sort: "asc(name)", user_id: userState?.id || "" };
      if (name.length !== 0) {
        query = {
          name: typeof name === "string" ? name : "",
          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await callEndpoint(getAllOrganizationsWithOutTokenAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id }));

      //** If the name has no value, all results are displayed from an infinite scroll */
      if (!name) {
        //** Create an array with the previuous data an the new data */
        if (orgPage !== 0) {
          setOrgData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setOrgData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setOrgPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setHasNextPage(false);
      } else {
        setOrgPage(0);
        //** Create an array with the previuous data an the new data */
        setOrgData([...autocompleteData]);
      }
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };
  return {
    getOrganizationsInfiniteScroll,
    getOrganizationsInfiniteScrollWithOutToken,
    hasNextPage,
    orgData,
    loading,
    orgPage,
    setOrgPage,
    setOrgData,
  };
}
