/* eslint-disable consistent-return */
import { Box, IconButton, Switch, Theme } from "@mui/material";
import { AutoComplete, Chip, StatusBadge } from "components";
import { useLocationContext } from "pages/locations/context";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { useGlobalContext } from "context/globalContext";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IRegion } from "models";
import { useFetchAndLoad } from "hooks";
import { HqSwitch } from "pages/locations/components/molecules/HqSwitch";
import { getAllRegionsAPI } from "pages/locations/services";

export default function LocHeaderAditionalInfoEdit() {
  const { t } = useTranslation();
  const fetchRegion = useFetchAndLoad();
  const { region, marketCountry, isHQLocation, purpose, isVisible, googleMapsInputs, alertRequiredFields } = useLocationContext();
  const { setIsVisible, setRegion, setMarketCountry } = useLocationContext();
  const { setMapLocDetailView, mapLocDetailView } = useGlobalContext();

  const hasCorporateOffice = purpose.some((item) => item.code === "CO");

  const marketGoogleCompare = !!marketCountry?.id && !!googleMapsInputs?.market && googleMapsInputs?.market !== marketCountry.label;
  const regionGoogleCompare = !!region?.id && !!googleMapsInputs?.region && googleMapsInputs?.region !== region.label;

  const [regionOptionsDropdown, setRegionOptionsDropdown] = useState<IRegion[]>([]);
  const [regionOptions, setRegionOptions] = useState<IRegion[]>([]);
  const [marketOptionsDropdown, setMarketOptionsDropdown] = useState<any>([]);
  // eslint-disable-next-line consistent-return
  const compareByLabel = (a: any, b: any) => {
    return a.label.localeCompare(b.label);
  };

  const getCountryData = async () => {
    //Get all the regions/markets data on all the countrys
    if (region?.id) {
      const marketsOnRegion = regionOptions.find((item: IRegion) => item.code === region?.id);
      const markets = marketsOnRegion?.markets?.map((market) => ({ label: market.code, id: market.code }));
      const sorterdMarker = markets?.sort(compareByLabel);
      setMarketOptionsDropdown(sorterdMarker ? [...sorterdMarker] : []);
    } else {
      const autocompleteData = regionOptions.map((item: IRegion) => ({ markets: item.markets }));

      //create a regions/markets array data on all the countrys information
      let array: { label: string; id: string }[] = [];
      autocompleteData.forEach((item) => {
        item.markets?.forEach((market: any) => {
          array = [...array, { label: market.code, id: market.code }];
        });
      });
      const sorterdMarker = array?.sort(compareByLabel);
      setMarketOptionsDropdown([...sorterdMarker]);
    }
  };

  const getRegionData = async () => {
    //Get country data sort by name
    try {
      const query: any = { sort: "asc(name)" };
      const response = await fetchRegion.callEndpoint(getAllRegionsAPI({ query }));
      const { data } = response;

      //Get all the regions/markets data on all the countrys
      const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ label: item.code, id: item.code }));

      setRegionOptionsDropdown(autocompleteData);
      if (regionOptions.length === 0) setRegionOptions(data?.result?.items);
      return autocompleteData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  return (
    <Box display="flex" ml={23} justifyContent="space-between" mt={mapLocDetailView ? 0 : 3}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center">
          <StatusBadge color="success" />
          <Switch
            sx={{ ml: -2 }}
            checked={isVisible}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsVisible(event.target.checked);
            }}
          />
        </Box>

        <HqSwitch />
        <Box>
          <AutoComplete
            sx={{ width: "95px", mr: 2 }}
            value={region}
            options={regionOptionsDropdown}
            setValue={(valueState: any) => {
              setRegion(valueState);
            }}
            name="loc-region"
            blurOnSelect
            warning={regionGoogleCompare}
            requiredFields={(region?.label?.length === 0 || region === null) && alertRequiredFields[1]}
            alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
            inputSize="small"
            endAdornment={
              regionGoogleCompare &&
              googleMapsInputs?.region && (
                <ReturnDataIcon onClick={() => setRegion({ label: googleMapsInputs?.region, id: googleMapsInputs?.region })} />
              )
            }
          />
        </Box>
        <Box>
          <AutoComplete
            sx={{ width: "90px", mr: 4, mb: 2 }}
            value={marketCountry}
            options={marketOptionsDropdown}
            setValue={(valueState: any) => {
              setMarketCountry(valueState);
            }}
            name="loc-market-country"
            blurOnSelect
            warning={marketGoogleCompare}
            onClick={getCountryData}
            requiredFields={(marketCountry?.label?.length === 0 || marketCountry === null) && alertRequiredFields[1]}
            alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
            inputSize="small"
            endAdornment={
              marketGoogleCompare &&
              googleMapsInputs?.market && (
                <ReturnDataIcon onClick={() => setMarketCountry({ label: googleMapsInputs?.market, id: googleMapsInputs?.market })} />
              )
            }
          />
        </Box>
        {hasCorporateOffice && isHQLocation && (
          <Chip
            label={purpose.find((item) => item.code === "CO")?.label?.toLocaleUpperCase()}
            size="small"
            color="secondary"
            sx={{ mr: 2 }}
          />
        )}
      </Box>
      {mapLocDetailView && (
        <IconButton
          onClick={() => {
            setMapLocDetailView(false);
          }}
          sx={{ color: (theme: Theme) => theme.palette.text.primary }}
        >
          <ViewListOutlinedIcon sx={{ fontSize: 26 }} />
        </IconButton>
      )}
    </Box>
  );
}
