/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import { ChangeEvent } from "react";
//**Components */
import { Box, Typography, useMediaQuery } from "@mui/material";
import { IconButtons, Input, InputEmail, TooltipApp } from "components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
//**Hoks */
import { useTranslation } from "react-i18next";
//**Context */
import { IObjectFieldsContact, useLocationContext } from "pages/locations/context";
import { FieldKey } from "pages/locations/components/molecules/KeyContacts/KeyContact";

export default function KeyContactRespFields({
  index,
  keyContact,
  handleChangeInputContact,
  authUpdateOrganization,
  handleDeleteContactField,
}: {
  index: number;
  keyContact: IObjectFieldsContact;
  handleChangeInputContact: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  authUpdateOrganization: boolean;
  handleDeleteContactField: (idx: number) => void;
}) {
  const { t } = useTranslation();
  const { keyContacts } = useLocationContext();

  return (
    <Box pr={6} py={6}>
      <Box display="flex">
        <Box width="100%">
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-NAME")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input
                  name={FieldKey.NAME}
                  id="kyContact1Name"
                  value={keyContact?.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
                  fullWidth
                  disabled={!authUpdateOrganization}
                  size="small"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-EMAIL")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <InputEmail
                  name={FieldKey.EMAIL}
                  email={keyContact?.email ?? ""}
                  onChangeEmail={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
                  isValidEmail={keyContact?.isValidEmail}
                  disabled={!authUpdateOrganization}
                  id="kycontact-email"
                  size="small"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-PHONE")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input
                  name={FieldKey.PHONE}
                  id="kyContact1Phone"
                  value={keyContact?.phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
                  fullWidth
                  disabled={!authUpdateOrganization}
                  size="small"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box display="flex" width="100%">
              <Box width="100px" alignContent="center">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-TITLE")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 100px)">
                <Input
                  name={FieldKey.TITLE}
                  id="kyContact1Title"
                  value={keyContact?.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
                  fullWidth
                  disabled={!authUpdateOrganization}
                  size="small"
                />
              </Box>
              {keyContacts?.length > 1 && (
                <TooltipApp title="Remove key contact" placement="left">
                  <Box>
                    <IconButtons
                      icon={<RemoveCircleOutlineIcon sx={{ fontSize: 20 }} />}
                      onClick={() => handleDeleteContactField(index)}
                      color="default"
                      disabled={!authUpdateOrganization}
                    />
                  </Box>
                </TooltipApp>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
