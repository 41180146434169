import { IconButton } from "@mui/material";
import { ButtonSizes } from "models";
import { ReactNode } from "react";

interface IIconButton {
  onClick: () => void;
  icon: ReactNode;
  color?: any;
  size?: ButtonSizes;
  id?: string;
  name?: string;
  disabled?: boolean;
}
export default function IconButtons(props: IIconButton) {
  const { onClick, icon, color = "primary", size, id, name, disabled } = props;
  return (
    <IconButton onClick={onClick} color={color} size={size} id={id} name={name} disabled={disabled}>
      {icon}
    </IconButton>
  );
}
