import { useEffect } from "react";
//** MUI */
import { Box, Grid, Skeleton, Stack, Theme, useMediaQuery } from "@mui/material";
//** Hooks */
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { EmptyListText } from "components";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useEmptyCardsLocationData } from "hooks";
//** Components */
import useGetLocationsDashboard from "pages/locations/pages/locationsList/hooks/useLocationsDashboard";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";

import { useGlobalContext } from "context/globalContext";
import { LocationDashboardView } from "pages/locations/components";
import { Roles } from "models";
import TableResponsiveRow from "../TableResponsiveRow/TableResponsiveRow";
//**Context */
import { useLocationListContext } from "../../../context";
import useGetSearchData from "../../../hooks/useGetSearchData";
import { AppListHeader } from "../../atoms";

export default function LocTableResponsive({
  hiddeEdit,
  embebed,
  infoView,
}: {
  hiddeEdit?: boolean;
  embebed?: boolean;
  infoView?: boolean;
}) {
  const params = useParams();

  const { id } = params;
  const { dataCards } = useEmptyCardsLocationData();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  //** Context */
  const { locationsMapListArray } = useLocationListContext();
  const { locationHasFilter } = useListAndSearchContext();
  const { mapView } = useGlobalContext();
  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization, roles } = userState;
  const orgIdForMultiOrgs = roles === Roles.multiOrgOwner ? "" : organization?.id;

  const { loading, hasNextPage, loadMoreLocations, onSearch, page } = useGetLocationsDashboard(id || orgIdForMultiOrgs, infoView, embebed);
  const { purposeOptions, getPurposes } = useGetSearchData();

  const hasData = locationHasFilter?.length === 0 ? locationsMapListArray.length !== 0 : true;

  useEffect(() => {
    getPurposes();
  }, []);

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreLocations(),
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      {!mapView ? (
        <Box>
          {underMd && <AppListHeader onSearch={onSearch} mainPage hiddeEdit={hiddeEdit} isDashboard={embebed} />}

          {loading && locationsMapListArray.length === 0 ? (
            <Stack spacing={4} mt={8}>
              {Array.from({ length: 4 }).map((_, i) => (
                <Skeleton key={i} width="100%" height={100} variant="rounded" />
              ))}
            </Stack>
          ) : (
            <Box maxHeight={embebed ? "calc(100vh - 440px)" : "calc(100vh - 240px)"} overflow="scroll">
              {locationsMapListArray?.map((item, i) => (
                <TableResponsiveRow data={item} index={i} purposeOptions={purposeOptions} embebed={embebed} />
              ))}
              {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

              <Box display="flex" justifyContent="center">
                {!hasData && (
                  <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                    {dataCards.map((item, index) => (
                      <EmptyTableCards
                        key={index}
                        data={dataCards[index]}
                        name="custom-radios-delivery"
                        gridProps={{ md: 12, sm: 12, xs: 12 }}
                      />
                    ))}
                  </Grid>
                )}
                {locationHasFilter?.length > 0 && locationsMapListArray.length === 0 && <EmptyListText />}
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <LocationDashboardView
          loading={loading}
          hasNextPage={hasNextPage}
          loadMoreLocations={loadMoreLocations}
          onSearch={onSearch}
          page={page}
        />
      )}
    </>
  );
}
