import { BadgeProps, Box, useTheme } from "@mui/material";
import "./style.css";

export default function StatusBadge({ color, size = "medium" }: { color: BadgeProps["color"]; size?: "small" | "medium" | "large" }) {
  const theme = useTheme();
  const colors = {
    primary: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
    secondary: theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light,
    success: theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light,
    error: theme.palette.mode === "dark" ? theme.palette.error.dark : theme.palette.error.light,
    warning: theme.palette.mode === "dark" ? theme.palette.warning.dark : theme.palette.warning.light,
    info: theme.palette.mode === "dark" ? theme.palette.info.dark : theme.palette.info.light,
    default: theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.grey[300],
  };
  const sizes = {
    small: { width: 14, height: 14 },
    medium: { width: 20, height: 20 },
    large: { width: 24, height: 24 },
  };

  return (
    <Box
      className="om-circle-badge"
      sx={{
        backgroundColor: colors[color || "default"],
        ...sizes[size],
      }}
    />
  );
}
