/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
//**Hooks */
import { useState } from "react";
import { useAsync, useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
//**Models */
import { IGetOneOrganization, IOrganizationListDOM } from "pages/organizations/models";
//**Services */
import { getAllPurposesAPI, getOneLocationAPI, ILocationDOM, locationGetOneAdapter } from "pages/locations";
//**Context */
import { useGlobalContext } from "context/globalContext";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { PrivateRoutes } from "models";
import { useOrganizationContext } from "pages/organizations/context";
import { useUserContext } from "pages/users";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

//**Adapter */
import { organizationGetOneAdapter } from "../adapters";
import { getOneOrganizationAPI } from "../services";
import { useOrganizationInfoContext } from "../context/OrganizationInfoContext";

export default function useOrganization(id: string) {
  //**Hooks */
  const navigate = useNavigate();
  const [mounted, setMounted] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const fetchPurposes = useFetchAndLoad();
  const fetchHqLocation = useFetchAndLoad();

  const { handleCreateToast } = useToastMui();
  const location = useLocation();
  const { t } = useTranslation();
  const { addToTheEndOfHistory, createCustomHistory } = useBreadcrumbs();
  const { breadcrumbsHistory } = useGlobalContext();

  //**Context */
  const { setActive, setFrecuency, setDataCopy, toggleGetData } = useOrganizationInfoContext();
  const { setName, setDescription, setLogo, setUrl, cleatBasicInformation, setLogoText, setInitalUrl, setCityOrganization } =
    useOrganizationContext();
  const userContext = useUserContext();
  const {
    setEmailOrganization,
    setPhoneOrganization,
    setLocHqId,
    setPrimaryContact,
    setHqPrimaryContact,
    setAddress2Organization,
    setAddress1Organization,
    setRegionOrganization,
    setCountryOrganization,
    setStateOrganization,
    setPostalCodeOrganization,
    setLatOrganization,
    setLongOrganization,
  } = useOrganizationContext();
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;
  //** Location */
  const {
    setApplicableBrand,
    setCategory,
    setPurpose,
    setIsPremium,
    setDisplayOrder,
    setIsOnlineOnly,
    setLocationHq,
    setMapId,
    setOpeningHoursOrg,
  } = useOrganizationContext();

  //** Data From APi */
  const getOneOrganization: any = async (id: string) => {
    cleatBasicInformation();
    //Calling get all API

    const response = await callEndpoint(getOneOrganizationAPI(id), () => {
      handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error");
      navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
    });
    const responsePurposes = await fetchPurposes.callEndpoint(getAllPurposesAPI(id), () => {
      handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error");
      navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
    });

    const { data } = response;
    const responseLocation = await fetchHqLocation.callEndpoint(getOneLocationAPI(data.result.hq_information.loc_id), () => {
      handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error");
      navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
    });

    return {
      data: {
        organization: response,
        purpose: responsePurposes,
        location: responseLocation,
      },
    };
  };

  const adaptInfo = (data: IGetOneOrganization) => {
    //Save the adapted data and the last page and length for the pagination
    const adaptedData: IOrganizationListDOM = organizationGetOneAdapter(data?.organization.data.result);
    const adaptedDataLoc: ILocationDOM = locationGetOneAdapter(data?.location?.data?.result);
    const purposesDataResponse = data?.purpose?.data?.result?.items;

    //**HQ Location */
    setLocationHq({ label: adaptedDataLoc?.name, id: adaptedDataLoc?.id });
    setMapId(adaptedDataLoc.address?.mapsId);
    const selectedBrands = adaptedDataLoc?.brandData?.map((item) => ({ label: item.name, id: item.id, code: item.code }));
    setApplicableBrand(selectedBrands);
    setCategory(adaptedDataLoc.categoriesProducts);
    //**Aditional Steps */
    const purposesData = purposesDataResponse.map((item) => ({ label: item.name, id: item.code, code: item.code }));
    const purposes = purposesData.filter((item) => adaptedDataLoc.purposeCodes.includes(item.code));
    setPurpose(purposes);
    setIsPremium(adaptedDataLoc?.isPremium);
    setDisplayOrder(adaptedDataLoc?.displayOrder);
    setIsOnlineOnly(adaptedDataLoc?.isOnlineOnly);

    //** Organization */
    //Breadcrumb;
    if (organization?.id) {
      createCustomHistory([
        {
          url: `/${PrivateRoutes.ORGANIZATIONS_DASHBOARD}`,
          label: adaptedData.name,
          index: 0,
        },
        {
          label: t("ORGANIZATION-DETAIL"),
          url: "",
          index: breadcrumbsHistory.length,
        },
      ]);
    } else if (location.pathname.includes(`${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`)) {
      addToTheEndOfHistory({
        label: adaptedData.name,
        url: `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${adaptedData.id}`,
        index: breadcrumbsHistory.length,
      });
    }
    //Data
    setActive(adaptedData.isSuspended);
    setFrecuency({
      label: `${adaptedData.notificationTime}` === "7" ? t("FRECUENCY-1") : t("FRECUENCY-2"),
      value: `${adaptedData.notificationTime}`,
    });
    setName(adaptedData.name);
    setDescription(adaptedData.description);
    setUrl(
      adaptedData?.hqInformation.website?.includes("https://")
        ? adaptedData.hqInformation.website.slice(8)
        : adaptedData?.hqInformation.website?.includes("http://")
        ? adaptedData.hqInformation.website.slice(7)
        : adaptedData.hqInformation.website,
    );
    setInitalUrl(
      adaptedData?.hqInformation.website?.includes("https://")
        ? "https://"
        : adaptedData?.hqInformation.website?.includes("http://")
        ? "http://"
        : "https://",
    );

    //** Address */
    setAddress1Organization(adaptedDataLoc.address.address1);
    setAddress2Organization(adaptedDataLoc.address.address2);
    setRegionOrganization({ label: adaptedDataLoc.address.region, id: adaptedDataLoc.address.region });
    setCountryOrganization({ label: adaptedDataLoc.marketOrCountry, id: adaptedDataLoc.marketOrCountry });
    setStateOrganization(adaptedDataLoc.address.state);
    setPostalCodeOrganization(adaptedDataLoc.address.postalCode);
    setCityOrganization(adaptedDataLoc.address.city);
    setLatOrganization(`${adaptedDataLoc.address.coordinates[0]}`);
    setLongOrganization(`${adaptedDataLoc.address.coordinates[1]}`);

    //** Backend needs to compare with gm*/
    const days: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const openingHoursData = days.map((day, index) => ({
      label: day,
      id: index,
      opening: adaptedDataLoc.openHours[`${day.toLocaleLowerCase()}`]?.opening,
      closing: adaptedDataLoc.openHours[`${day.toLocaleLowerCase()}`]?.closing,
      is_enabled: adaptedDataLoc.openHours[`${day.toLocaleLowerCase()}`]?.is_enabled,
    }));
    setOpeningHoursOrg([...openingHoursData]);

    //**Contact  */
    setEmailOrganization(adaptedDataLoc.contact.email);
    setPhoneOrganization(adaptedDataLoc.contact.phone);
    setLocHqId(adaptedDataLoc.id);
    setPrimaryContact(adaptedData.primaryContactData);
    setHqPrimaryContact({
      label: `${adaptedData.primaryContactData?.firstName}`,
      id: adaptedData.primaryContactData?.id,
    });

    //** Users modal  */
    userContext.setOrganization({ label: adaptedData.name, id: adaptedData.id });
    //Logo need to be File type
    //Convert url to blob to file
    if (adaptedData?.logo) {
      setLogoText(adaptedData?.logo as string);
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${adaptedData.logo}.png`);
      xhr.responseType = "arraybuffer";

      xhr.onload = function () {
        const blob = new Blob([xhr.response]);
        const file = new File([blob], "logo.png", { type: "image/png" });
        setLogo([file]);
        setDataCopy((data: any) => {
          return {
            ...data,
            logo: [file],
          };
        });
      };
      xhr.send();
      setMounted(true);
    } else {
      setLogo([]);
      setMounted(true);
    }
  };

  useAsync(
    () => getOneOrganization(id),
    adaptInfo,
    () => {},
    [organization, toggleGetData],
  );

  return { loading, getOneOrganization, loadingHq: fetchHqLocation.loading, loadingPurposes: fetchPurposes.loading, mounted };
}
