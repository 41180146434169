/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import { INotification, INotificationDOM, IRowError, IRowErrorDOM } from "models";
import {
  ILocationsListAPI,
  ILocationsListDOM,
  IOrganizationDataAPI,
  IOrganizationDataDOM,
  IPrimaryContactAPI,
  IPrimaryContactDOM,
  IWarningAPI,
  IWarningDOM,
} from "pages/locations/models";

export const locationGetallAdapter = (items: ILocationsListAPI[]): ILocationsListDOM[] => {
  const array: ILocationsListDOM[] = [];
  items?.map((item: ILocationsListAPI) => {
    return array.push({
      id: item.id || item?.address?.maps_id,
      address: {
        address1: item.address.address1,
        address2: item.address.address2,
        city: item.address.city,
        mapsId: item?.address?.maps_id,
        coordinates: item.address.coordinates,
        postalCode: item.address.postal_code,
        state: item.address.state,
        region: item.address.region,
      },
      brands: item.brands,
      categoriesProducts: item.categories_products,
      contact: {
        email: item.contact.email,
        phone: item.contact.phone,
        fax: item.contact.fax,
        website: item.contact.website,
        keyContacts: item.contact.key_contacts,
        managingDirectorOrLeader: item.contact.managing_director_or_leader,
      },
      createdAt: item.created_at,
      deleted_At: item.deleted_at,
      isVisible: item.is_visible,
      logo: item.logo,
      marketOrCountry: item.market_or_country,
      name: item.name,
      openHours: {
        monday: {
          closing: item.open_hours.monday.closing,
          opening: item.open_hours.monday.opening,
          is_enabled: item.open_hours.monday.is_enabled,
        },
        tuesday: {
          closing: item.open_hours.tuesday.closing,
          opening: item.open_hours.tuesday.opening,
          is_enabled: item.open_hours.tuesday.is_enabled,
        },
        wednesday: {
          closing: item.open_hours.wednesday.closing,
          opening: item.open_hours.wednesday.opening,
          is_enabled: item.open_hours.wednesday.is_enabled,
        },
        thursday: {
          closing: item.open_hours.thursday.closing,
          opening: item.open_hours.thursday.opening,
          is_enabled: item.open_hours.thursday.is_enabled,
        },
        friday: {
          closing: item.open_hours.friday.closing,
          opening: item.open_hours.friday.opening,
          is_enabled: item.open_hours.friday.is_enabled,
        },
        saturday: {
          closing: item.open_hours.saturday.closing,
          opening: item.open_hours.saturday.opening,
          is_enabled: item.open_hours.saturday.is_enabled,
        },
        sunday: {
          closing: item.open_hours.sunday.closing,
          opening: item.open_hours.sunday.opening,
          is_enabled: item.open_hours.sunday.is_enabled,
        },
      },
      organizationData: organizationDataAdapter(item.organization_data),
      organizationId: item.organization_id,
      purposeCodes: item.purpose_codes,
      region: item.region,
      updatedAt: item.updated_at,
      hasRequiredFields: item.has_required_fields ?? true,
      warnings: warningsDataAdapter(item.warnings || []),
      amountOfEmployees: item.amount_of_employees,
      business: item.business,
      description: item.description,
      displayOrder: item.display_order,
      facilitySquareFootage: item.facility_square_footage,
      isFactory: item.factory,
      isPremium: item.premium,
      isOnlineOnly: item.is_online_only,
      sustainability: item.sustainability,
      yearFounded: item.year_founded,
      mainImage: item.main_image,
      images: item.images,
      brandManufactured: item.brand_manufactured,
      primaryContact: item.primary_contact,
      primaryContactData: primaryContactAdapter(item.primary_contact_data),
      sbdConnectId: item.sbd_connect_id,
      sbdConnectData: primaryContactAdapter(item.sbd_connect_data),
      isHeadQuarter: item.is_head_quarter,
    });
  });
  return array;
};

const organizationDataAdapter = (item: IOrganizationDataAPI): IOrganizationDataDOM => {
  return {
    id: item?.id,
    name: item?.name,
    logo: item?.logo,
  };
};

const warningsDataAdapter = (items: IWarningAPI[]): IWarningDOM[] => {
  return items?.map((item: IWarningAPI) => ({
    warning: item.warning,
    fieldName: item.field_name,
  }));
};

export const notificationsAdapter = (items: INotification): Partial<INotificationDOM> => {
  return {
    summary: {
      executionTime: items.summary.execution_time,
      success: items.summary.success,
      warning: items.summary.warning,
      failed: items.summary.failed,
      total: items.summary.total,
    },
    rowsErrors: rowsErrorsAdapter(items.rows_errors),
    templateErrors: templateErrorsAdapter(items.template_errors),
  };
};

const primaryContactAdapter = (item: IPrimaryContactAPI): IPrimaryContactDOM => {
  return {
    email: item?.email || "",
    firstName: item?.first_name || "",
    lastName: item?.last_name || "",
    phone: item?.phone || "",
    id: item?._id || "",
    designation: item?.designation || "",
    label: `${item?.first_name} ${item?.last_name}`,
  };
};

const rowsErrorsAdapter = (items: IRowError[]): IRowErrorDOM[] => {
  const errorsDetails = items?.map((item: IRowError) => ({
    rowNumber: item.row_number,
    errors: item.errors,
  }));
  return errorsDetails.sort((a, b) => a.rowNumber - b.rowNumber);
};

const templateErrorsAdapter = (items: string[]): IRowErrorDOM[] => {
  const errorsDetails = items?.map((item: string) => ({
    rowNumber: " - ",
    errors: [item],
  }));
  return errorsDetails;
};
