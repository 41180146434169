import { ChangeEvent } from "react";
import { Box, Collapse, List, Typography } from "@mui/material";
import { useRoleGuard } from "hooks";
import { EMAIL_REGEX, Roles } from "models";
import { FieldKey } from "pages/locations/components/molecules/KeyContacts/KeyContact";
import { useLocationContext } from "pages/locations/context";
import { ButtonAddKeyContacts } from "pages/locations/components";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import KeyContactRespFields from "./KeyContactRespFields";

export default function KeyConctactRespEdit() {
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
  ]);
  const { keyContacts } = useLocationContext();
  const { setKeyContacts } = useLocationContext();
  const { t } = useTranslation();

  //** Key contacts management */
  const handleChangeInputContact = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const copyData = keyContacts;
    const isEmailChange = e.target.name.slice(6) === FieldKey.EMAIL;
    copyData[index] = {
      ...copyData[index],
      [e.target.name.slice(6)]: e.target.value,
      isValidEmail: isEmailChange ? EMAIL_REGEX.test(e.target.value) || e.target.value === "" : copyData[index]?.isValidEmail,
    };

    setKeyContacts([...copyData]);
  };

  const handleDeleteContactField = (index: number) => {
    // eslint-disable-next-line no-shadow
    const copyContacts = [...keyContacts];
    copyContacts.splice(index, 1);
    setKeyContacts(copyContacts);
  };
  return (
    <List sx={{ backgroundColor: "background.paper", px: 4, pt: 4 }}>
      <Box display="flex" alignItems="center" gap={4}>
        <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 24, transform: "rotate(90deg)" }} />
        <Typography variant="h2" sx={{ fontWeight: 700 }}>
          {`${t("LOCATION-KEY-CONTACTS-LANDING-1")}`.toUpperCase()}
        </Typography>
      </Box>
      <Box display="flex">
        <Box width="100%">
          <TransitionGroup>
            {keyContacts?.map((keyContact, index) => (
              <Collapse>
                <KeyContactRespFields
                  index={index}
                  keyContact={keyContact}
                  handleChangeInputContact={handleChangeInputContact}
                  authUpdateOrganization={authUpdateOrganization}
                  handleDeleteContactField={handleDeleteContactField}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </Box>
        <Box display="flex" alignItems="flex-end" pb={10}>
          <ButtonAddKeyContacts />
        </Box>
      </Box>
    </List>
  );
}
