// ** MUI Imports
import { useTheme } from "@mui/material/styles";

// ** Util Import
import { hexToRGBA } from "utilities";

export type UseBgColorType = {
  [key: string]: {
    color: string;
    backgroundColor: string;
  };
};

const UseBgColor = () => {
  // ** Hooks
  const theme = useTheme();

  return {
    primaryFilled: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.customColors.primaryBackground,
    },
    primaryLight: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.customColors.primaryBackground,
    },
    secondaryFilled: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.customColors.secondaryBackground,
    },
    secondaryLight: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.customColors.secondaryBackground,
    },
    successFilled: {
      color: theme.palette.success.main,
      backgroundColor: theme.palette.customColors.successBackground,
    },
    successLight: {
      color: theme.palette.success.main,
      backgroundColor: theme.palette.customColors.successBackground,
    },
    errorFilled: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.customColors.errorBackground,
    },
    errorLight: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.customColors.errorBackground,
    },
    warningFilled: {
      color: theme.palette.warning.main,
      backgroundColor: theme.palette.customColors.warningBackground,
    },
    warningLight: {
      color: theme.palette.warning.main,
      backgroundColor: theme.palette.customColors.warningBackground,
    },
    infoFilled: {
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
    },
    infoLight: {
      color: theme.palette.info.main,
      backgroundColor: hexToRGBA(theme.palette.info.main, 0.12),
    },
  };
};

export default UseBgColor;
