//**Mui imports */
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
//**Hooks */
import { useTranslation } from "react-i18next";
//**COMPONENTS IMPORTS */
import { AutoComplete, GoogleMapsMUI, Input } from "components";
import { useOrganizationContext } from "pages/organizations/context";
import { useFetchAndLoad, useInputValueContext, useRoleGuard } from "hooks";
import { getAllRegionsAPI } from "pages/locations";
import { IRegion, Roles } from "models";
import { useEffect, useState } from "react";
import useSetGoogleMapDataOrg from "pages/organizations/hooks/useSetGoogleMapDataOrg";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";

export default function OrganizationAddress({ createView }: { createView?: boolean }) {
  //** Context */
  const {
    Address2Organization,
    countryOrganization,
    cityOrganization,
    postalCodeOrganization,
    mapId,
    regionOrganization,
    googleMapsOrgInputs,
    stateOrganization,
    alertRequiredFields,
  } = useOrganizationContext();
  const {
    setAddress2Organization,
    setCityOrganization,
    setCountryOrganization,
    setPostalCodeOrganization,
    setRegionOrganization,
    setStateOrganization,
    setOrgHasChanges,
  } = useOrganizationContext();
  //** Hooks */
  const { t } = useTranslation();

  const matches = useMediaQuery("(max-width:600px)");
  const address2Input = useInputValueContext("", setAddress2Organization);
  const cityInput = useInputValueContext("", setCityOrganization);
  const stateInput = useInputValueContext("", setStateOrganization);
  const postalCodeInput = useInputValueContext("", setPostalCodeOrganization);
  const isRequiedFieldEmpty = createView ? alertRequiredFields : true;

  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.organizationOwner, Roles.organizationEditor]);

  const fetchRegion = useFetchAndLoad();

  const [regionOptions, setRegionOptions] = useState<IRegion[]>([]);
  const [regionOptionsDropdown, setRegionOptionsDropdown] = useState<IRegion[]>([]);
  const [marketOptionsDropdown, setMarketOptionsDropdown] = useState<any>([]);

  const changeControllerFn = () => setOrgHasChanges(true);

  const getCountryData = async () => {
    //Get all the regions/markets data on all the countrys
    if (regionOrganization?.id) {
      const marketsOnRegion = regionOptions.find((item: IRegion) => item.code === regionOrganization?.id);
      const markets = marketsOnRegion?.markets?.map((market) => ({ label: market.code, id: market.code }));
      setMarketOptionsDropdown(markets ? [...markets] : []);
    } else {
      const autocompleteData = regionOptions.map((item: IRegion) => ({ markets: item.markets }));

      //create a regions/markets array data on all the countrys information
      let array: { label: string; id: string }[] = [];
      autocompleteData.forEach((item) => {
        item.markets?.forEach((market: any) => {
          array = [...array, { label: market.code, id: market.code }];
        });
      });
      setMarketOptionsDropdown([...array]);
    }
  };

  // eslint-disable-next-line consistent-return
  const getRegionData = async () => {
    try {
      //Get country data sort by name
      const query: any = { sort: "asc(name)" };
      const response = await fetchRegion.callEndpoint(getAllRegionsAPI({ query }));
      const { data } = response;

      //Get all the regions/markets data on all the countrys
      const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ label: item.code, id: item.code }));

      setRegionOptionsDropdown(autocompleteData);
      if (regionOptions.length === 0) setRegionOptions(data?.result?.items);

      return autocompleteData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  const { getGMDataForInfoView, onPlaceChanged } = useSetGoogleMapDataOrg();

  const marketGoogleCompare =
    !!countryOrganization?.id && !!googleMapsOrgInputs?.market && googleMapsOrgInputs?.market !== countryOrganization.label;
  const regionGoogleCompare =
    !!regionOrganization?.id && !!googleMapsOrgInputs?.region && googleMapsOrgInputs?.region !== regionOrganization.label;
  const cityGoogleCompare = !!cityOrganization && !!googleMapsOrgInputs?.city && googleMapsOrgInputs?.city !== cityOrganization;
  const postalCodeGoogleCompare =
    !!postalCodeOrganization && !!googleMapsOrgInputs?.postalCode && googleMapsOrgInputs?.postalCode !== postalCodeOrganization;
  const stateGoogleCompare = !!stateOrganization && !!googleMapsOrgInputs?.state && googleMapsOrgInputs?.state !== stateOrganization;

  const rolesCanEditOrganization = [Roles.superAdmin, Roles.organizationOwner, Roles.organizationEditor];

  return (
    <Box mt={12}>
      <Typography variant="h3" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }} id="orgContactInfo">
        {t("TITLE-ADDRESS")}
      </Typography>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Box sx={{ width: "100%" }}>
          {mapId || createView ? (
            <GoogleMapsMUI
              onPlaceChanged={onPlaceChanged}
              initialValue={mapId}
              label={`${t("INPUT-ADDRESS-1")}*`}
              disabled={!authUpdateOrganization}
              getGMDataForInfoView={getGMDataForInfoView}
              mapId={mapId}
              requiredFields={!mapId && alertRequiredFields}
              alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </Box>
        <Input
          name="org-address2"
          value={Address2Organization}
          onChange={address2Input.onChange}
          label={`${t("INPUT-ADDRESS-2")}`}
          fullWidth
          disabled={!authUpdateOrganization}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <AutoComplete
          disabled={!authUpdateOrganization}
          fullWidth
          value={regionOrganization}
          options={regionOptionsDropdown}
          setValue={(valueState: any) => {
            setRegionOrganization(valueState);
            changeControllerFn();
          }}
          name="org-region"
          blurOnSelect
          inputLabel={`${t("INPUT-REGION")}*`}
          warning={regionGoogleCompare}
          requiredFields={(regionOrganization?.label?.length === 0 || regionOrganization === null) && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            regionGoogleCompare &&
            googleMapsOrgInputs?.region && (
              <ReturnDataIcon
                authorizedRoles={rolesCanEditOrganization}
                onClick={() => setRegionOrganization({ label: googleMapsOrgInputs?.region, id: googleMapsOrgInputs?.region })}
              />
            )
          }
        />
        <AutoComplete
          disabled={!authUpdateOrganization}
          fullWidth
          value={countryOrganization}
          options={marketOptionsDropdown}
          setValue={(valueState: any) => {
            changeControllerFn();
            setCountryOrganization(valueState);
          }}
          name="org-market-country"
          blurOnSelect
          inputLabel={`${t("INPUT-COUNTRY")}*`}
          onClick={getCountryData}
          warning={marketGoogleCompare}
          requiredFields={(countryOrganization?.label?.length === 0 || countryOrganization === null) && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            marketGoogleCompare &&
            googleMapsOrgInputs?.market && (
              <ReturnDataIcon
                authorizedRoles={rolesCanEditOrganization}
                onClick={() => setCountryOrganization({ label: googleMapsOrgInputs?.market, id: googleMapsOrgInputs?.market })}
              />
            )
          }
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <Input
          name="loc-state"
          value={stateOrganization}
          onChange={stateInput.onChange}
          label={`${t("INPUT-STATE")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={stateGoogleCompare ? <WarningTooltip /> : null}
          color={stateGoogleCompare && "warning"}
          focused={stateGoogleCompare}
          error={stateOrganization?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            stateGoogleCompare &&
            googleMapsOrgInputs?.state && <ReturnDataIcon onClick={() => setStateOrganization(googleMapsOrgInputs.state)} />
          }
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="loc-city"
          value={cityOrganization}
          onChange={cityInput.onChange}
          label={`${t("INPUT-CITY")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={cityGoogleCompare ? <WarningTooltip /> : null}
          color={cityGoogleCompare && "warning"}
          focused={cityGoogleCompare}
          error={cityOrganization?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            cityGoogleCompare &&
            googleMapsOrgInputs?.city && (
              <ReturnDataIcon authorizedRoles={rolesCanEditOrganization} onClick={() => setCityOrganization(googleMapsOrgInputs.city)} />
            )
          }
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <Input
          name="loc-postal-code"
          value={postalCodeOrganization}
          onChange={postalCodeInput.onChange}
          label={`${t("INPUT-POSTAL-CODE")}*`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={postalCodeGoogleCompare ? <WarningTooltip /> : null}
          color={postalCodeGoogleCompare && "warning"}
          focused={postalCodeGoogleCompare}
          error={postalCodeOrganization?.length === 0 && isRequiedFieldEmpty}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
          endAdornment={
            postalCodeGoogleCompare &&
            googleMapsOrgInputs?.postalCode && (
              <ReturnDataIcon
                authorizedRoles={rolesCanEditOrganization}
                onClick={() => setPostalCodeOrganization(googleMapsOrgInputs.postalCode)}
              />
            )
          }
          changeControllerFn={changeControllerFn}
        />
        <Box sx={{ width: "100%" }} />
      </Stack>
    </Box>
  );
}
