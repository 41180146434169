/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import { Box, Theme, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import { PrivateRoutes, Roles } from "models";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//**Icon */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PlaceIcon from "@mui/icons-material/Place";
//**Hooks */
import { useNavigate } from "react-router-dom";
import { useLocationContext } from "pages/locations/context";
import { ButtonEditLocation } from "pages/locations/components";
import { Input } from "components";
import { useGlobalContext } from "context/globalContext";
import { hexToRGBA } from "utilities";
import { useTranslation } from "react-i18next";
import { useInputValueContext, useSettings } from "hooks";
import { LocHeaderAditionalInfoEdit } from "../LocHeaderAditionalInfoEdit";
import { ButtonUpdateLocation } from "../../molecules";

export default function LocHeaderEdit() {
  const { t } = useTranslation();
  //** Context */

  const { name, setName, alertRequiredFields, view, setUpdateToggle } = useLocationContext();
  const { locEditMode, setLocEditMode, setUserEditMode, setMapView } = useGlobalContext();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //** Hooks */
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const nameInput = useInputValueContext("", setName);

  const adminUsers = [Roles.superAdmin, Roles.generalViewOnly];

  //**Required inputs management */
  const isRequiedFieldEmpty = view === "create" ? alertRequiredFields[0] : true;
  //** Change top color */
  const { settings } = useSettings();
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const theme = useTheme();
  const appBarFixedStyles = () => {
    return {
      backgroundColor: hexToRGBA(theme.palette.background.paper, 1),
    };
  };
  return (
    <Box
      position={underMd ? "sticky" : "static"}
      px={4}
      pb={4}
      pt={4}
      sx={{
        backgroundColor: underMd ? "background.default" : "background.paper",
        top: 60,
        zIndex: 10,
        ...(settings.appBar === "fixed" && scrollTrigger && { ...appBarFixedStyles() }),
      }}
    >
      <Box display="flex" alignItems="center" justifyContent={underMd ? "space-between" : ""}>
        <Box
          mr={4}
          display="flex"
          alignItems="center"
          onClick={() => {
            if (locEditMode) {
              setUserEditMode(false);
              setLocEditMode(false);
              setMapView(false);
              setUpdateToggle((prev) => !prev);
              return;
            }
            adminUsers.find((item) => item === userState?.roles)
              ? navigate(`/${PrivateRoutes.LOCATIONS}`)
              : navigate(`/${PrivateRoutes.ORGANIZATIONS}`);
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 16, stroke: (theme: Theme) => `${theme.palette.text.primary}`, strokeWidth: 2 }} />
          <PlaceIcon sx={{ fontSize: 24, mr: 2 }} />
        </Box>
        <Box width="100%">
          <Input
            name="org-email"
            value={name}
            onChange={nameInput.onChange}
            fullWidth
            error={name?.length === 0 && isRequiedFieldEmpty}
            helperText={`${t("ALL-REQUIRED.INPUT")}`}
            size="small"
          />
        </Box>
        {locEditMode ? <ButtonUpdateLocation /> : <ButtonEditLocation />}
      </Box>
      <LocHeaderAditionalInfoEdit />
    </Box>
  );
}
