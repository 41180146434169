/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import { ChangeEvent } from "react";
//**Components */
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { IconButtons, Input, InputEmail, TooltipApp } from "components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
//**Hoks */
import { useTranslation } from "react-i18next";
//**Context */
import { IObjectFieldsContact, useLocationContext } from "pages/locations/context";
import { FieldKey } from "../KeyContacts/KeyContact";

export default function KeyContactFields({
  index,
  keyContact,
  handleChangeInputContact,
  authUpdateOrganization,
  handleDeleteContactField,
}: {
  index: number;
  keyContact: IObjectFieldsContact;
  handleChangeInputContact: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  authUpdateOrganization: boolean;
  handleDeleteContactField: (idx: number) => void;
}) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const { keyContacts } = useLocationContext();

  return (
    <>
      <Typography variant="h2" sx={{ fontWeight: 600, letterSpacing: "0.18px" }} my={6} mx={2}>
        {t("LOCATION-SBDUS-MORE-FIELDS-TITLE-2")}
        {`${index + 1}`}
      </Typography>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name={FieldKey.NAME}
          id="kyContact1Name"
          value={keyContact?.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
          label={`${t("INPUT-NAME")}`}
          fullWidth
          disabled={!authUpdateOrganization}
        />
        <InputEmail
          name={FieldKey.EMAIL}
          email={keyContact?.email ?? ""}
          // onChangeEmail={onChangeEmail}
          onChangeEmail={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
          isValidEmail={keyContact?.isValidEmail}
          disabled={!authUpdateOrganization}
          id="kycontact-email"
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name={FieldKey.PHONE}
          id="kyContact1Phone"
          value={keyContact?.phone}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
          label={`${t("INPUT-PHONE")}`}
          fullWidth
          disabled={!authUpdateOrganization}
        />
        <Stack direction="row" spacing={4} width="100%" alignItems="center">
          <Input
            name={FieldKey.TITLE}
            id="kyContact1Title"
            value={keyContact?.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputContact(e, index)}
            label={`${t("INPUT-TITLE")}`}
            fullWidth
            disabled={!authUpdateOrganization}
          />
          {keyContacts?.length > 1 && (
            <TooltipApp title="Remove key contact" placement="left">
              <Box>
                <IconButtons
                  icon={<RemoveCircleOutlineIcon sx={{ fontSize: 20 }} />}
                  onClick={() => handleDeleteContactField(index)}
                  color="default"
                  disabled={!authUpdateOrganization}
                />
              </Box>
            </TooltipApp>
          )}
        </Stack>
      </Stack>
    </>
  );
}
