import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";

export default function SBDConnectLanding() {
  const { SBDConnectContact } = useLocationContext();
  const { t } = useTranslation();

  return (
    <Box px={6} pt={4} pb={2} sx={{ backgroundColor: "background.default" }}>
      <Box display="flex" alignItems="center" gap={4}>
        <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 24, transform: "rotate(90deg)" }} />
        <Typography variant="h2" sx={{ fontWeight: 700 }}>
          {`${t("LOCATION-SBD-CONECT")}`}
        </Typography>
      </Box>
      <Box px={2} mt={4}>
        <Box display="flex" mb={4}>
          <Box minWidth="80px" width="20%" maxWidth={110}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-NAME")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              {`${SBDConnectContact?.firstName} ${SBDConnectContact?.lastName}`}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" mb={4}>
          <Box minWidth="80px" width="20%" maxWidth={110}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-PHONE")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box width="75%">
            <Typography variant="h5">{SBDConnectContact?.phone}</Typography>
          </Box>
        </Box>
        <Box display="flex" mb={4}>
          <Box minWidth="80px" width="20%" maxWidth={110}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-EMAIL")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              {SBDConnectContact?.email}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
