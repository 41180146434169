//** MUI imports */
import { Box, FormControlLabel, IconButton, Stack, Switch, Tooltip, Typography, useMediaQuery } from "@mui/material";
//**Hooks */
import { useTranslation } from "react-i18next";
import { AutoComplete, Image } from "components";
import { days } from "constants/globalConstants";
import { useRoleGuard } from "hooks";
import { Roles } from "models";
import { useLocationContext } from "pages/locations/context";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";
import warningImg from "assets/Warning.png";
import { hours } from "./dataHours";

export default function OpenHoursInputs({
  data,
  index,
  openingHours,
  setOpeningHours,
  googleMapsInputs,
}: {
  data: any;
  index: number;
  openingHours: any;
  setOpeningHours: any;
  googleMapsInputs: any;
}) {
  /** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const { view } = useLocationContext();
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
    Roles.multiOrgOwner,
  ]);

  // eslint-disable-next-line no-shadow
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setOpeningHours((prev: any) => {
      const copyData = prev;
      copyData[index] = {
        ...copyData[index],
        is_enabled: event.target.checked,
      };
      return [...copyData];
    });
  };

  //** Getting open hour value */
  const googleOpenHour = googleMapsInputs?.openingHours?.[days[index]]?.opening;
  const googleCloseHour = googleMapsInputs?.openingHours?.[days[index]]?.closing;
  const googleIsEnabled = googleMapsInputs?.openingHours?.[days[index]]?.is_enabled;

  const haveOpenHours = googleOpenHour?.length > 0 && googleCloseHour?.length > 0;

  //** If open hour have value then compare between data and google maps, */
  //** When google maps doen't have data it's not necessary the comparison */
  const openingHourComparisson = googleOpenHour?.length > 0 ? googleOpenHour?.includes(openingHours[index]?.opening) : true;
  const closingHourComparisson = googleCloseHour?.length > 0 ? googleCloseHour?.includes(openingHours[index]?.closing) : true;
  const isEnabledHourComparisson = haveOpenHours ? googleIsEnabled === openingHours[index]?.is_enabled : true;

  return (
    <Stack direction={!matches ? "row" : "column"} spacing={8} alignItems={!matches ? "center" : "left"} mb={8} ml={!matches ? 6 : 0}>
      <FormControlLabel
        sx={{ minWidth: 180 }}
        control={
          <Switch
            checked={openingHours[index]?.is_enabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeSwitch(event, index)}
            disabled={!authUpdateOrganization}
          />
        }
        label={
          <Box display="flex" justifyContent="space-between" width="125px" alignItems="center">
            <Box>{data.label}</Box>
            <Box>
              {!isEnabledHourComparisson && (
                // eslint-disable-next-line react/jsx-indent
                <Tooltip title={t("WARNING-ALERT")} placement="top">
                  <IconButton>
                    <Image src={warningImg} alt="warning" name="warning-img" style={{ width: "22px" }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        }
      />

      <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
        <AutoComplete
          disabled={!openingHours[index]?.is_enabled || !authUpdateOrganization}
          name="autocomplete-opening-hour"
          fullWidth
          value={openingHours[index]?.opening}
          inputLabel={`${t("OPENING-HOUR-LABEL")}`}
          setValue={(value: any) => {
            const copyData = openingHours;
            copyData[index] = {
              ...copyData[index],
              opening: value,
            };
            setOpeningHours([...copyData]);
          }}
          endAdornment={
            !openingHourComparisson &&
            googleMapsInputs?.openingHours?.[days[index]]?.opening && (
              <ReturnDataIcon
                onClick={() => {
                  const copyData = openingHours;
                  copyData[index] = {
                    ...copyData[index],
                    opening: googleMapsInputs?.openingHours?.[days[index]]?.opening,
                  };
                  setOpeningHours([...copyData]);
                }}
              />
            )
          }
          onChangeInput={(e: any) => {
            //** If the user input value appear on hours list it will be auto selected */
            //** Autocomplete select item if i copy a acceptable value */
            if (hours.filter((item) => item === e.target.value).length > 0) {
              const copyData = openingHours;
              copyData[index] = {
                ...copyData[index],
                opening: e.target.value,
              };
              setOpeningHours([...copyData]);
            }
          }}
          //** If the user input doesn't exist on the hours list it must be deleted*/
          freeSolo={hours.filter((item) => item === openingHours[index]?.opening).length > 0}
          options={hours}
          warning={
            view === "create"
              ? !openingHourComparisson
              : !openingHourComparisson || googleMapsInputs?.openingHours?.[days[index]]?.opening === "warning"
          }
        />

        <Typography ml={8} variant="h6">
          {t("GLOBAL-TO")}
        </Typography>
      </Box>

      <AutoComplete
        disabled={!openingHours[index]?.is_enabled || !authUpdateOrganization}
        name="autocomplete-closing-hour"
        fullWidth
        value={openingHours[index]?.closing}
        inputLabel={`${t("CLOSING-HOUR-LABEL")}`}
        setValue={(value: any) => {
          const copyData = openingHours;
          copyData[index] = {
            ...copyData[index],
            closing: value,
          };
          setOpeningHours([...copyData]);
        }}
        endAdornment={
          !closingHourComparisson &&
          googleMapsInputs?.openingHours?.[days[index]]?.closing && (
            <ReturnDataIcon
              onClick={() => {
                const copyData = openingHours;
                copyData[index] = {
                  ...copyData[index],
                  closing: googleMapsInputs?.openingHours?.[days[index]]?.closing,
                };
                setOpeningHours([...copyData]);
              }}
            />
          )
        }
        onChangeInput={(e: any) => {
          //** If the user input value appear on hours list it will be auto selected */
          //** Autocomplete select item if i copy a acceptable value */
          if (hours.filter((item) => item === e.target.value)) {
            const copyData = openingHours;
            copyData[index] = {
              ...copyData[index],
              closing: e.target.value,
            };
            setOpeningHours([...copyData]);
          }
        }}
        //** If the user input doesn't exist on the hours list it must be deleted*/
        freeSolo={hours.filter((item) => item === openingHours[index]?.closing).length > 0}
        options={hours}
        warning={
          view === "create"
            ? !closingHourComparisson
            : !closingHourComparisson || googleMapsInputs?.openingHours?.[days[index]]?.closing === "warning"
        }
      />
    </Stack>
  );
}
