//** MUI Imports */
import { Box, Stack, Typography } from "@mui/material";
//** Components Imports */
import { Button } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Icons */
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { PublicRoutes } from "models";

interface IProps {
  onClickPrev: Function;
  onClickNext: Function;
  disabledPrev?: boolean;
  disabledNext?: boolean;
}

export default function StepperButtons(props: IProps) {
  const { onClickPrev, onClickNext, disabledPrev, disabledNext } = props;
  //** Hooks */
  const { t } = useTranslation();
  return (
    <>
      <Stack justifyContent="space-between" direction="row" mt={12}>
        <Button
          name="org-previous"
          label={`${t("BUTTON-PREVIOUS")}`}
          onClick={onClickPrev}
          startIcon={<ArrowBack />}
          variant="outlined"
          disabled={disabledPrev}
        />
        <Button name="org-next" label={`${t("BUTTON-NEXT")}`} onClick={onClickNext} endIcon={<ArrowForward />} disabled={disabledNext} />
      </Stack>
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }} mt={8}>
        <Typography sx={{ mr: 2, color: "text.secondary" }}>{t("REQUEST-FOOTER-TEST-1")}</Typography>
        <Link to={`/${PublicRoutes.LOGIN}`} replace>
          <Typography sx={{ color: "primary.main", textDecoration: "none" }}>{t("REQUEST-FOOTER-TEST-2")}</Typography>
        </Link>
      </Box>
    </>
  );
}
