//** Components Imports */
import { useFetchAndLoad, useInputValueContext, useRoleGuard } from "hooks";
import InputApiCallCheckbox from "components/atoms/InputApiCallCheckbox/InputApiCallCheckbox";
import { Input } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Context */
//**Services */
import { getAllBrandsAPI } from "pages/locations/services";
import { useOrganizationContext } from "pages/organizations/context";
//** MUI Imports */
import { Box, FormControlLabel, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { Roles } from "models";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
import { TagInput } from "pages/locations";
import { purposeCodeforAplicableBrand } from "pages/locations/constants/purposesToConditions";

// eslint-disable-next-line no-unused-vars
export default function BusinessOrg({ createView }: { createView?: boolean }) {
  //**Context */
  const { category, applicableBrand, alertRequiredFields, purpose, isPremium, displayOrder, isOnlineOnly } = useOrganizationContext();
  const { setApplicableBrand, setCategory, setPurpose, setIsPremium, setDisplayOrder, setIsOnlineOnly, setOrgHasChanges } =
    useOrganizationContext();

  //**Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  //**States */
  const fetchBrands = useFetchAndLoad();
  const authUpdateOrganization = useRoleGuard([
    Roles.superAdmin,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationEditor,
    Roles.locationOwner,
  ]);
  const displayOrderInput = useInputValueContext("", setDisplayOrder);
  const isRequiedFieldEmpty = createView ? alertRequiredFields : true;

  //**Required inputs management */

  const includePurposeCodeforAplicableBrand = purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`));
  const isRequiedExclusiveFields = includePurposeCodeforAplicableBrand && applicableBrand.length === 0;

  const { getPurposes, loadingPurpose } = useGetSearchData();

  // eslint-disable-next-line consistent-return
  const getBrands = async () => {
    try {
      //get the brand sorted by name
      const query: any = { sort: "asc(name)" };
      const response = await fetchBrands.callEndpoint(getAllBrandsAPI({ query }));
      const { data } = response;

      //Generate the array of brands for the autocomplete
      //We need to add the code for send to the create or update funciton
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id, code: item.code }));

      //Add select all field to the brands array
      return [{ label: "Select all", id: "", code: "" }, ...autocompleteData];
    } catch (error) {
      console.log(error);
    }
  };

  const changeControllerFn = () => setOrgHasChanges(true);

  const handleChangesetIsPremium = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeControllerFn();
    setIsPremium(event.target.checked);
  };

  const handleChangesetIsOnlineOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeControllerFn();
    setIsOnlineOnly(event.target.checked);
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 6, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("CREATE-LOC-STEPPER-4")}
      </Typography>
      <Box width="100%" display="flex" gap={4}>
        <Box width="100%">
          <InputApiCallCheckbox
            initialValue={purpose}
            setPropValue={setPurpose}
            apiCallFunction={getPurposes}
            loading={loadingPurpose}
            label={`${t("INPUT-BUSINESS")} *`}
            disabled={!authUpdateOrganization}
            requiredFields={purpose.length === 0 && isRequiedFieldEmpty}
            alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
            changeControllerFn={changeControllerFn}
          />
        </Box>
        <Stack direction={!matches ? "row" : "column"} spacing={4} width="100%">
          <FormControlLabel
            control={<Switch checked={isPremium} onChange={handleChangesetIsPremium} />}
            label={t("INPUT-PREMIUM")}
            disabled={!authUpdateOrganization}
          />

          <FormControlLabel
            control={<Switch checked={isOnlineOnly} onChange={handleChangesetIsOnlineOnly} />}
            label={t("INPUT-ONLINE-ONLY")}
            disabled={!authUpdateOrganization}
          />
        </Stack>
      </Box>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <TagInput
          name="loc-category-product"
          label={`${t("INPUT-CATEGORY-PRODUCT")} `}
          valueTag={category}
          setValueTag={setCategory}
          disabled={!authUpdateOrganization}
        />
        <InputApiCallCheckbox
          initialValue={applicableBrand}
          setPropValue={setApplicableBrand}
          apiCallFunction={getBrands}
          loading={fetchBrands.loading}
          label={`${t("INPUT-BRAND")} ${includePurposeCodeforAplicableBrand ? "*" : ""}`}
          disabled={!authUpdateOrganization}
          requiredFields={isRequiedExclusiveFields && isRequiedFieldEmpty}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mt={6}>
        <Box width="100%">
          {purpose.findIndex((item) => item.code === "OR") !== -1 && (
            <Input
              name="locationNumber"
              value={displayOrder}
              onChange={displayOrderInput.onChange}
              label={`${t("INPUT-DISPLAY-ORDER")} *`}
              disabled={!authUpdateOrganization}
              fullWidth
              error={displayOrder?.length === 0 && isRequiedFieldEmpty}
              helperText={`${t("ALL-REQUIRED.INPUT")}`}
              changeControllerFn={changeControllerFn}
            />
          )}
        </Box>
        <Box width="100%" />
      </Stack>
    </>
  );
}
