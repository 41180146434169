import { Box, Typography } from "@mui/material";
import { FileUploadMain, FileUploaderLocations } from "components";
import { useLocationContext } from "pages/locations/context";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useTranslation } from "react-i18next";

export default function LocationImgRespEdit() {
  const { t } = useTranslation();
  const { setMainPictureText, setAdditionalPicturesText } = useLocationContext();
  const { mainLocationImage, locationImagesAdditional } = useLocationContext();
  const { mainPictureText, additionalPicturesText } = useLocationContext();
  const { setMainLocationImage, setLocationImagesAdditional } = useLocationContext();
  return (
    <Box px={6} py={6} sx={{ backgroundColor: "background.default" }}>
      <Typography variant="h2" sx={{ fontWeight: 700, letterSpacing: "0.18px", display: "flex", gap: 4, alignItems: "center" }} mx={-2}>
        <ImageOutlinedIcon sx={{ fontSize: 24 }} />
        LOCATION IMAGES
      </Typography>
      <Box display="flex" gap={4} flexWrap="wrap" mt={8}>
        <FileUploadMain
          loading={!mainLocationImage.length && !!mainPictureText}
          files={mainLocationImage}
          setFiles={setMainLocationImage}
          label={t("FILE-MAIN").toUpperCase()}
          removeFunction={() => {
            setMainPictureText("");
          }}
        />

        <FileUploaderLocations
          setFileText={setAdditionalPicturesText}
          files={locationImagesAdditional}
          setFiles={setLocationImagesAdditional}
          label={t("FILE-ADD-MORE").toUpperCase()}
          fileText={additionalPicturesText}
          disabled={mainLocationImage.length === 0}
        />
      </Box>
    </Box>
  );
}
