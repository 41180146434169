/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Checkbox, Typography } from "@mui/material";
import { RoleGuard } from "guards";
import Translations from "layouts/components/Translations";
import { Roles } from "models";
import { LocBulkActions } from "pages/locations/components";
import { useLocationListContext } from "../../../context";
import { useSelectLocationsCheckbox } from "../../../hooks";

export default function TextHeaderLoc({ onSearch, isDashboard }: { onSearch: Function; isDashboard?: boolean }) {
  //** On responsive view this header appear on org details */
  const { selectedClaim } = useLocationListContext();

  const { numSelected, onSelectAllClick, rowCount } = useSelectLocationsCheckbox();

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4} alignItems="center">
      <Box>
        {isDashboard ? (
          <Box display="flex" alignItems="flex-start" ml={-3}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
            />
            <Box display="flex" alignItems="center">
              <Typography variant="h1" fontWeight="500" mt={selectedClaim.length > 0 ? 1 : 3}>
                <Translations text="GLOBAL-TITLE-LOCATIONS" />
              </Typography>
              {selectedClaim.length > 0 && (
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]}>
                  <Box mt={2} ml={4}>
                    <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
                  </Box>
                </RoleGuard>
              )}
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <PlaceIcon sx={{ fontSize: 24, mr: 4 }} />
            <Typography variant="h1" fontWeight="500">
              <Translations text={isDashboard ? "GLOBAL-TITLE-LOCATIONS" : "GLOBAL-TITLE-ALL-LOCATIONS"} />
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
