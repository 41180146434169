/* eslint-disable consistent-return */
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { Chip, Input, InputApiCallInfiniteScroll } from "components";
import useUsersInfiniteScroll from "hooks/useUsersInfiniteScroll";
import { useLocationContext } from "pages/locations/context";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useTranslation } from "react-i18next";

import { Roles, RolesIds } from "models";
import useAutorizeUserByRole from "utilities/hooks/useAuthorizeUserRole";
import { useParams } from "react-router-dom";

export default function LocOwnerEdit({ locationId, bgDefault, orgView }: { locationId?: string; bgDefault?: boolean; orgView?: boolean }) {
  const { hqPrimaryContact, setHqPrimaryContact, primaryContact, setPrimaryContact } = useLocationContext();
  const { getUsersInfiniteScroll, userHasNextPage, userData, loading, userPage, setUserPage, setUserData } = useUsersInfiniteScroll();
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { isUserAuthorized } = useAutorizeUserByRole();
  const authorizedRoles = isUserAuthorized([Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.locationOwner]);
  const RolesToFilter = [RolesIds.multiOrgOwner, RolesIds.organizationOwner, RolesIds.organizationEditor];

  const { id } = useParams();

  return (
    <Box px={6} pt={4} pb={2} sx={{ backgroundColor: bgDefault ? "background.default" : "background.paper" }}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Box display="flex" alignItems="center" gap={4}>
          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 24, transform: "rotate(90deg)" }} />
          <Typography variant="h2" sx={{ fontWeight: 700 }}>
            {`${t("LOCATION-LOCATION-OWNER")}`.toUpperCase()}
          </Typography>
        </Box>
        <Box>
          <Chip label={primaryContact?.designation?.toUpperCase() || "-----"} size="small" fontSize={underMd ? 12 : 14} />
        </Box>
      </Box>
      <Box mx={2} display="flex">
        <Box sx={{ width: "100%" }}>
          <Box display="flex" my={4}>
            <Box minWidth="80px" width="20%" maxWidth={110} alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-NAME")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              <InputApiCallInfiniteScroll
                readOnly={!authorizedRoles}
                disabled={!authorizedRoles}
                initialValue={hqPrimaryContact}
                name="loc-organization"
                setPropValue={(value: any) => {
                  setHqPrimaryContact(value);
                  setPrimaryContact({
                    email: value?.email,
                    firstName: value?.firstName,
                    lastName: value?.lastName,
                    phone: value?.phone,
                    id: value?.id,
                    designation: value?.designation,
                    label: `${value?.first_name} ${value?.last_name} - ${value?.email}`,
                  });
                }}
                changeWithInputText
                apiCallFunction={
                  authorizedRoles
                    ? (firstName: string) =>
                        getUsersInfiniteScroll(
                          firstName,
                          orgView ? "" : locationId || hqPrimaryContact.id || "",
                          orgView ? RolesToFilter.join(",") : "",
                          orgView ? id : "",
                        )
                    : () => {}
                }
                loading={loading}
                label=""
                hasNextPage={userHasNextPage}
                page={userPage}
                options={userData}
                setPage={setUserPage}
                setOptions={setUserData}
                size="small"
                alwaysCallApi
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="20%" maxWidth={110} alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-PHONE")}: `.toUpperCase() || "----"}
              </Typography>
            </Box>
            <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              <Input name="org-email" value={primaryContact?.phone} onChange={() => {}} fullWidth readonly size="small" />
            </Box>
          </Box>
          <Box display="flex" mb={12}>
            <Box minWidth="80px" width="20%" maxWidth={110} alignContent="center">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-EMAIL")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
              <Input name="org-email" value={primaryContact?.email} onChange={() => {}} fullWidth readonly size="small" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
