/* eslint-disable consistent-return */
import { Box, Divider } from "@mui/material";

import { ContactInputs, OpenHours, KeyContact } from "pages/locations/components";

export default function ContactStep({ create }: { create?: boolean }) {
  return (
    <Box>
      <ContactInputs create={create} />
      <KeyContact />
      <Divider />
      <OpenHours />
      <Divider />
    </Box>
  );
}
