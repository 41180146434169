import { useEffect } from "react";
//** MUI Imports */
import { Box, Card, Divider, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { SectionsTitles } from "components";
import { BasicInformationUsers } from "pages/users/components";
import CreateUsersButtons from "pages/users/components/molecules/CreateUsersButtons/CreateUsersButtons";
//** Hooks */
import { useTranslation } from "react-i18next";

//** Context */
import { useUserContext } from "pages/users/context";
import { UserViewsName } from "pages/users/models";

export default function UsersCreate() {
  //** Context*/
  const { clearBasicInformation, setView } = useUserContext();

  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    clearBasicInformation();
    setView(UserViewsName.CREATE);
  }, []);

  return (
    <Box px={underMd ? 4 : 0} mb={underMd ? 12 : 0}>
      <Card sx={{ py: 8, px: 6 }}>
        <SectionsTitles title={`${t("GLOBAL-TITLE-USERS-INVITATION")}`} subTitle={`${t("INVITATION-VIEW-SUBTITLE")}`} />
        <BasicInformationUsers />
        <Divider />
        <CreateUsersButtons />
      </Card>
    </Box>
  );
}
