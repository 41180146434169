//** Components imports */
import { CardHeaderLogin } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";

export default function StepperHeader() {
  //** Hooks */
  const { t } = useTranslation();
  // if (activeStep === 0) {
  return (
    <CardHeaderLogin
      title={`${t("REQUEST-STEP1-TITLE")}`}
      subTitle={`${t("REQUEST-STEP1-SUBTITLE")}`}
      subTitle2={`${t("REQUEST-STEP1-SUBTITLE2")}`}
      paddingSecondTitle={1.5}
      variant="subtitle1"
    />
  );
  // }
  // return <></>;
}
