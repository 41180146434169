import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { LocOwnerEdit, SbdConnectEdit } from "pages/locations/components";
import { useParams } from "react-router-dom";
import { UsersSpeedDialOnScroll } from "components/responsiveComponents";
import { useRequiredFields } from "pages/locations/hooks";
import { UsersList } from "pages/users";
import { useGlobalContext } from "context/globalContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import { LocAddressRespEdit } from "../LocAddressRespEdit";
import { LocHeaderEdit } from "../LocHeaderEdit";
import LocOpenHoursEdit from "../LocOpenHoursEdit/LocOpenHoursEdit";
import LocBusinessRespEdit from "../LocBusinessRespEdit/LocBusinessRespEdit";
import { FacilityInfoRespEdit } from "../FacilityInfoRespEdit";
import { KeyConctactRespEdit } from "../KeyConctactRespEdit";
import { LocationImgRespEdit } from "../LocationImgRespEdit";

export default function LocationEditResponsive() {
  useRequiredFields();
  const { id } = useParams();
  const { t } = useTranslation();

  const { setUserEditMode, locEditMode } = useGlobalContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    setUserEditMode(true);
  }, []);
  return (
    <Box>
      <LocHeaderEdit />
      <LocAddressRespEdit />
      <LocOpenHoursEdit />
      <LocBusinessRespEdit preventApiCall={locEditMode && underMd} />
      <Box mt={8} mx={4}>
        <SbdConnectEdit editView />
      </Box>
      <Accordion sx={{ p: 0, m: 0, "& .MuiAccordionSummary-content.Mui-expanded": { m: 0 } }}>
        <AccordionSummary sx={{ p: 0, m: 0 }} expandIcon={<ExpandMoreIcon sx={{ fontSize: 24 }} />}>
          <Box display="flex" alignItems="center" gap={4} mx={-2} px={6}>
            <DomainAddIcon sx={{ fontSize: 24 }} />
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("LOCATION-FACILITY-INFO")}`.toUpperCase()}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, m: 0 }}>
          <FacilityInfoRespEdit />
          <LocationImgRespEdit />
          <KeyConctactRespEdit />
        </AccordionDetails>
      </Accordion>
      <LocOwnerEdit locationId={id || ""} bgDefault />
      <Box mt={-16}>
        <UsersList locationId={id} embebed staticTop height="100%" invertBg />
      </Box>
      <UsersSpeedDialOnScroll needsScroll locationId={id} embebed />
    </Box>
  );
}
