/* eslint-disable no-unused-vars */
//** React imports */
import React, { useEffect, useState } from "react";
//** MUI imports */
import Box from "@mui/material/Box";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import { Fade, Grid, Skeleton, Stack } from "@mui/material";
//** Models */
import { ILocationsListDOM } from "pages/locations/models";

//** Hooks */
import useEmptyCardsLocationData from "hooks/useEmptyCardsLocationData";

//** Components imports */
import useGetLocationsDashboard from "pages/locations/pages/locationsList/hooks/useLocationsDashboard";
import { EmptyListText } from "components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { LocSpeedDial, MapSpeedDialLoc } from "components/responsiveComponents";
import { useGlobalContext } from "context/globalContext";
import { RoleGuard } from "guards";
import { Roles } from "models";

import { LocationDashboardView } from "pages/locations/components";
import { useLocationContext } from "pages/locations/context";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useParams } from "react-router-dom";
import { AppListHeader } from "../../atoms";
//** Context */
import { useLocationListContext } from "../../../context";
import TableResponsiveRow from "../../responsive/TableResponsiveRow/TableResponsiveRow";
import useGetSearchData from "../../../hooks/useGetSearchData";
import { LocListDetail } from "../LocListDetail";

export default function LocationsTable({ organizationId }: { organizationId?: string }) {
  const { id } = useParams();
  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization, roles } = userState;
  const orgIdForMultiOrgs = roles === Roles.multiOrgOwner ? "" : organization?.id;
  const { loading, hasNextPage, loadMoreLocations, onSearch, page } = useGetLocationsDashboard(
    `${id || orgIdForMultiOrgs || organizationId || ""}`,
    false,
  );
  const { locationHasFilter } = useListAndSearchContext();
  const { locationsMapListArray } = useLocationListContext();
  const { purposesData } = useLocationContext();
  const { locEditMode, mapView, setLocEditMode } = useGlobalContext();
  const [purposesLocation, setPurposesLocation] = useState<{ label: string; id: string; code: string }[]>([]);

  const [selectedListData, setSelectedListData] = useState({} as ILocationsListDOM);

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreLocations(),
    rootMargin: "0px 0px 400px 0px",
  });
  const hasData = locationHasFilter?.length === 0 ? locationsMapListArray.length !== 0 : true;

  const { dataCards } = useEmptyCardsLocationData();

  const { purposeOptions, getPurposes } = useGetSearchData();

  useEffect(() => {
    setLocEditMode(false);
    getPurposes();
  }, []);

  const handleClick = (data: ILocationsListDOM) => {
    setSelectedListData(data);
    const purposes = purposesData.filter((item) => data.purposeCodes.includes(item.code));
    setPurposesLocation(purposes);
  };

  return (
    <>
      {!mapView ? (
        <>
          <AppListHeader onSearch={onSearch} mainPage />
          <Box display="flex">
            <Box display="block" width="70%">
              <Box
                height={`calc(100vh - ${
                  locEditMode ? `246px - ${locationHasFilter ? "70px" : "0px"}` : `200px - ${locationHasFilter ? "70px" : "0px"}`
                })`}
                overflow="scroll"
              >
                {loading && locationsMapListArray.length === 0 ? (
                  <Stack spacing={4}>
                    {Array.from({ length: 12 }).map((_, i) => (
                      <Skeleton key={i} width="100%" height={60} variant="rounded" />
                    ))}
                  </Stack>
                ) : (
                  <Box mt={2}>
                    {locationsMapListArray?.map((item, i) => (
                      <TableResponsiveRow data={item} purposeOptions={purposeOptions} index={i} onClick={() => handleClick(item)} />
                    ))}
                    {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

                    <Box display="flex" justifyContent="center">
                      {!hasData && (
                        <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                          {dataCards.map((item, index) => (
                            <EmptyTableCards
                              key={index}
                              data={dataCards[index]}
                              name="custom-radios-delivery"
                              gridProps={{ md: 4, sm: 12, xs: 12 }}
                            />
                          ))}
                        </Grid>
                      )}
                      {locationHasFilter?.length > 0 && locationsMapListArray.length === 0 && <EmptyListText />}
                    </Box>
                  </Box>
                )}
              </Box>
              <RoleGuard
                authorizedRoles={[
                  Roles.superAdmin,
                  Roles.multiOrgOwner,
                  Roles.organizationEditor,
                  Roles.organizationOwner,
                  Roles.locationEditor,
                  Roles.locationOwner,
                ]}
              >
                <Fade in={locEditMode}>
                  <Box position="relative">
                    <LocSpeedDial absolute />
                  </Box>
                </Fade>
              </RoleGuard>
              <Fade in={!locEditMode}>
                <Box position="relative">
                  <MapSpeedDialLoc absolute />
                </Box>
              </Fade>
            </Box>
            <Box width="30%" mt={4} pl={2}>
              <LocListDetail data={selectedListData} isAnyLocSelected={!!selectedListData.id} purposesLocation={purposesLocation} />
            </Box>
          </Box>
        </>
      ) : (
        <LocationDashboardView
          loading={loading}
          hasNextPage={hasNextPage}
          loadMoreLocations={loadMoreLocations}
          onSearch={onSearch}
          page={page}
        />
      )}
    </>
  );
}
