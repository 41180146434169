/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from "react";
//** MUI Imports*/
import { Box, Checkbox, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components Imports*/
import {
  ButtonExpand,
  FilterChips,
  Input,
  InputApiCall,
  InputApiCallCheckboxWithOutSelectAll,
  InputApiCallInfiniteScroll,
  Select,
  TableSearch,
} from "components";

//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { ILocationsListDOM } from "pages/locations/models";
import { useTranslation } from "react-i18next";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

//**Icons */
import { IDataButtonExpand } from "components/molecules/ButtonExpand/ButtonExpand";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { LocBulkActions } from "pages/locations/components";
import { useNavigate, useParams } from "react-router-dom";
import { capitalize } from "utilities";
import { useLocationListContext } from "../../../context";
import { useSelectLocationsCheckbox } from "../../../hooks";
import useGetSearchData from "../../../hooks/useGetSearchData";
import useLocationSearch from "../../../hooks/useLocationSearch";
import LocationImportOnHeader from "./LocationImportOnHeader";
import OrganizationEditButtonResp from "./OrganizationEditButtonResp";
import TextHeaderLoc from "./TextHeaderLoc";

interface ISearchChip {
  key: keyof ILocationsListDOM; //** Key on DOM  */
  label: string; //** Translaton from i18n */
  data: any; //** State value */
}

export default function AppListHeader({
  onSearch,
  isDashboard,
  mainPage,
}: {
  onSearch: Function;
  isDashboard?: boolean;
  mainPage?: boolean;
  loading?: boolean;
  hiddeEdit?: boolean;
}) {
  //** Context */
  const { locationHasFilter, setOpenImportModal } = useListAndSearchContext();
  const { selectedClaim } = useLocationListContext();

  const nameSearchFn = useLocationSearch().locNameSearch;
  const visibilitySearch = useLocationSearch().locVisibilitySearch;
  const marketSearch = useLocationSearch().locMarketSearch;
  const purposeSearch = useLocationSearch().locPurposesSearch;
  const organizationsearch = useLocationSearch().locOrganizationSearch;

  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { numSelected, onSelectAllClick, rowCount } = useSelectLocationsCheckbox();

  //** Var */
  const { id } = useParams();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);
  //** Button */
  const fromAdminOrgView = window.location.pathname.includes(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`);
  const adminNavigation = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CLAIM_LOCATION_NAV}/${id}`;
  const organizationRelatedNavigation = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CLAIM_LOCATION_NAV}`;

  const dataButton: IDataButtonExpand[] = [
    {
      label: capitalize(t("BUTTON-CREATE").toLocaleLowerCase()),
      onClick: () => {
        navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CREATE}`, { replace: false });
      },
    },
    {
      label: capitalize(t("BUTTON-IMPORT").toLocaleLowerCase()),
      onClick: () => {
        setOpenImportModal(true);
      },
    },
    {
      label: capitalize(t("BUTTON-CLAIM").toLocaleLowerCase()),
      onClick: () => {
        navigate(fromAdminOrgView ? adminNavigation : organizationRelatedNavigation, { replace: false });
      },

      hide: !fromAdminOrgView && !userState.organization?.id,
    },
  ];

  const {
    getPurposes,
    getCountryData,
    getOrganizations,
    orgPage,
    hasNextPage,
    orgData,
    setOrgPage,
    loadingOrg,
    setOrgData,
    loadingCountry,
    loadingPurpose,
  } = useGetSearchData();

  const { latitudeClaimMap, longitudeClaimMap, setLatitudeClaimMap, setLongitudeClaimMap } = useLocationListContext();

  const optionsVisibility = ["Visible", "Hidden"];

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  //**Modal */

  //**Chips */
  const filterChips: ISearchChip[] = [
    {
      key: "name",
      label: "INPUT-NAME",
      data: nameSearchFn.value,
    },
    {
      key: "isVisible",
      label: "INPUT-VISIBILITY",
      data: visibilitySearch.value,
    },
    {
      key: "purposeCodes",
      label: "INPUT-PURPUSE",
      data: purposeSearch?.valueLabel,
    },
    {
      key: "organizationId",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationsearch?.valueLabel,
    },
    {
      key: "marketOrCountry",
      label: "INPUT-MARKET",
      data: marketSearch?.valueLabel,
    },
    {
      key: "coordinates",
      label: "INPUT-COORDINATES",
      data: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : "",
    },
  ];
  //Filter Chips functions
  const handleDelete = (filterName: "name" | "isVisible" | "purposeCodes" | "organizationId" | "marketOrCountry" | "coordinates") => {
    if (filterName === "name") nameSearchFn.clearByKey();
    if (filterName === "isVisible") visibilitySearch.clearByKey();
    if (filterName === "purposeCodes") purposeSearch.clearByKey();
    if (filterName === "organizationId") organizationsearch.clearByKey();
    if (filterName === "marketOrCountry") marketSearch.clearByKey();
    if (filterName === "coordinates") {
      setLatitudeClaimMap(undefined);
      setLongitudeClaimMap(undefined);
    }
    onSearch();
  };

  const handleDeleteAll = () => {
    nameSearchFn.clearSearch();
    visibilitySearch.clearSearch();
    purposeSearch.clearSearch();
    organizationsearch.clearSearch();
    marketSearch.clearSearch();
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    onSearch();
  };

  const hasSelectedItems = selectedClaim.length > 0;

  return (
    <Box pb={hasSelectedItems ? 5 : 10}>
      <Box display="flex" alignItems="center" pt={isDashboard && underMd ? 4 : 8}>
        <Box width="100%">
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={4}
            px={3}
            mb={1}
            alignItems={underMd && isDashboard ? "center" : "center"}
          >
            <TextHeaderLoc onSearch={onSearch} isDashboard={isDashboard} />

            <Box
              ml="auto"
              display="flex"
              flexWrap="wrap"
              flexDirection={underMd ? "column" : "row"}
              alignItems={underMd ? "flex-end" : "normal"}
            >
              {underMd && !mainPage && <OrganizationEditButtonResp />}

              <TableSearch
                noOptionsComponent={
                  <Box>
                    <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                      <Input name="loc-name" {...nameSearchFn} label={`${t("INPUT-NAME")}`} fullWidth />
                      <Select
                        name="loc-visibility"
                        value={visibilitySearch.value || ""}
                        label={`${t("INPUT-VISIBILITY")}`}
                        setValue={visibilitySearch.onChange}
                        options={optionsVisibility}
                      />
                    </Stack>

                    <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                      <InputApiCallCheckboxWithOutSelectAll
                        setPropValue={purposeSearch.onChange as any}
                        apiCallFunction={getPurposes}
                        loading={loadingPurpose}
                        label={`${t("INPUT-PURPUSE")}`}
                        initialValue={
                          purposeSearch?.valueLabel
                            ? purposeSearch?.valueLabel
                                ?.split(",")
                                .map((item: string, index: number) => ({ label: item, id: purposeSearch.valueId?.split(",")[index] }))
                            : []
                        }
                      />

                      <InputApiCall
                        name="loc-market-search"
                        setPropValue={marketSearch.onChange}
                        apiCallFunction={getCountryData}
                        loading={loadingCountry}
                        label={`${t("INPUT-MARKET")}`}
                        externalValue={{ label: marketSearch.valueLabel || "", id: marketSearch.valueId || "" } || null}
                      />
                    </Stack>
                    {!isDashboard && !userState?.organization?.id && (
                      <Box mb={4}>
                        <InputApiCallInfiniteScroll
                          initialValue={{ label: organizationsearch.valueLabel || "", id: organizationsearch.valueId || "" } || null}
                          name="loc-organization-search"
                          setPropValue={organizationsearch.onChange}
                          changeWithInputText
                          apiCallFunction={getOrganizations}
                          loading={loadingOrg}
                          label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                          hasNextPage={hasNextPage}
                          page={orgPage}
                          options={orgData}
                          setPage={setOrgPage}
                          setOptions={setOrgData}
                          search
                        />
                      </Box>
                    )}
                  </Box>
                }
                onReset={handleDeleteAll}
                onSubmit={(e: any) => {
                  e.preventDefault();
                  onSearch();
                }}
              />
            </Box>
            <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationEditor, Roles.organizationOwner, Roles.locationOwner]}>
              <ButtonExpand data={dataButton} />
            </RoleGuard>
          </Box>

          <LocationImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
        </Box>
      </Box>
      <Box ml={18} mt={-4}>
        {hasSelectedItems && (
          <Typography variant="h4" fontWeight="500">
            {`${t("GLOBAL-SELECTED")} ${selectedClaim.length}`}
          </Typography>
        )}

        {hasSelectedItems && !isDashboard && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]}>
            <Box display="flex" alignItems="center" mt={2} ml={-4}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              </Box>
              <Box>
                <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
              </Box>
            </Box>
          </RoleGuard>
        )}
        {!!locationHasFilter && (
          <Stack direction="row" spacing={4} alignItems="center" mt={underMd ? 2 : 4} mb={underMd ? 2 : 4} pb={underMd ? 0 : 8}>
            <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
          </Stack>
        )}
      </Box>
    </Box>
  );
}
