import { ChangeEvent, useEffect } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { Input, InputApiCall, InputApiCallInfiniteScroll } from "components";
import { useGetRoles, useInputValueContext, useLocationInfiniteScroll } from "hooks";
import { EMAIL_REGEX, PrivateRoutes, Roles } from "models";
import { useUserContext } from "pages/users/context";
import { RolesRadioButtons } from "pages/users/models";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation } from "react-router-dom";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useTranslation } from "react-i18next";
import CreateUsersButtons from "../CreateUsersButtons/CreateUsersButtons";

interface IModalConfirmProps {
  open: boolean;
  handleClose: () => void;
}

export default function UserCreateModal({ open, handleClose }: IModalConfirmProps) {
  const { t } = useTranslation();
  //** Context */
  const { firstName, lastName, email, phone, title, alertRequiredFields, role, organization, location } = useUserContext();
  const { setFirstName, setLastName, setEmail, setPhone, setTitle, setRole, setRadioRole, setLocation } = useUserContext();
  const { isValidEmail, setIsValidEmail } = useUserContext();
  const { getRolesByUserRole, loading } = useGetRoles();
  const { setUserGetToggle } = useListAndSearchContext();

  const locationUrl = useLocation();
  const { getlocationsInfiniteScroll, locHasNextPage, locData, locPage, setLocPage, setLocData } = useLocationInfiniteScroll();

  const isOrgCreation = locationUrl.pathname.includes(PrivateRoutes.ORGANIZATIONS);
  const LocationRoles = [Roles.locationEditor, Roles.locationOwner];
  useEffect(() => {
    if (!open) return;

    if (isOrgCreation) setRadioRole(RolesRadioButtons.orgRelated);
    else setRadioRole(RolesRadioButtons.locRelated);

    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setTitle("");
    setRole({ id: "", label: "" });
    if (isOrgCreation) setLocation({ id: "", label: "" });
  }, [open]);

  //**States */
  const firstNameInput = useInputValueContext("", setFirstName);
  const lastNameInput = useInputValueContext("", setLastName);
  const phoneInput = useInputValueContext("", setPhone);
  const titleInput = useInputValueContext("", setTitle);
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValidEmail(EMAIL_REGEX.test(e.target.value));
  };

  return (
    <Dialog open={open} aria-labelledby="user-view-edit" aria-describedby="user-view-edit-description" fullWidth maxWidth="lg">
      <DialogTitle id="user-view-edit" display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={4}>
          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 24, transform: "rotate(90deg)" }} />
          <Typography variant="h2" sx={{ fontWeight: 700 }}>
            {t("ADD-USERS-MODAL-TITLE")}
          </Typography>
        </Box>

        <Box display="flex">
          <Box sx={{ mt: -8 }}>
            <CreateUsersButtons
              hideCancel
              iconsButtons
              successFunction={() => {
                setUserGetToggle((prev) => !prev);
                handleClose();
              }}
            />
          </Box>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box mt={4} px={4} width="100%">
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-FIRST-NAME")}* : `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Input
                name="users-first-name"
                value={firstName}
                onChange={firstNameInput.onChange}
                fullWidth
                error={firstName?.length === 0 && alertRequiredFields}
                helperText={`${t("ALL-REQUIRED.INPUT")}`}
                size="small"
              />
            </Box>
          </Box>

          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-LAST-NAME")}* : `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Input
                name="users-first-name"
                value={lastName}
                onChange={lastNameInput.onChange}
                fullWidth
                error={lastName?.length === 0 && alertRequiredFields}
                helperText={`${t("ALL-REQUIRED.INPUT")}`}
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-EMAIL")}* : `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Input
                name="users-email"
                value={email}
                onChange={onChangeEmail}
                fullWidth
                error={!isValidEmail || (email?.length === 0 && alertRequiredFields)}
                helperText={!isValidEmail && `${email || ""}`.length > 0 ? `${t("INVALID-EMAIL")}` : `${t("ALL-REQUIRED.INPUT")}`}
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-PHONE")}* : `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Input
                name="users-phone"
                value={phone}
                onChange={phoneInput.onChange}
                fullWidth
                error={phone?.length === 0 && alertRequiredFields}
                helperText={`${t("ALL-REQUIRED.INPUT")}`}
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-TITLE")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Input name="users-title" value={title} onChange={titleInput.onChange} fullWidth size="small" />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-ROLE")}* : `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <InputApiCall
                name="usr-role"
                externalValue={role}
                setPropValue={(value: any) => {
                  setRole(value);
                  if (LocationRoles.includes(value.label)) {
                    setRadioRole(RolesRadioButtons.locRelated);
                  } else {
                    setRadioRole(RolesRadioButtons.orgRelated);
                  }
                }}
                apiCallFunction={
                  () => getRolesByUserRole(isOrgCreation ? RolesRadioButtons.orgRelated : RolesRadioButtons.locRelated, isOrgCreation)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                loading={loading}
                label=""
                requiredFields={role?.label?.length === 0 && alertRequiredFields}
                alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
                size="small"
              />
            </Box>
          </Box>
          <Box display="flex" mb={4}>
            <Box minWidth="80px" width="150px">
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-ORGANIZATION")}: `.toUpperCase()}
              </Typography>
            </Box>
            <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <InputApiCallInfiniteScroll
                initialValue={organization}
                name="loc-organization"
                setPropValue={() => {}}
                changeWithInputText
                apiCallFunction={() => {}}
                loading={false}
                label=""
                hasNextPage={false}
                page={0}
                options={[]}
                setPage={() => {}}
                setOptions={() => {}}
                requiredFields={!Array.isArray(organization) && !organization?.label && alertRequiredFields}
                size="small"
                disabled
              />
            </Box>
          </Box>
          {(role.label === Roles.locationEditor || role.label === Roles.locationOwner || !isOrgCreation) && (
            <Box display="flex" mb={4}>
              <Box minWidth="80px" width="150px">
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {`${t("INPUT-LOCATION")}: `.toUpperCase()}
                </Typography>
              </Box>
              <Box width="calc(100% - 150px)" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                <InputApiCallInfiniteScroll
                  initialValue={location}
                  name="loc.user"
                  setPropValue={setLocation}
                  changeWithInputText
                  apiCallFunction={(name: string) => {
                    getlocationsInfiniteScroll(name, !Array.isArray(organization) ? `${organization?.id}` : "");
                  }}
                  loading={false}
                  size="small"
                  label=""
                  hasNextPage={locHasNextPage}
                  page={locPage}
                  options={locData}
                  setPage={setLocPage}
                  setOptions={setLocData}
                  requiredFields={!location?.label && alertRequiredFields}
                  alwaysCallApi
                  disabled={!isOrgCreation}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
