//**Hooks */
import { useAsync, useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { useGlobalContext } from "context/globalContext";
//**Context */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Services */
import { PrivateRoutes, Roles } from "models";
import { IOrganizationListDOM, organizationGetallAdapter } from "pages/organizations";
import { getOneUserAPI } from "../pages/usersInformation/services";
import { useUserContext } from "../context";
//**Models */
//**Adapter */
import { userGetOneAdapter } from "../adapters";
import { IUsersFromAPI, IUsersListDOM, RolesRadioButtons, UserViewsName } from "../models";
import { statusRequest } from "../pages/usersRequestList/hooks/useGetUsersRequest";

export default function useUsers(id: string, view?: UserViewsName) {
  //**Hooks */
  const userFetch = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToTheEndOfHistory, createCustomHistory } = useBreadcrumbs();
  const { breadcrumbsHistory } = useGlobalContext();

  //**Context */
  const {
    setFirstName,
    setLastName,
    setEmail,
    setSBDEmail,
    setRole,
    setRadioRole,
    updatedToggle,
    setPhone,
    setDataCopy,
    setTitle,
    setIsPrimaryContact,
  } = useUserContext();
  const { setActive, setOrganization, setLocation } = useUserContext();

  const userState = useSelector((store: AppStore) => store.user);
  const { organization, roles } = userState;

  const canSeeAnAdmin = roles === Roles.superAdmin || roles === Roles.generalViewOnly || roles === Roles.multiOrgOwner;
  const orgRelatedRoles = [Roles.multiOrgOwner, Roles.organizationEditor, Roles.organizationOwner, Roles.viewOnly];
  //** Data From APi */
  // eslint-disable-next-line no-shadow
  const getOneOrganization: any = async (id: string) => {
    // cleatBasicInformation();

    //Calling get all API
    const response = await userFetch.callEndpoint(getOneUserAPI(id), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));

    return response;
  };
  const redirectToUserList = () => {
    navigate(`/${PrivateRoutes.USERS_REQUEST}/${PrivateRoutes.LIST}`);
    handleCreateToast(t("ALERT-INFO-INVALID-USER-ACCESS"), "info");
  };

  const adaptInfo = async (data: IUsersFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const adaptedData: IUsersListDOM = userGetOneAdapter(data?.result);

    //Breadcrumb
    if (organization?.id) {
      createCustomHistory([
        {
          label: organization.name,
          url: `/${PrivateRoutes.ORGANIZATIONS_DASHBOARD}`,
          index: 0,
        },
        {
          label: `${adaptedData.firstName} ${adaptedData.lastName}`,
          url: `/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${adaptedData.id}`,
          index: breadcrumbsHistory.length,
        },
      ]);
    } else {
      addToTheEndOfHistory({
        label: `${adaptedData.firstName} ${adaptedData.lastName}`,
        url: `/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${adaptedData.id}`,
        index: breadcrumbsHistory.length,
      });
    }

    //Data
    if (view === UserViewsName.REQUEST && adaptedData.status !== statusRequest.USERS_WITH_CREATION_REQUEST) {
      handleCreateToast(t("ALERT-INFO-REQUEST-REVIEWED"), "info");
      navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`);
      return;
    }

    if (!canSeeAnAdmin && (adaptedData.roleData[0].name === Roles.superAdmin || adaptedData.roleData[0].name === Roles.generalViewOnly)) {
      redirectToUserList();
    }

    let copyRadioRole = "";
    setFirstName(adaptedData.firstName);
    setLastName(adaptedData.lastName);
    setEmail(adaptedData.username);
    setSBDEmail(adaptedData.SBDEmail);
    setPhone(adaptedData.phone);
    setActive(adaptedData.isSuspended);
    setRole({ label: "", id: "" });
    setTitle(adaptedData.title);
    setIsPrimaryContact(!!adaptedData?.primaryContact?.relatedId || false);
    if (adaptedData.roleData[0].name === Roles.multiOrgOwner) {
      setOrganization([]);
    } else {
      setOrganization({ label: "", id: "" });
      setLocation({ label: "", id: "" });
    }
    if (adaptedData.roleData[0].name === Roles.superAdmin) {
      setRadioRole(RolesRadioButtons.superAdmin);
      copyRadioRole = RolesRadioButtons.superAdmin;
      setDataCopy({ role: "", organizations: [] });
    } else if (adaptedData.roleData[0].name === Roles.generalViewOnly) {
      setRadioRole(RolesRadioButtons.generalViewOnly);
      copyRadioRole = RolesRadioButtons.generalViewOnly;
      setDataCopy({ role: "", organizations: [] });
    } else {
      if (orgRelatedRoles.find((role) => role === adaptedData.roleData[0].name)) setRadioRole(RolesRadioButtons.orgRelated);
      else setRadioRole(RolesRadioButtons.locRelated);
      copyRadioRole = RolesRadioButtons.orgRelated;
      setRole({ label: adaptedData.roleData[0].name, id: adaptedData.roleData[0].id });
      if (adaptedData?.id) {
        const adaptedDataOrganization: IOrganizationListDOM[] = organizationGetallAdapter(data?.result?.organization_data);

        if (organization?.id && adaptedDataOrganization.findIndex((item) => item.id === organization.id) === -1) redirectToUserList();

        if (adaptedData.roleData[0].name === Roles.multiOrgOwner) {
          setOrganization(adaptedDataOrganization.map((item) => ({ label: item.name, id: item.id })));
        } else {
          setOrganization({ label: adaptedDataOrganization[0]?.name, id: adaptedDataOrganization[0]?.id });
          setLocation({ label: data?.result?.location_data?.name || "", id: data?.result?.location_id || "" });
        }
        setDataCopy({
          organizations: adaptedDataOrganization.map((item) => ({ label: item.name, id: item.id })),
          role: adaptedData.roleData[0].name,
        });
      }
    }

    if (UserViewsName.PROFILE === view) {
      setRole({ label: adaptedData.roleData[0].name, id: adaptedData.roleData[0].id });
    }

    setDataCopy((prev) => ({
      ...prev,
      firstName: adaptedData.firstName,
      lastName: adaptedData.lastName,
      email: adaptedData.username,
      phone: adaptedData.phone,
      radioRole: copyRadioRole,
      active: adaptedData.isSuspended,
    }));
  };

  useAsync(
    () => getOneOrganization(id),
    adaptInfo,
    () => {},
    [organization?.id, updatedToggle, userState.id],
  );

  return { loading: userFetch.loading };
}
