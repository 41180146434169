/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import { Chip, TooltipError } from "components";
import { useLocationContext } from "pages/locations/context";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const SkeletonLoader = ({ my }: { my?: boolean }) => {
  return (
    <Box display="flex" alignItems="center" gap={2} mt={my ? 0 : 4} my={my ? 4 : 0} width="90%">
      {Array.from({ length: 3 }).map((_, i) => (
        <Skeleton key={i} width="80%" height={20} variant="rounded" />
      ))}
    </Box>
  );
};

export default function BusinessLocLanding({ loading }: { loading?: boolean }) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [expandPurposes, setExpandPurposes] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(!underMd);
  const { t } = useTranslation();

  const { purpose, isOnlineOnly, isPremium, applicableBrand, category } = useLocationContext();

  const handleChange = () => {
    if (underMd) setAccordionExpanded((prev) => !prev);
    if (!accordionExpanded) setExpandPurposes(false);
  };

  return (
    <Box px={6} py={4} mt={4} sx={{ backgroundColor: "background.paper" }}>
      <Box display="flex" alignItems="center" gap={4}>
        <StoreOutlinedIcon sx={{ fontSize: 24 }} />
        <Typography variant="h2" sx={{ fontWeight: 700 }}>
          {`${t("CREATE-LOC-STEPPER-4")}`.toUpperCase()}
        </Typography>
        {purpose.length === 0 && !loading && <TooltipError tooltipLabel="Some elemets are incomplete" />}
      </Box>
      <Accordion
        expanded={accordionExpanded}
        disableGutters
        elevation={0}
        sx={{
          "&::before": { display: "none" },
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          border: "0 !important",
          boxShadow: "none !important",
          mx: -4,
        }}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={
            underMd ? (
              <ExpandCircleDownIcon
                sx={{
                  fontSize: 24,
                }}
              />
            ) : null
          }
        >
          {loading ? (
            <SkeletonLoader />
          ) : (
            <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} mt={4}>
              {purpose.map((item, i) => (
                <>{i >= 2 && !expandPurposes && !accordionExpanded ? <></> : <Chip label={item?.label?.toUpperCase()} size="small" />}</>
              ))}
              {purpose.length > 2 && !expandPurposes && !accordionExpanded && (
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ px: 0, minWidth: 40 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandPurposes(true);
                  }}
                >
                  +{purpose.length - 2}
                </Button>
              )}
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={4} mb={16} mx={1}>
            <FormControlLabel
              control={<Switch checked={isPremium} size="small" />}
              label={<Typography variant="h6">{t("INPUT-PREMIUM")}</Typography>}
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Switch checked={isOnlineOnly} size="small" />}
              label={<Typography variant="h6">{t("INPUT-ONLINE-ONLY")}</Typography>}
              labelPlacement="start"
            />
          </Stack>
          <Typography variant="h3" sx={{ fontWeight: 700 }}>
            {`${t("INPUT-CATEGORY-SERVICED")}`.toUpperCase()}
          </Typography>
          {/* //**Categories  */}
          {loading ? (
            <SkeletonLoader my />
          ) : (
            <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} my={4}>
              {category.map((item) => (
                <Chip label={item} size="small" variant="outlined" />
              ))}
            </Box>
          )}
          {/* //**Brands  */}
          <Typography variant="h3" sx={{ fontWeight: 700 }}>
            {`${t("LOCATION-BRANDS")}`.toUpperCase()}
          </Typography>

          {loading ? (
            <SkeletonLoader my />
          ) : (
            <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} mt={4}>
              {applicableBrand.map((item) => (
                <Chip label={item.label} size="small" variant="outlined" />
              ))}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
