import { Box, IconButton, Skeleton, Theme, Typography, useMediaQuery, Grid } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useGlobalContext } from "context/globalContext";
import { Image } from "components";
import notImage from "assets/NotImage.png";
import { useState } from "react";

export default function LocAddressInfo({ loading }: { loading?: boolean }) {
  const { address1, state, city, website, logoText } = useLocationContext();
  const [load, setLoad] = useState(true);
  const { t } = useTranslation();
  const { setMapLocDetailView } = useGlobalContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const dataRender = (text: string) => {
    if (loading) {
      return <Skeleton variant="rounded" width="95%" height={20} />;
    }
    return (
      <Typography variant="h2" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
        {text}
      </Typography>
    );
  };
  return (
    <Box sx={{ backgroundColor: "background.paper" }} px={6} py={6}>
      <Box display="flex" width="100%" alignItems="flex-start">
        {!underMd && (
          <Box mr={8}>
            {logoText ? (
              <>
                <Image
                  src={typeof logoText === "string" ? logoText || notImage : notImage}
                  alt={`logo-${logoText}`}
                  name={`logo-${logoText}`}
                  onLoad={(e: any) => {
                    if (e.type === "load") setLoad(false);
                  }}
                  style={{ objectFit: "fill", width: "100px", maxHeight: "100px", display: load ? "none" : "block" }}
                />
                <Skeleton sx={{ display: load ? "block" : "none" }} animation="wave" variant="rectangular" width={80} height={30} />
              </>
            ) : (
              <img src={notImage} alt="logo" />
            )}
          </Box>
        )}
        <Box width={underMd ? "100%" : "90%"} maxWidth="80vw">
          {/* Address Section */}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={3} sm={2} md={2.3} xl={1.3}>
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-ADDRESS")}: `.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={9} sm={10} md={9.7} xl={10.7}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  width: "85%",
                }}
              >
                {dataRender(address1)}
              </Typography>
            </Grid>
          </Grid>
          {/* State and City Section */}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={3} sm={2} md={2.3} xl={1.3}>
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-STATE")}: `.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={9} sm={10} md={3.7} xl={4.4}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {dataRender(state)}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={2} md={1.3} xl={1}>
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-CITY")}: `.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={9} sm={10} md={4} xl={5.3}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {dataRender(city)}
              </Typography>
            </Grid>
          </Grid>
          {/* URL Section */}
          <Grid container spacing={2}>
            <Grid item xs={3} sm={2} md={2.3} xl={1.3}>
              <Typography variant="h2" sx={{ fontWeight: 700 }}>
                {`${t("INPUT-URL")}: `.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={9} sm={10} md={8.2} xl={10.7}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {dataRender(website)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          {!loading && underMd && (
            <IconButton
              onClick={() => {
                setMapLocDetailView(true);
              }}
              sx={{ color: (theme: Theme) => theme.palette.text.primary, pt: 0 }}
            >
              <MapOutlinedIcon sx={{ fontSize: 26 }} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
