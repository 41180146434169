/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import {
  IAppDataAPI,
  IAppDataDOM,
  IBrandDataAPI,
  IBrandDataDOM,
  ILocationAPI,
  ILocationDOM,
  IOrganizationDataAPI,
  IOrganizationDataDOM,
  IPrimaryContactAPI,
  IPrimaryContactDOM,
  IWarningAPI,
  IWarningDOM,
} from "pages/locations/models";

export const locationGetOneAdapter = (item: ILocationAPI): ILocationDOM => {
  return {
    id: item.id,
    address: {
      address1: item.address.address1,
      address2: item.address.address2,
      city: item.address.city,
      mapsId: item.address.maps_id,
      coordinates: item.address.coordinates,
      postalCode: item.address.postal_code,
      state: item.address.state,
      region: item.address.region,
    },
    brands: item.brands,
    categoriesProducts: item.categories_products,
    contact: {
      email: item.contact.email,
      phone: item.contact.phone,
      fax: item.contact.fax,
      website: item.contact.website,
      keyContacts: item.contact.key_contacts || [],
      managingDirectorOrLeader: item.contact.managing_director_or_leader,
    },
    createdAt: item.created_at,
    deleted_At: item.deleted_at,
    isVisible: item.is_visible,
    logo: item.logo,
    marketOrCountry: item.market_or_country,
    name: item.name,
    openHours: {
      monday: {
        closing: item.open_hours.monday.closing,
        opening: item.open_hours.monday.opening,
        is_enabled: item.open_hours.monday.isEnabled || false,
      },
      tuesday: {
        closing: item.open_hours.tuesday.closing,
        opening: item.open_hours.tuesday.opening,
        is_enabled: item.open_hours.tuesday.isEnabled || false,
      },
      wednesday: {
        closing: item.open_hours.wednesday.closing,
        opening: item.open_hours.wednesday.opening,
        is_enabled: item.open_hours.wednesday.isEnabled || false,
      },
      thursday: {
        closing: item.open_hours.thursday.closing,
        opening: item.open_hours.thursday.opening,
        is_enabled: item.open_hours.thursday.isEnabled || false,
      },
      friday: {
        closing: item.open_hours.friday.closing,
        opening: item.open_hours.friday.opening,
        is_enabled: item.open_hours.friday.isEnabled || false,
      },
      saturday: {
        closing: item.open_hours.saturday.closing,
        opening: item.open_hours.saturday.opening,
        is_enabled: item.open_hours.saturday.isEnabled || false,
      },
      sunday: {
        closing: item.open_hours.sunday.closing,
        opening: item.open_hours.sunday.opening,
        is_enabled: item.open_hours.sunday.isEnabled || false,
      },
    },
    organizationData: organizationDataAdapter(item.organization_data),
    organizationId: item.organization_id,
    purposeCodes: item.purpose_codes,
    region: item.region,
    updatedAt: item.updated_at,
    appData: appDataAdapter(item.app_data),
    brandData: brandDataAdapter(item.brand_data),
    hasRequiredFields: item.has_required_fields ?? true,
    warnings: warningsDataAdapter(item.warnings || []),
    amountOfEmployees: item.amount_of_employees,
    business: item.business,
    description: item.description,
    displayOrder: item.display_order,
    facilitySquareFootage: item.facility_square_footage,
    isFactory: item.factory,
    isPremium: item.premium,
    isOnlineOnly: item.is_online_only,
    sustainability: item.sustainability,
    yearFounded: item.year_founded,
    mainImage: item.main_image,
    images: item.images,
    brandManufactured: item.brand_manufactured,
    primaryContact: item.primary_contact,
    primaryContactData: primaryContactAdapter(item.primary_contact_data),
    sbdConnectId: item.sbd_connect_id,
    sbdConnectData: primaryContactAdapter(item.sbd_connect_data),
    isHeadQuarter: item.is_head_quarter,
  };
};

const organizationDataAdapter = (item: IOrganizationDataAPI): IOrganizationDataDOM => {
  return {
    id: item?.id,
    name: item?.name,
    logo: item?.logo,
  };
};

export const brandDataAdapter = (item: IBrandDataAPI[]): IBrandDataDOM[] => {
  const array: IBrandDataDOM[] = [];
  item?.map((item: IBrandDataAPI) => {
    return array.push({
      name: item.name,
      code: item.code,
      id: item._id,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      deletedAt: item.deleted_at,
    });
  });
  return array;
};
const primaryContactAdapter = (item: IPrimaryContactAPI): IPrimaryContactDOM => {
  return {
    email: item?.email || "",
    firstName: item?.first_name || "",
    lastName: item?.last_name || "",
    phone: item?.phone || "",
    id: item?._id || "",
    designation: item?.designation || "",
    label: `${item?.first_name} ${item?.last_name} - ${item?.email}`,
  };
};

const appDataAdapter = (item: IAppDataAPI[]): IAppDataDOM[] => {
  const array: IAppDataDOM[] = [];
  item?.map((item: IAppDataAPI) => {
    return array.push({
      name: item.name,
      code: item.code,
      fields: item.fields,
      purpose: item.purpose,
      id: item.id,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      deletedAt: item.deleted_at,
    });
  });
  return array;
};

const warningsDataAdapter = (item: IWarningAPI[]): IWarningDOM[] => {
  const array: IWarningDOM[] = [];
  item?.map((item: IWarningAPI) => {
    return array.push({
      fieldName: item.field_name,
      warning: item.warning,
    });
  });
  return array;
};
