/* eslint-disable no-nested-ternary */
import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { useLocationContext } from "pages/locations/context";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function LocOpenHoursLanding({ loading }: { loading?: boolean }) {
  const { t } = useTranslation();
  const { openingHours } = useLocationContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const underXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const defaultText = underXs ? "----" : "-----";
  const responsiveDisposition = (position: number) => {
    if (underMd) {
      if (loading) {
        return (
          <Box width="100%" mt={2} textAlign="center">
            <Skeleton variant="rounded" width="100%" height={20} />
            to
            <Skeleton variant="rounded" width="100%" height={20} />
          </Box>
        );
      }
      return (
        <Box width="100%" mt={2}>
          <Typography variant="h2" textAlign="center">
            {openingHours[position].opening || defaultText} <br /> to <br />
            {openingHours[position].closing || defaultText}{" "}
          </Typography>
        </Box>
      );
    }
    if (loading) {
      return (
        <Box mt={2}>
          <Skeleton variant="rounded" width="100%" height={20} />
        </Box>
      );
    }
    return (
      <Typography variant="h2" mt={2}>
        {openingHours[position].opening || "-----"} to {openingHours[position].closing || "-----"}
      </Typography>
    );
  };
  return (
    <Box mx={6} mt={4}>
      <Box display="flex" alignItems="center" gap={4}>
        <AccessTimeIcon sx={{ fontSize: 24 }} />
        <Typography variant="h2" sx={{ fontWeight: 700 }}>
          {`${t("STEP-TITLE-OPEN-OPEN-HOURS")}`.toUpperCase()}
        </Typography>
      </Box>
      <Box display="flex" mt={4} overflow="scroll">
        <Box width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            Mo
          </Typography>
          {responsiveDisposition(0)}
        </Box>
        <Box mx={2} width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            Tu
          </Typography>
          {responsiveDisposition(1)}
        </Box>
        <Box mx={2} width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            We
          </Typography>
          {responsiveDisposition(2)}
        </Box>
        <Box mx={2} width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            Th
          </Typography>
          {responsiveDisposition(3)}
        </Box>
        <Box mx={2} width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            Fr
          </Typography>
          {responsiveDisposition(4)}
        </Box>
        <Box mx={2} width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            Sa
          </Typography>
          {responsiveDisposition(5)}
        </Box>
        <Box mx={2} width="100%" textAlign="center">
          <Typography variant="h2" sx={{ fontWeight: 700 }} marginLeft={-8}>
            Su
          </Typography>
          {responsiveDisposition(6)}
        </Box>
      </Box>
    </Box>
  );
}
