//** Hooks */
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
//** Models */
import { PublicRoutes, Roles } from "models";
//** Utils */
import { capitalize } from "utilities";
import { useNavigate } from "react-router-dom";
//** Context */
import { useRequestContext } from "../context";
//** Services*/
import { IRequestAccessBodyUser, requestAccessAPI } from "../services";

export default function useRequestAccess() {
  //** States */
  const { firstName, lastName, email, phone, emailSBD, selectedIconRadio, organization, title, location, multiOrgOrganizations } =
    useRequestContext();
  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rolesArray = [
    Roles.multiOrgOwner,
    Roles.viewOnly,
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.locationOwner,
    Roles.locationEditor,
  ];

  const requestAccess = async () => {
    try {
      const body: IRequestAccessBodyUser = {
        username: `${email}`,
        first_name: firstName,
        last_name: lastName,
        phone,
        description: "",
        sbd_contact: emailSBD,
        roles: [rolesArray[+selectedIconRadio - 1]],
        organization_id: selectedIconRadio === "1" ? multiOrgOrganizations.map((item) => `${item.id}`) : [`${organization.id}`],
        designation: title,
        location_id: location?.id || "",
      };

      await callEndpoint(requestAccessAPI({ body }), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-REQUEST")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );

      handleCreateToast(t("ALERT-SUCCESS-REQUEST"), "success");

      navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  return { requestAccess };
}
