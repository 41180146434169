import { useState } from "react";
//** MUI Imports */
import { Stack } from "@mui/material";
//** Components */
import { Button, ModalConfirm } from "components";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
//**Hooks */

import { useFetchAndLoad, useRoleGuard, useToastMui } from "hooks";
import useGetPictures from "hooks/useGetPictures";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

import { useDeleteOrganizations } from "pages/organizations/hooks";
//**Context */
import isUrl from "is-url";
import { useOrganizationContext } from "pages/organizations/context";

import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGlobalContext } from "context/globalContext";
import { capitalize } from "utilities";
//**SERVICES */
import { updateLocationAPI, useUpdateLocation } from "pages/locations";
import { purposeCodeforAplicableBrand } from "pages/locations/constants/purposesToConditions";
import { IUpdateBodyLocationAPI } from "pages/locations/services/api.interfaces";
import { useUpdateUsers } from "pages/users/components/molecules/UpdateUserButtons/hooks";
import { IUpdateBodyOrganization, updateOrganizationsAPI } from "../../../services";
import { useOrganizationInfoContext } from "../../../context/OrganizationInfoContext";

export default function ButtonsInformation() {
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const { setUserEditMode, setLocEditMode } = useGlobalContext();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  //**Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { handleGetCompressedBase64 } = useGetPictures();
  const { id } = useParams();

  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]);
  //**Context */
  const { active, frecuency, dataCopy } = useOrganizationInfoContext();
  const { name, description, logo, url, initalUrl, isValidEmail, newHqData, orgHasChanges } = useOrganizationContext();
  const { Address1Organization, Address2Organization, setGoBackModal } = useOrganizationContext();
  const { phoneOrganization, emailOrganization, locHqId, primaryContact } = useOrganizationContext();
  const {
    mapId,
    countryOrganization,
    cityOrganization,
    postalCodeOrganization,
    regionOrganization,
    stateOrganization,
    latOrganization,
    longOrganization,
    openingHoursOrg,
  } = useOrganizationContext();
  const { isOnlineOnly, isPremium, category, applicableBrand, purpose, displayOrder } = useOrganizationContext();

  const { setSearchToggleDash } = useListAndSearchContext();
  const { deleteOrganizations } = useDeleteOrganizations();
  const { updateHqLocation } = useUpdateLocation();
  const { updateUserPrimaryContact } = useUpdateUsers();

  const updateOrganization = async () => {
    let body: IUpdateBodyOrganization = {
      description,
      is_suspended: active,
      name,
      notification_time: +`${frecuency?.value}`,
    };

    const bodyLocation: Partial<IUpdateBodyLocationAPI> = {
      body: {
        address: {
          address1: Address1Organization,
          address2: Address2Organization,
          city: cityOrganization,
          postal_code: postalCodeOrganization,
          state: stateOrganization,
          maps_id: mapId,
          region: regionOrganization.id || "",
          coordinates: [latOrganization, longOrganization],
        },
        market_or_country: `${countryOrganization ? countryOrganization?.label : ""}`,
        is_online_only: isOnlineOnly,
        premium: isPremium,
        categories_products: category,
        purpose_codes: purpose.map((item) => `${item.code}`),
        contact: {
          website: `${initalUrl}${url}`,
          email: emailOrganization || "",
          phone: phoneOrganization,
        },
        brands: applicableBrand?.map((item) => `${item.code}`),
        organization_id: `${userState.organization?.id ? userState.organization?.id : id}`,
        open_hours: {
          1: {
            opening: openingHoursOrg[0].opening,
            closing: openingHoursOrg[0].closing,
            is_enabled: openingHoursOrg[0].is_enabled,
          },
          2: {
            opening: openingHoursOrg[1].opening,
            closing: openingHoursOrg[1].closing,
            is_enabled: openingHoursOrg[1].is_enabled,
          },
          3: {
            opening: openingHoursOrg[2].opening,
            closing: openingHoursOrg[2].closing,
            is_enabled: openingHoursOrg[2].is_enabled,
          },
          4: {
            opening: openingHoursOrg[3].opening,
            closing: openingHoursOrg[3].closing,
            is_enabled: openingHoursOrg[3].is_enabled,
          },
          5: {
            opening: openingHoursOrg[4].opening,
            closing: openingHoursOrg[4].closing,
            is_enabled: openingHoursOrg[4].is_enabled,
          },
          6: {
            opening: openingHoursOrg[5].opening,
            closing: openingHoursOrg[5].closing,
            is_enabled: openingHoursOrg[5].is_enabled,
          },
          7: {
            opening: openingHoursOrg[6].opening,
            closing: openingHoursOrg[6].closing,
            is_enabled: openingHoursOrg[6].is_enabled,
          },
        },
      },
    };

    //** If the image change we need to update to the new compressed image */
    if ((dataCopy?.logo?.length ? dataCopy?.logo[0] : null) !== logo[0]) {
      const fileCompressed: string = await handleGetCompressedBase64(logo, 0);
      body = { ...body, logo: fileCompressed };
    }

    if (newHqData?.id && dataCopy.hqId !== newHqData?.id) await updateHqLocation(id || "", newHqData?.id);

    if (primaryContact?.id) await updateUserPrimaryContact(primaryContact?.id || "", id || "", "Organization");

    await callEndpoint(updateOrganizationsAPI(id ? `${id}` : `${organization?.id}`, { body }), (error: any) =>
      handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
    );
    if (locHqId) {
      await callEndpoint(updateLocationAPI(locHqId, bodyLocation), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );
    }
    setSearchToggleDash((prev: boolean) => !prev);

    handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
    setUserEditMode(false);
    setLocEditMode(false);

    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  const handleCloseDelete = () => setOpenModalDelete(false);

  const requiredInputsAddress =
    !mapId || !cityOrganization || !postalCodeOrganization || !stateOrganization || !regionOrganization?.id || !countryOrganization?.id;

  const requiredInputsBusiness =
    (purpose.findIndex((item) => item.code === "OR") !== -1 ? !displayOrder : false) ||
    purpose.length === 0 ||
    (purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`)) && applicableBrand.length === 0);

  const buttonIsDisabled =
    loading ||
    //Required fields
    !authUpdateOrganization ||
    !name ||
    requiredInputsAddress ||
    requiredInputsBusiness ||
    logo.length === 0 ||
    (emailOrganization && emailOrganization?.length > 0 && !isValidEmail) ||
    (url.length > 0 && !isUrl(`${initalUrl}${url}`));

  // different layout on responsive view
  return (
    <Stack direction="row" gap={4} mt={8} mx={2} display="flex" justifyContent="space-between">
      <Stack direction="row" gap={4}>
        <Button
          name="org-cancel-update"
          onClick={() => {
            const urlToGo = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`;
            if (orgHasChanges) return setGoBackModal({ open: true, urlToGo: `${urlToGo}` });
            return navigate(`${urlToGo}`);
          }}
          label={t("BUTTON-CANCEL")}
          size="large"
          color="secondary"
          variant="outlined"
        />

        <RoleGuard authorizedRoles={[Roles.superAdmin]}>
          <Button
            name="org-delete"
            onClick={() => setOpenModalDelete(true)}
            label={t("BUTTON-DELETE")}
            size="large"
            variant="text"
            capitalize
          />
        </RoleGuard>
      </Stack>
      <Button name="org-update" onClick={updateOrganization} label={t("BUTTON-UPDATE")} size="large" disabled={buttonIsDisabled} />

      <ModalConfirm
        open={openModalDelete}
        title={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-TITLE")}
        description={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteOrganizations([`${id}`]);
          setOpenModalDelete(false);
          navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`, { replace: false });
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Stack>
  );
}
