import { useEffect, useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
// import AddIcon from "@mui/icons-material/Add";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { useNavigate, useParams } from "react-router-dom";
import { PrivateRoutes, Roles } from "models";
import LocationImportOnHeader from "pages/locations/pages/locationsList/components/atoms/AppListHeader/LocationImportOnHeader";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { Backdrop, Fade } from "@mui/material";
import { RoleGuard } from "guards";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useRoleGuard } from "hooks";
import { ModalLocationExports, useOrganizationContext } from "pages";
import { useExportLocation } from "pages/locations/hooks";

type BaseProps = {
  orgLanding?: boolean;
  absolute?: boolean;
  // otras propiedades comunes
};

export default function LocSpeedDial({ absolute, orgLanding }: BaseProps) {
  const { name } = useOrganizationContext();
  const { setOpenImportModal } = useListAndSearchContext();
  const { openImportModalUser, openAddModal, openExport, setOpenexport, openImportModal } = useListAndSearchContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  //** States Export */
  //** Var */
  const { id } = useParams();
  const { exportLocations } = useExportLocation();

  const handleCloseExport = () => setOpenexport(false);
  const handleOpenExport = () => setOpenexport(true);

  const [actions, setActions] = useState([
    {
      name: t("BUTTON-IMPORT"),
      onClick: () => {
        setOpenImportModal(true);
        setCallStatus(null);
      },
    },
    {
      name: t("BUTTON-EXPORT"),
      onClick: () => {
        handleOpenExport();
      },
    },
    {
      name: t("BUTTON-CREATE"),
      onClick: () => {
        navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CREATE}`, { replace: false });
      },
    },
  ]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //** Button */

  const fromAdminOrgView = window.location.pathname.includes(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`);
  const adminNavigation = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CLAIM_LOCATION_NAV}/${id}?orgName=${name}`;
  const organizationRelatedNavigation = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CLAIM_LOCATION_NAV}`;
  const fromLocList = window.location.pathname.includes(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`);

  const authorizedRoles = [Roles.superAdmin, Roles.organizationEditor, Roles.organizationOwner, Roles.multiOrgOwner];
  const adminRoles = [Roles.superAdmin, Roles.multiOrgOwner, Roles.generalViewOnly];

  const isAdminRole = useRoleGuard(adminRoles);

  useEffect(() => {
    if (fromLocList) return;
    if (!fromAdminOrgView && !userState.organization?.id) return;

    const createLocAction = orgLanding
      ? {
          name: t("BUTTON-CREATE"),
          onClick: () => {
            navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CREATE}?orgId=${id}&orgName=${name}`, { replace: false });
          },
        }
      : {
          name: t("BUTTON-CREATE"),
          onClick: () => {
            navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CREATE}`, { replace: false });
          },
        };
    const newActions = [
      actions[0],
      actions[1],
      {
        name: t("BUTTON-CLAIM"),
        onClick: () => {
          navigate(isAdminRole ? adminNavigation : organizationRelatedNavigation, { replace: false });
        },
      },
      createLocAction,
    ];

    setActions(newActions);
  }, [name]);

  return (
    <Fade in={!openImportModalUser && !openAddModal && !openExport && !openImportModal}>
      <div>
        <RoleGuard authorizedRoles={authorizedRoles}>
          <Backdrop open={open} sx={{ zIndex: 999 }} onClick={handleClose} />
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{
              position: absolute ? "absolute" : "fixed",
              bottom: (theme) => (absolute ? theme.spacing(15) : theme.spacing(30)),
              right: (theme) => theme.spacing(6),
              zIndex: 1000,
              "& .MuiButtonBase-root": { width: 48, height: 48 },
              "& .MuiSpeedDialAction-staticTooltipLabel": { fontSize: "1.5rem", cursor: "pointer !important" },
              "& .MuiSpeedDial-actions": {
                marginBottom: actions.length === 2 ? "-117px" : "-138px",
                marginLeft: "-20px",
              },
              "& .MuiSpeedDialAction-fab": { visibility: "hidden", width: 15, height: 15, fontSize: 2 },
              "& .MuiSpeedDialIcon-icon": { fontSize: 28, fontWeight: 700, mt: -0.5 },
              "& .MuiSpeedDialAction-staticTooltip": { height: 43 },
            }}
            icon={<SpeedDialIcon />}
            onOpen={handleOpen}
            onClick={() => {
              setOpen(!open);
            }}
            open={open}
          >
            {actions.map((action) => (
              <SpeedDialAction
                disableInteractive
                key={action.name}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => {
                  action.onClick && action.onClick();
                  handleClose();
                }}
              />
            ))}
          </SpeedDial>
          <LocationImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
          <ModalLocationExports open={openExport} exportLocations={exportLocations} handleClose={handleCloseExport} />
        </RoleGuard>
      </div>
    </Fade>
  );
}
