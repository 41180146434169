import { Dispatch, SetStateAction, useEffect, useState } from "react";
//** MUI Imports */
import { Box, Checkbox, Chip, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
//** Model */
import { IDropdownOption } from "models";
//** Icon */
import { ArrowDropDown } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//** External libraries */
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";

interface IInputApiCalls {
  apiCallFunction: any;
  label: string;
  loading: boolean;
  setPropValue: Dispatch<SetStateAction<IDropdownOption[]>>;
  changeWithInputText?: boolean; //when need a query to call the api with autocomplete input value
  initialValue: any;
  disabled?: boolean;
  hasNextPage: boolean;
  page: number;
  options: any[];
  setPage: any;
  setOptions: any;
  name: string;
  requiredFields?: boolean;
  alertLabel?: string;
  redirect?: boolean;
  redirectUrl?: string;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const iconAll = <IndeterminateCheckBoxIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

export default function InputApiCallCheckboxInfiniteScroll({
  apiCallFunction,
  label,
  loading,
  initialValue,
  setPropValue,
  disabled,
  hasNextPage,
  page,
  options,
  setPage,
  setOptions,
  changeWithInputText,
  name,
  requiredFields,
  alertLabel,
  redirect,
  redirectUrl,
}: IInputApiCalls) {
  const [value, setValue] = useState<any>(initialValue);
  const [valueInput, setValueInput] = useState<any>(initialValue);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialValue.length > 0 && value.length === 0) {
      setValue([...initialValue]);
    }
    if (window.location.pathname.includes("create") && initialValue.length === 0) {
      setValue([]);
    }
  }, [initialValue]);

  const isAllSelected = options.length > 0 && value.length === options.length;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : apiCallFunction,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (!options.length) return;
    setPage(0);
    const delayDebounceFn = setTimeout(async () => {
      if (!changeWithInputText) return;
      setOptions([]);
      await apiCallFunction(valueInput);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(delayDebounceFn);
  }, [valueInput]);

  const handleChange = (event: any, newValue: any | null) => {
    if (newValue.findIndex((item: any) => item.label === "Select all") !== -1) {
      setValue(options);
      setPropValue(options);
    } else if (isAllSelected) {
      setValue([]);
      setPropValue([]);
    } else {
      setValue(newValue);
      setPropValue(newValue);
    }
  };
  return (
    <Box width="100%">
      <Autocomplete
        disabled={disabled}
        fullWidth
        multiple
        loading={loading}
        popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
        value={value}
        disableCloseOnSelect
        options={options}
        onChange={handleChange}
        isOptionEqualToValue={(option, valueOption) => option?.label === valueOption?.label}
        renderOption={(props: any, option: any, { selected }) => (
          <li {...props} ref={sentryRef}>
            {option.name === "Select all" ? (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={iconAll}
                  checked={selected}
                  style={{ marginRight: 8 }}
                  indeterminate={value.length > 0 && value.length <= options.length}
                />
                Select All
              </>
            ) : (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </>
            )}
          </li>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            sx={{ "& .MuiChip-label": { fontSize: 16 } }}
            name={name}
            onClick={async () => {
              if (options.length) return;
              await apiCallFunction(value);
            }}
            onChange={(fieldValue: any) => {
              setValueInput(fieldValue.target.value);
            }}
            color={requiredFields && "error"}
            focused={requiredFields}
          />
        )}
        clearIcon={
          <IconButton
            sx={{ margin: 0, padding: 0 }}
            onClick={async () => {
              if (options.length && !changeWithInputText) return;
              await apiCallFunction("");
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        renderTags={
          (valueSelected: IDropdownOption[], getTagProps) =>
            valueSelected.map((option: IDropdownOption, index: number) => (
              <Chip
                label={option.label}
                {...getTagProps({ index })}
                onClick={redirect ? () => navigate(`/${redirectUrl}/${option.id}`) : () => {}}
              />
            ))
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
      {requiredFields && alertLabel && (
        <Typography component="span" color="error" ml={6} mt={2}>
          {alertLabel}
        </Typography>
      )}
    </Box>
  );
}
