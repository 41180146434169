import { useState } from "react";
//** MUI Imports*/
import { Box, Checkbox, IconButton, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components Imports*/
import EditIcon from "@mui/icons-material/Edit";
import { FilterChips, Input, InputApiCall, InputApiCallInfiniteScroll, Select, TableSearch } from "components";
import { RoleGuard } from "guards";
import Translations from "layouts/components/Translations";
import { PrivateRoutes, Roles } from "models";
import UserBulkActions from "pages/users/components/molecules/UserBulkActions/UserBulkActions";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { useGetReduxUser } from "hooks";
import { useGetRoles } from "hooks/apiCalls";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
import { useTranslation } from "react-i18next";
//** Icons */
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GroupIcon from "@mui/icons-material/Group";
import { useGlobalContext } from "context/globalContext";
import { useNavigate } from "react-router-dom";
import { useUsersListContext } from "../../../context";
import useUsersSearch from "../../../hooks/useUsersSearch";
import UserImportOnHeader from "./UserImportOnHeader";

interface HeaderProps {
  onSearch: () => void;
  isDashboard?: boolean;
}

export default function AppListHeader({ onSearch, isDashboard }: HeaderProps) {
  //** Context */
  const { usersHasFilter } = useListAndSearchContext();
  const { usersArray, setSelected, selected } = useUsersListContext();
  const firstNameSearchFn = useUsersSearch().userFirstNameSearch;
  const lastNameSearchFn = useUsersSearch().userLastNameSearch;
  const emailSearchFn = useUsersSearch().userEmailSearch;
  const statusUserSearch = useUsersSearch().userStatusSearch;
  const roleSearch = useUsersSearch().userRoleSearch;
  const organizationUserSearch = useUsersSearch().userOrganizationSearch;

  //** Hooks */
  const matches = useMediaQuery("(max-width:600px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { getRoles, loading } = useGetRoles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const optionsStatus = [t("OPTION-SELECT-1"), t("OPTION-SELECT-2")];

  const { getOrganizations, orgPage, hasNextPage, orgData, setOrgPage, loadingOrg, setOrgData } = useGetSearchData();
  const { setUserEditMode, userEditMode } = useGlobalContext();

  const numSelected = selected.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = usersArray.map((n) => ({ id: n.id, active: !n.isSuspended }));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const rowCount = usersArray.length;

  //**Chips */
  const filterChips = [
    {
      key: "first_name",
      label: "INPUT-FIRST-NAME",
      data: firstNameSearchFn.value,
    },
    {
      key: "last_name",
      label: "INPUT-LAST-NAME",
      data: lastNameSearchFn.value,
    },
    {
      key: "email",
      label: "INPUT-EMAIL",
      data: emailSearchFn.value,
    },
    {
      key: "role",
      label: "INPUT-ROLE",
      data: roleSearch.valueLabel,
    },
    {
      key: "status",
      label: "INPUT-STATUS",
      data: statusUserSearch.value,
    },
    {
      key: "organization",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationUserSearch.valueLabel,
    },
  ];

  //Filter Chips functions
  const handleDelete = (filterName: "first_name" | "last_name" | "email" | "role" | "status" | "organization") => {
    if (filterName === "first_name") firstNameSearchFn.clearByKey();
    if (filterName === "last_name") lastNameSearchFn.clearByKey();
    if (filterName === "email") emailSearchFn.clearByKey();
    if (filterName === "role") roleSearch.clearByKey();
    if (filterName === "status") statusUserSearch.clearByKey();
    if (filterName === "organization") organizationUserSearch.clearByKey();
    onSearch();
  };
  const handleDeleteAll = () => {
    firstNameSearchFn.clearByKey();
    lastNameSearchFn.clearByKey();
    emailSearchFn.clearByKey();
    roleSearch.clearByKey();
    statusUserSearch.clearByKey();
    organizationUserSearch.clearByKey();
    onSearch();
  };

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  const { userState } = useGetReduxUser();

  const hasSelectedItems = selected.length > 0;

  return (
    <Box pt={2} pb={6}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={4}
        px={1}
        justifyContent="space-between"
        alignItems={isDashboard ? "center" : ""}
      >
        <Box>
          <Box ml={2}>
            {isDashboard ? (
              <Box display="flex">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
                <Box display="flex" alignItems="center" onClick={() => {}}>
                  <Typography width="100%" variant="h1" fontWeight="500">
                    <Translations text="GLOBAL-TITLE-USERS" />
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" onClick={() => {}}>
                <GroupIcon sx={{ fontSize: 24, mr: 4 }} />
                <Typography width="100%" variant="h1" fontWeight="500">
                  <Translations text="GLOBAL-TITLE-USERS" />
                </Typography>
              </Box>
            )}
            {hasSelectedItems && (
              <Typography variant="h4" fontWeight="500" sx={{ ml: isDashboard ? 18 : 0 }}>
                {`${t("GLOBAL-SELECTED")} ${selected.length}`}
              </Typography>
            )}
          </Box>

          {hasSelectedItems && !isDashboard && (
            <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.locationOwner]}>
              <Box display="flex" alignItems="center" ml={-4}>
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />

                <Box display="flex" justifyContent="space-between">
                  <UserBulkActions onSearch={onSearch} hasSelectedItems={hasSelectedItems} />
                </Box>
              </Box>
            </RoleGuard>
          )}
        </Box>

        <Box ml="auto" display="flex" flexWrap="wrap" alignItems={underMd ? "flex-end" : "normal"}>
          {!isDashboard && (
            <Box>
              {userEditMode ? (
                <IconButton
                  sx={{ mr: 4 }}
                  onClick={() => {
                    setUserEditMode(false);
                    setSelected([]);
                  }}
                  disabled={loading}
                >
                  <CheckCircleIcon sx={{ fontSize: 20 }} />
                </IconButton>
              ) : (
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.locationOwner]}>
                  <IconButton
                    sx={{ mr: 4 }}
                    onClick={() => {
                      setUserEditMode(true);
                    }}
                  >
                    <EditIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </RoleGuard>
              )}
            </Box>
          )}
          {isDashboard && hasSelectedItems ? (
            <UserBulkActions onSearch={onSearch} hasSelectedItems={hasSelectedItems} />
          ) : (
            <>
              <Box mt={underMd ? 4 : 0}>
                <TableSearch
                  isDashboard={isDashboard}
                  noOptionsComponent={
                    <Box>
                      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={4}>
                        <Input name="usr-first-name-search" {...firstNameSearchFn} label={`${t("INPUT-FIRST-NAME")}`} fullWidth />
                        <Input name="usr-last-name-search" {...lastNameSearchFn} label={`${t("INPUT-LAST-NAME")}`} fullWidth />
                      </Stack>
                      <Box mb={4}>
                        <Input name="usr-email-search" {...emailSearchFn} label={`${t("INPUT-EMAIL")}`} fullWidth />
                      </Box>
                      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={4}>
                        <Select
                          name="usr-status-search"
                          value={statusUserSearch.value || ""}
                          label={`${t("INPUT-STATUS")}`}
                          setValue={statusUserSearch.onChange}
                          options={optionsStatus}
                        />
                        <InputApiCall
                          name="usr-role-search"
                          externalValue={{ label: roleSearch.valueLabel || "", id: roleSearch.valueId || "" } || null}
                          setPropValue={roleSearch.onChange}
                          apiCallFunction={getRoles}
                          loading={loading}
                          label={`${t("INPUT-ROLE")}`}
                        />
                      </Stack>
                      {!isDashboard && !userState?.organization?.id && (
                        <Box mb={4}>
                          <InputApiCallInfiniteScroll
                            initialValue={
                              { label: organizationUserSearch.valueLabel || "", id: organizationUserSearch.valueId || "" } || null
                            }
                            name="usr-organization-search"
                            setPropValue={organizationUserSearch.onChange}
                            changeWithInputText
                            apiCallFunction={getOrganizations}
                            loading={loadingOrg}
                            label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                            hasNextPage={hasNextPage}
                            page={orgPage}
                            options={orgData}
                            setPage={setOrgPage}
                            setOptions={setOrgData}
                            search
                          />
                        </Box>
                      )}
                    </Box>
                  }
                  onReset={handleDeleteAll}
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    onSearch();
                  }}
                />
              </Box>

              {isDashboard && (
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.locationOwner]}>
                  <IconButton
                    onClick={() => {
                      navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.CREATE}`);
                    }}
                    color="inherit"
                    sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" }, padding: "6px" }}
                  >
                    <AddIcon sx={{ fontSize: 25 }} />
                  </IconButton>
                </RoleGuard>
              )}
            </>
          )}
        </Box>
      </Box>

      {usersHasFilter && (
        <Stack direction="row" spacing={4} alignItems="center" ml={4} my={6}>
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>
      )}
      <UserImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
    </Box>
  );
}
