//** MUI Imports */
import { Box, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { LocRespLanding } from "pages/locations/components";
import { LocationLanding } from "pages/locations/components/organism/LocationLanding";

//** Hooks */
import { useLocationContext } from "pages/locations/context";

//** Utils */
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "../../hooks";

export default function LocationsInformationView() {
  //** Context */
  const { setView } = useLocationContext();

  //**Hooks */
  const { id } = useParams();
  const { loading, loadingPurpose } = useLocation(`${id}`);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    setView("update");
  }, []);

  return (
    <Box>{underMd ? <LocRespLanding loading={loading || loadingPurpose} /> : <LocationLanding loading={loading || loadingPurpose} />}</Box>
  );
}
