/* eslint-disable consistent-return */
//** MUI Imports */
import { Box, Divider } from "@mui/material";
//** Components imports */
import { BasicInformationLocation, FacilityInformation } from "pages/locations/components";
import { VisibilitySection } from "pages/locations/components/molecules/VisibilitySection";

export default function BasicInformationLocationStep({ create }: { create?: boolean }) {
  return (
    <>
      <BasicInformationLocation create={create} />
      <Box mt={12} />
      <VisibilitySection />
      <FacilityInformation />
      <Divider />
    </>
  );
}
