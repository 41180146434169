/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useTranslation } from "react-i18next";
import Input from "./Input";

interface IProps {
  id: string;
  email: string | null;
  onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValidEmail: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  name?: string;
  size?: "small" | "medium";
}

export default function InputEmail({ email, onChangeEmail, isValidEmail, disabled, id, isRequired, name, size }: IProps) {
  const { t } = useTranslation();
  return (
    <Input
      id={id}
      name={name || "loc-email"}
      size={size}
      value={email}
      onChange={onChangeEmail}
      label={size === "small" ? (isRequired ? `${t("INPUT-EMAIL")}*` : `${t("INPUT-EMAIL")}`) : ""}
      fullWidth
      disabled={disabled}
      error={isValidEmail ? false : email?.length === 0 || !isValidEmail}
      helperText={(!isValidEmail && `${email || ""}`.length > 0) || !isRequired ? `${t("INVALID-EMAIL")}` : `${t("ALL-REQUIRED.INPUT")}`}
      strink={email === null ? true : email.length > 0}
    />
  );
}
