//** React imports */
import { useEffect, useState } from "react";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//**Services */
import { getAllLocationsAPI, getAllPurposesAPI } from "pages/locations/services";
//**Hooks */
import { useAsync, useFetchAndLoad, useRoleGuard, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "hooks/useBreadcrumbs";
//**Model */
import { ILocationsListDOM, ILocationsListFromAPI, ILocationsLoadHook, ILocationsSearchHook } from "pages/locations/models";
//**Utils */
import { capitalize, createQuery } from "utilities";
//** Context */
import { PrivateRoutes, Roles, array20, array50 } from "models";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { locationGetallAdapter, useLocationContext, useLocationListContext } from "pages/locations";
import { useGlobalContext } from "context/globalContext";
import { getOneUserAPI } from "pages/users/pages/usersInformation/services";
import { LocsSearchFields } from "pages/locations/pages/locationsList/hooks/useLocationSearch";

export default function useGetLocationsDashboard(organizationId: string | undefined, infoView?: boolean, embebed?: boolean) {
  //** Context */
  const fetchPurposes = useFetchAndLoad();
  const { setLocationHasFilter, setLocationIsLoading } = useListAndSearchContext();
  const {
    locationsMapListArray,
    setLocationsMapListArray,
    setLocationsMapMarksArray,
    latitudeClaimMap,
    longitudeClaimMap,
    setLatitudeClaimMap,
    setLongitudeClaimMap,
    toggleChangeCoordinates,
    setLoad,
  } = useLocationListContext();
  //**Search */
  const { searchParamsLocs } = useListAndSearchContext();
  const nameLocSearch = searchParamsLocs.get(LocsSearchFields.name);
  const visibilitySearch = searchParamsLocs.get(LocsSearchFields.visibility);
  const marketSearch = searchParamsLocs.get(LocsSearchFields.marketId); //** needs the ids */
  const purposeSearch = searchParamsLocs.get(LocsSearchFields.purposeCodes); //** needs the codes */
  const organizationsearch = searchParamsLocs.get(LocsSearchFields.organization); //** needs the ids */
  const { setPrimaryContact, setHqPrimaryContact, setPurposesData } = useLocationContext();
  const { setSearchToggleDash, searchToggleDash } = useListAndSearchContext();
  const { mapView } = useGlobalContext();

  //** State */

  const [searchToggle, setSearchToggle] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const limit = 50;
  const initialPagination = 20;

  //to sort list by api
  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  //** Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const userFetch = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { startHistory } = useBreadcrumbs();
  const rolesWithHQSort = useRoleGuard([
    Roles.organizationOwner,
    Roles.organizationEditor,
    Roles.viewOnly,
    Roles.locationOwner,
    Roles.locationEditor,
  ]);

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const loadLocations: any = async ({
    name,
    market_or_country,
    purpose,
    is_visible,
    organization_id,
    latitude,
    longitude,
  }: ILocationsLoadHook) => {
    //Create an object with key that have some search value
    //If the table doesn't have a filter it will be sorted by creation

    //? When non admin user in on organization landing they list must be filter by ther url org id
    //? Then in the others views is most important the user organization that was linked
    // eslint-disable-next-line no-nested-ternary
    const orgFromNonAdmins = userState?.organization?.id ? userState?.organization?.id : organization_id;
    const organizationIdOnView = embebed ? organization_id : orgFromNonAdmins;
    setLocationIsLoading(true);
    setLoad([...array20]);
    let query = createQuery<ILocationsSearchHook>({
      name,
      market_or_country,
      purpose,
      is_visible: is_visible?.length > 0 ? `${is_visible === "Visible"}` : "",
      organization_id: organizationIdOnView,
      // eslint-disable-next-line max-len
      sort: typeSort || infoView || rolesWithHQSort ? "desc(is_head_quarter)" : "", //! NO sirve para lista global el sort por is_head_quarter para admins
      coordinates: latitude && longitude ? `${latitude},${longitude}` : undefined,
    });
    setLocationHasFilter(`${name}${market_or_country}${purpose}${is_visible}${latitudeClaimMap || ""}`);
    query = { offset: 0, limit: initialPagination, ...query };

    //Calling get all API
    const response = await callEndpoint(getAllLocationsAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));

    return response;
  };

  const adaptInfo = async (data: ILocationsListFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const array: ILocationsListDOM[] = locationGetallAdapter(data?.result?.items);

    setLocationsMapListArray([...array]);
    setLocationsMapMarksArray(
      array.map((item) => ({
        lat: item.address.coordinates[0],
        lng: item.address.coordinates[1],
        id: item.id,
        name: item.name,
        status: item.isVisible,
        address: item.address.address1,
      })),
    );

    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);
    //** If dosen`t appear more data sent that there are not more information*/
    if (!data?.result?.next) setHasNextPage(false);
    setLocationIsLoading(false);

    //? si la primera loc es hq y estoy en una vista que requiera la informacion del primary contact
    const isHqLoc = array[0]?.isHeadQuarter;
    if (infoView && isHqLoc && array[0]?.primaryContact) {
      const responsePC = await userFetch.callEndpoint(getOneUserAPI(array[0]?.primaryContact), () =>
        handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"),
      );
      const primaryContactResult = responsePC?.data?.result;
      setPrimaryContact({
        email: primaryContactResult?.username,
        firstName: primaryContactResult?.first_name,
        lastName: primaryContactResult?.last_name,
        phone: primaryContactResult?.phone,
        id: primaryContactResult?.id,
        designation: primaryContactResult?.designation,
        label: `${primaryContactResult?.first_name} ${primaryContactResult?.last_name}`,
      });
      setHqPrimaryContact({
        label: `${primaryContactResult?.first_name}`,
        id: primaryContactResult?.id,
      });
    }

    if (!organizationId) {
      startHistory({
        url: `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`,
        label: capitalize(`${PrivateRoutes.LOCATIONS}`),
        index: 0,
      });
    }
    const responsePurposes = await fetchPurposes.callEndpoint(getAllPurposesAPI({}));
    const purposesDataResponse = responsePurposes?.data?.result?.items;
    const purposesData = purposesDataResponse.map((item: any) => ({ label: item.name, id: item.code, code: item.code }));
    setPurposesData(purposesData);
  };

  const loadMoreLocations = async () => {
    setLocationIsLoading(true);
    setLoad((prev) => [...prev, ...array50]);
    const organization_id = organizationId || organizationsearch;
    //? When non admin user in on organization landing they list must be filter by ther url org id
    //? Then in the others views is most important the user organization that was linked
    // eslint-disable-next-line no-nested-ternary
    const organizationIdOnView = embebed ? organization_id : userState?.organization?.id ? userState?.organization?.id : organization_id;
    let query = createQuery<ILocationsSearchHook>({
      name: nameLocSearch,
      market_or_country: marketSearch,
      purpose: purposeSearch,
      is_visible: visibilitySearch?.length > 0 ? `${visibilitySearch === "Visible"}` : "",
      organization_id: organizationIdOnView,
      // eslint-disable-next-line max-len
      sort: typeSort || infoView || rolesWithHQSort ? "desc(is_head_quarter)" : "",
      coordinates: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : undefined,
    });

    //?first 20 are loaded and then loaded 50 at a time
    if (page === 1) {
      query = { ...query, offset: initialPagination };
    } else {
      query = { ...query, offset: (page - 1) * limit + initialPagination };
    }

    query = { ...query, limit };

    //Calling get all API
    const response = await callEndpoint(getAllLocationsAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    const { data } = response;
    //Save the adapted data and the last page and length for the pagination
    const array: ILocationsListDOM[] = locationGetallAdapter(data?.result?.items);
    setLocationsMapListArray((prev) => [...prev, ...array]);
    setLocationsMapMarksArray((prev) => [
      ...prev,
      ...array.map((item) => ({
        lat: item.address.coordinates[0],
        lng: item.address.coordinates[1],
        id: item.id,
        name: item.name,
        status: item.isVisible,
        address: item.address.address1,
      })),
    ]);
    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);

    //** If dosen`t appear more data sent that there are not more information*/
    if (!data?.result?.next) setHasNextPage(false);
    setLocationIsLoading(false);
    return response;
  };

  useAsync(
    () =>
      loadLocations({
        name: nameLocSearch || "",
        market_or_country: marketSearch || "",
        purpose: purposeSearch || "",
        is_visible: visibilitySearch || "",
        organization_id: organizationId || organizationsearch || "",
        latitude: latitudeClaimMap || "",
        longitude: longitudeClaimMap || "",
      }),
    adaptInfo,
    () => {},
    [searchToggle, userState?.organization?.id, searchToggleDash, mapView],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setPage(0);
    setSearchToggleDash((value) => !value);

    //** When search by filters the filters must be the main search */
    //** Then when i move the map and ge coordinates this lat,long must be the main search */
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    setHasNextPage(true);
  };

  const onSearchWithCoordinates = () => {
    setSearchToggle((value) => !value);
    setPage(0);
    setSearchToggleDash((value) => !value);
    setHasNextPage(true);
  };

  //**Search by coordinates */
  useEffect(() => {
    if (!latitudeClaimMap) return;
    if (!longitudeClaimMap) return;
    onSearchWithCoordinates();
  }, [latitudeClaimMap, longitudeClaimMap, toggleChangeCoordinates]);

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    locationsMapListArray,
    loading,
    limit,
    handleChange,
    onSearch,
    incrementCount,
    loadMoreLocations,
    hasNextPage,
    page,
    loadingPurpose: fetchPurposes.loading,
  };
}
