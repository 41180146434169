import { useEffect } from "react";
//** MUI Imports */
import { Box, Card, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { SectionsTitles } from "components";
import { StepsLocation } from "pages/locations/components";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Utils */
//** Context */
import { useLocationContext } from "pages/locations/context";

export default function LocationsCreate() {
  //** Context*/
  const { setView, clearBasicInformation } = useLocationContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //** Hooks */
  const { t } = useTranslation();
  useEffect(() => {
    setView("create");
    clearBasicInformation();
  }, []);

  return (
    <Box mx={underMd ? 4 : 0} mb={underMd ? 12 : 0}>
      <Card sx={{ py: 8, px: 6 }}>
        <SectionsTitles title={`${t("GLOBAL-TITLE-NEW-LOCATION")}`} subTitle={`${t("LOCATION-CREATE-SUBTITLE")}`} />
        <StepsLocation action="create" />
      </Card>
    </Box>
  );
}
