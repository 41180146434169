import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Chip } from "components";
import { useLocationContext } from "pages/locations/context";

export default function LocOwnerLanding({ loading, paperBg }: { loading?: boolean; paperBg?: boolean }) {
  const { t } = useTranslation();
  const { primaryContact } = useLocationContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box px={6} pt={4} pb={2} sx={{ backgroundColor: paperBg ? "background.default" : "background.paper" }}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={4}>
          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 24, transform: "rotate(90deg)" }} />
          <Typography variant="h2" sx={{ fontWeight: 700 }}>
            {`${t("LOCATION-LOCATION-OWNER")}`.toUpperCase()}
          </Typography>
        </Box>
        <Box>
          <Chip label={primaryContact?.designation?.toUpperCase() || "-----"} size="small" fontSize={underMd ? 12 : 14} />
        </Box>
      </Box>
      <Box mx={2}>
        <Box display="flex" my={4}>
          <Box minWidth="80px" width="20%" maxWidth={110}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-NAME")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box width="75%" sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "80%" }}>
            {loading ? (
              <Skeleton variant="rounded" width="95%" height={20} />
            ) : (
              <Typography variant="h5">{`${primaryContact?.firstName || "----"} ${primaryContact?.lastName || "----"}`}</Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" mb={4}>
          <Box minWidth="80px" width="20%" maxWidth={110}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-PHONE")}: `.toUpperCase() || "----"}
            </Typography>
          </Box>
          <Box width="75%">
            {loading ? (
              <Skeleton variant="rounded" width="95%" height={20} />
            ) : (
              <Typography variant="h5">{primaryContact?.phone || "----"}</Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" mb={4}>
          <Box minWidth="80px" width="20%" maxWidth={110}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {`${t("INPUT-EMAIL")}: `.toUpperCase()}
            </Typography>
          </Box>
          <Box width="75%" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "80%" }}>
            {loading ? (
              <Skeleton variant="rounded" width="95%" height={20} />
            ) : (
              <Typography variant="h5" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                {`${primaryContact?.email || "----"}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
